import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { VENDOR_ADMIN } from '../../globalConstants'
import ExitButton from './ExitButton'
import Help from './Help'
import Download from './Download'
import UserInfo from './UserInfo'
import BackButton from './BackButton'
import PrimaryNavTabs from '../PrimaryNavTabs/PrimaryNavTabs'
import MatLinearProgress from '../MatLinearProgress/MatLinearProgress'
import HomeButton from './HomeButton'
import { Stack } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    stack: {
      marginRight: theme.spacing(2),
    },
  }),
)

const Header = ({
  download = false,
  reportParams,
  arrowBack = false,
  renderTabs = false,
}) => {
  const classes = useStyles()
  return (
    <AppBar>
      <Toolbar>
        <Stack direction="row" className={classes.stack}>
          {arrowBack && <BackButton />}
          <HomeButton />
        </Stack>
        <img
          src={require('../../images/targetlogo.png')}
          alt={'Target Logo'}
          className={classes.logo}
        />
        <Typography id="header-title" variant={'h6'} style={{ flexGrow: 1 }}>
          {VENDOR_ADMIN}
        </Typography>
        <UserInfo />
        {download && <Download reportParams={reportParams} />}
        <Help />
        <ExitButton />
      </Toolbar>
      {renderTabs && <PrimaryNavTabs />}
      <MatLinearProgress />
    </AppBar>
  )
}

export default Header
