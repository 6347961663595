/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Paper, createStyles, Modal, Typography, Stack } from '@mui/material'
import Button from '@material-ui/core/Button'
import { OK } from '../../../../globalConstants'
import { getURLSearchParams } from '../../../../windowManager'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    modalHeader: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.action,
      padding: theme.spacing(1, 2),
    },
    modalMessage: {
      padding: theme.spacing(2),
    },
  }),
)

export const ConfirmBillingModal = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const onHashChange = () => {
    const searchParams = getURLSearchParams()
    const billingParam = Boolean(searchParams.get('confirm_billing'))
    setOpen(billingParam)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const handleClose = () => {
    window.history.back()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className={classes.paper}>
        <Stack>
          <Typography className={classes.modalHeader} variant="h6">
            Confirmation of Billing
          </Typography>
          <Typography variant="h9" className={classes.modalMessage}>
            <strong>Billing was successful.</strong>
            <br />
            Click OK to continue.
          </Typography>
        </Stack>
        <Stack alignItems="flex-end" margin={1}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {OK}
          </Button>
        </Stack>
      </Paper>
    </Modal>
  )
}

export default ConfirmBillingModal
