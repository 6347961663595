import React from 'react'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { setMessage } from '../../../UserFeedback/actionCreator'
import {
  VENDOR_PROPOSAL_DOWNLOAD,
  VENDOR_PROPOSAL_DOWNLOAD_ERROR,
  SET_MESSAGE_DELAY_FIX,
} from '../../../../globalConstants'

export const PdfButton = ({ preview, workOrder, setMessage }) => {
  const downloadPdf = () => {
    let feedbackMessage = VENDOR_PROPOSAL_DOWNLOAD
    try {
      const input = document.getElementById('wo-line-items')
      html2canvas(input, {
        scale: 0.8,
        allowTaint: false,
      }).then(function (canvas) {
        const base64image = canvas.toDataURL('image/jpeg')
        const imgWidth = 210
        const pageHeight = 295
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const doc = new jsPDF('p', 'mm', 'a4')
        const internalDocPageSize = doc.internal.pageSize
        const width = internalDocPageSize.getWidth()
        const height = internalDocPageSize.getHeight()
        let heightLeft = imgHeight
        let position = 0
        const commonAddImagePostParams = [
          imgWidth - 17,
          imgHeight,
          width,
          height,
        ]
        const commonAddImagePreParams = [base64image, 'JPEG', 10]

        doc.addImage(
          ...commonAddImagePreParams,
          10,
          ...commonAddImagePostParams,
        )
        heightLeft -= pageHeight
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight + 12
          doc.addPage()
          doc.addImage(
            ...commonAddImagePreParams,
            position,
            ...commonAddImagePostParams,
          )
          heightLeft -= pageHeight
        }
        const dateTime = moment().format('MM-DD-YYYY hh:mma')
        const fileName = `${dateTime}-WO-${workOrder?.work_order_number}-${
          workOrder?.asset_name || workOrder?.pm_space_asset_location
        }.pdf`
        doc.save(fileName)
      })
    } catch (e) {
      feedbackMessage = VENDOR_PROPOSAL_DOWNLOAD_ERROR
    } finally {
      setTimeout(() => {
        setMessage(feedbackMessage)
      }, SET_MESSAGE_DELAY_FIX)
    }
  }

  return (
    <Button
      onClick={downloadPdf}
      variant="contained"
      color="primary"
      size="large"
      disabled={!preview}
    >
      Download PDF
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    preview: state.workOrdersReducer.preview,
    workOrder: state.workOrdersReducer.workOrder,
  }
}

const mapDispatchToProps = {
  setMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfButton)
