import { getEnvConfig } from '@praxis/component-runtime-env'
import { merge } from 'lodash'

import commonConfig from './commonConfig'

const appConfig = {
  ...commonConfig,
  analytics: {
    appName: 'vendoradmin',
  },
}

const config = async () => {
  const envConfig = await getEnvConfig(
    process.env.NODE_ENV === 'development'
      ? '/config.json'
      : '/app-environment',
  )
  return merge(appConfig, envConfig)
}

export default config
