import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BigNumberCard from '../Common/BigNumberCard'
import TrendCard from '../Common/TrendCard'
import MetricsTableCard from '../Common/MetricsTableCard'
import ActionButton from '../../ActionButton/ActionButton'
import {
  getVisitComplianceFilters,
  getGreenfieldTimePeriod,
  getVisitTrendFilters,
} from '../../../service/common/GreenfieldService'
import { FISCAL_CAL_MONTH } from '../../../globalConstants'
import { useEnv } from '@praxis/component-runtime-env'
import { Link } from 'react-router-dom'
import withRouter from '../../../common/withRouter'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      paddingBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0, 2, 0),
    },
    buttonContainer: {
      marginRight: theme.spacing(1),
    },
    link: {
      textDecoration: 'none',
    },
  }),
)

const VisitCompliance = ({
  year,
  period,
  periodLabel,
  fiscalPeriodSelection,
  previousTwoPeriodOptions,
  business,
  companyId,
  calendar,
}) => {
  const classes = useStyles()
  const filters = getVisitComplianceFilters(
    year,
    period,
    business,
    companyId,
    calendar,
  )
  const trendFilters = getVisitTrendFilters(
    business,
    companyId,
    fiscalPeriodSelection,
    previousTwoPeriodOptions,
    calendar,
  )
  const commonTimePeriod = getGreenfieldTimePeriod()
  const reportsParams = `year=${year}&business=${business}&period=${period}&label=${periodLabel}&calendar=${calendar}`
  const isFiscalCalMonth = calendar === FISCAL_CAL_MONTH
  const env = useEnv()
  const cards = env.greenfield.cards
  const commonGridItemProps = {
    item: true,
    xs: 12,
    className: classes.gridItem,
  }

  return (
    <Paper square className={classes.paper}>
      <Grid container>
        <Grid
          {...commonGridItemProps}
          lg={6}
          container
          justifyContent="flex-start"
        >
          <BigNumberCard
            cardNumber={cards.visitComplianceBigNumber}
            filters={filters}
            timePeriod={commonTimePeriod}
          />
        </Grid>
        <Grid {...commonGridItemProps} lg={6}>
          <TrendCard
            cardNumber={
              isFiscalCalMonth
                ? cards.visitTrendCardMonth
                : cards.visitTrendCardWeek
            }
            filters={trendFilters}
            cardTitle={'Visit Trend'}
          />
        </Grid>
      </Grid>
      <Grid {...commonGridItemProps}>
        <Typography variant="subtitle2">
          Measures Visit Compliance by validating a check-in and check-out
          within the geo-fence for each visit. Each visit on a work order is
          measured separately.
        </Typography>
      </Grid>
      <Grid {...commonGridItemProps}>
        <MetricsTableCard
          cardNumber={cards.visitTotalsCard}
          filters={filters}
          timePeriod={commonTimePeriod}
        />
      </Grid>
      <Link to={`/outside-geofence#?${reportsParams}`} className={classes.link}>
        <ActionButton
          text={'outside geofence'}
          variant={'contained'}
          color={'primary'}
          className={classes.buttonContainer}
        />
      </Link>
      <Link to={`/missed-cico#?${reportsParams}`} className={classes.link}>
        <ActionButton
          text={'missed check in or check out'}
          variant={'contained'}
          color={'primary'}
          className={classes.buttonContainer}
        />
      </Link>
      <Link to={`/compliant-visits#?${reportsParams}`} className={classes.link}>
        <ActionButton
          text={'compliant visits'}
          variant={'contained'}
          color={'primary'}
        />
      </Link>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    previousTwoPeriodOptions: state.filtersReducer.previousTwoPeriodOptions,
    fiscalPeriodSelection: state.filtersReducer.fiscalPeriodSelection,
  }
}
export default withRouter(connect(mapStateToProps, null)(VisitCompliance))
