import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ExitDialog from './ExitDialog'
import { setURLSearchParams } from '../../windowManager'
import { LOG_OUT } from '../../globalConstants'
import ActionButton from '../ActionButton/ActionButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    logoutButton: {
      marginRight: theme.spacing(-1.5),
      color: theme.palette.secondary.action,
    },
    fullButton: {
      color: theme.palette.primary.action,
    },
  }),
)

const ExitButton = ({ fullButton }) => {
  const classes = useStyles()
  const onClick = () => {
    setURLSearchParams([{ name: 'open', value: true }])
  }
  return (
    <>
      {fullButton ? (
        <ActionButton
          className={classes.fullButton}
          text={LOG_OUT}
          icon={<ExitToAppIcon />}
          variant={'contained'}
          onClick={onClick}
        />
      ) : (
        <IconButton
          className={classes.logoutButton}
          data-cy="exit-button"
          onClick={onClick}
          aria-label={LOG_OUT}
        >
          <ExitToAppIcon />
        </IconButton>
      )}
      <ExitDialog />
    </>
  )
}

export default ExitButton
