import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import { setMessage } from './actionCreator'
import { Alert } from '@material-ui/lab'

const styles = (theme) => ({
  dark: {
    marginTop: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  severity: {
    marginTop: theme.spacing(7),
    border: '1px solid',
  },
})

const UserFeedback = ({ classes, message, open, setMessage, severity }) => {
  const onCloseSnackBar = () => {
    setMessage()
  }
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onCloseSnackBar}
    >
      <Alert
        className={severity ? classes.severity : classes.dark}
        severity={severity ? severity : ''}
      >
        <Typography>{message}</Typography>
      </Alert>
    </Snackbar>
  )
}

const mapStateToProps = (state) => {
  return {
    message: state.userFeedbackReducer.message,
    severity: state.userFeedbackReducer.severity,
    open: state.userFeedbackReducer.open,
  }
}

const mapDispatchToProps = {
  setMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UserFeedback))
