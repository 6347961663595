export const getURLSearchParams = () => {
  return new URLSearchParams(window.location.hash.split('#')[1])
}

const setLocation = (searchParams, replaceHistoryEntry) => {
  const windowLocation = window.location
  const baseURL = windowLocation.href.split('#')[0]
  let encodedURL = `${encodeURI(baseURL)}#?`
  for (const param of searchParams) {
    encodedURL += `${encodeURIComponent(param[0])}=${encodeURIComponent(
      param[1],
    )}&`
  }
  replaceHistoryEntry
    ? windowLocation.replace(encodedURL)
    : (windowLocation.href = encodedURL)
}

export const setURLSearchParams = (
  urlSearchParams = [],
  replaceHistoryEntry = false,
) => {
  const searchParams = getURLSearchParams()
  for (var i = 0; i < urlSearchParams.length; i++) {
    const urlSearchParam = urlSearchParams[i]
    searchParams.set(urlSearchParam.name, urlSearchParam.value)
  }
  setLocation(searchParams, replaceHistoryEntry)
}

export const getReportParams = () => {
  const searchParams = getURLSearchParams()
  const reportParams = {
    year: searchParams.get('year'),
    business: searchParams.get('business'),
    period: searchParams.get('period'),
    periodLabel: searchParams.get('label'),
    calendar: searchParams.get('calendar'),
  }
  return reportParams
}

export const getSessionStorage = () => {
  let storage = null
  try {
    storage = window.sessionStorage
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Session Storage Not Supported')
  }
  return storage
}

export const deleteURLSearchParams = (
  urlSearchParams = [],
  replaceHistoryEntry = false,
) => {
  const searchParams = getURLSearchParams()
  for (var i = 0; i < urlSearchParams.length; i++) {
    const urlSearchParam = urlSearchParams[i]
    searchParams.delete(urlSearchParam.name)
  }
  setLocation(searchParams, replaceHistoryEntry)
}
// fixes an edge case with setMessage not firing correctly in rapid succession
