import React, { useState } from 'react'
import { postData, fetchData } from '../../../../service/common/HttpService'
import { connect } from 'react-redux'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { setMessage } from '../../../UserFeedback/actionCreator'
import LoadingButton from '../../../LoadingButton/LoadingButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { getURLSearchParams } from '../../../../windowManager'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import { Stack } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    inputButton: {
      backgroundColor: theme.palette.secondary.action,
      marginRight: theme.spacing(1),
    },
    stack: {
      marginTop: theme.spacing(0.5),
    },
  }),
)

export const IncrementDecrementButton = ({
  optionDetails,
  technicianId,
  setMessage,
  setWorkOrder,
  workOrder,
  toggleRender,
  shouldRender,
}) => {
  const DEFAULT_COUNT = 1
  const SENDING_MATERIAL_LINE_ITEMS_ERROR =
    'There was an error sending back material line item entry.'
  const SENDING_MATERIAL_LINE_ITEMS_SUCCESS =
    'Material line item created successfully!'
  const ADD_TO_ITEMS_BUTTON = 'Add'
  const SENDING_MATERIAL_LINE_ITEMS_ZERO_NEG =
    'Error: Please add at least one material line item.'

  const amount = optionDetails.amount

  const classes = useStyles()
  const [count, setCount] = useState(DEFAULT_COUNT)
  const [isButtonLoading, setButtonIsLoading] = useState(false)

  const urlSearchParams = getURLSearchParams()
  const workOrderNumber = urlSearchParams.get('work_order_number')

  const addItemAmount = async () => {
    if (count >= DEFAULT_COUNT) {
      setButtonIsLoading(true)

      const materialLineItemPostBody = createPostDataForMaterialLineItem()

      const apiUrl = `work_order/${workOrderNumber}/lineitem`
      try {
        toggleRender(true)
        setMessage('Adding material line item...')
        await postData(apiUrl, materialLineItemPostBody)
        // Get updated line items from Maximo pass through endpoint and update workOrder state
        const response = await fetchData(
          `maximo/work_order/${workOrder.work_order_number}`,
        )
        setMessage(SENDING_MATERIAL_LINE_ITEMS_SUCCESS)
        setWorkOrder({
          ...workOrder,
          work_plan_item: response.work_plan_item,
        })
      } catch (error) {
        setMessage(SENDING_MATERIAL_LINE_ITEMS_ERROR)
      } finally {
        setButtonIsLoading(false)
        setCount(DEFAULT_COUNT)
        toggleRender(false)
      }
    } else {
      setMessage(SENDING_MATERIAL_LINE_ITEMS_ZERO_NEG)
    }
  }

  const createPostDataForMaterialLineItem = () => {
    const postDataBody = [
      {
        is_proposed_work: false,
        is_completed_work: true,
        is_non_catalog: false,
        description: optionDetails.description,
        line_item_type: optionDetails.line_item_type,
        location_id: workOrderNumber,
        unit_of_measure: optionDetails.unit_of_measure,
        supplier_part_id: optionDetails.supplier_part_id,
        catalog_name: optionDetails.catalog_name,
        request_by: technicianId,
        completed_quantity: count,
        completed_amount: amount,
        iso_labor_type: optionDetails.supplier_part_id,
      },
    ]

    return postDataBody
  }

  return (
    <Stack direction="row" className={classes.stack}>
      <OutlinedInput
        className={classes.inputButton}
        type={'number'}
        value={count}
        margin="dense"
        fullWidth
        disabled={shouldRender}
        onFocus={(event) => {
          event.target.select()
        }}
        onChange={(event) => {
          setCount(Math.abs(event.target.value))
        }}
      />
      <LoadingButton
        loading={isButtonLoading}
        disabled={shouldRender}
        onClick={addItemAmount}
        text={ADD_TO_ITEMS_BUTTON}
        color={'primary'}
        variant={'contained'}
      />
    </Stack>
  )
}

const mapStateToProps = (state) => {
  return {
    technicianId:
      state.authCredentialsReducer.auth.session.userInfo.technicianId,
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

const mapDispatchToProps = { setMessage, setWorkOrder, toggleRender }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncrementDecrementButton)
