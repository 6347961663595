/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import {
  SET_WORK_ORDERS,
  SET_TOGGLE,
  SET_VISITS,
  SET_WORK_ORDER,
  SET_PREVIEW,
  SET_TOTAL_COUNT_EXPORT,
  SET_SELECTED_WORK_LOG,
  SET_WORK_ORDER_ATTACHMENTS,
  SET_WORK_ORDER_ATTACHMENTS_INDEX,
  SET_NON_CATALOG_LINE_ITEM_TYPE,
} from './actionType'

const each = {
  value: 'EA',
  text: 'Each',
}

export const initialState = {
  workOrders: [],
  toggle: false,
  preview: false,
  visits: [],
  workOrder: {
    attachments: [],
    attachmentsIndex: 0,
  },
  worklogNoteTypes: [],
  selectedWorkLog: [],
  totalCount: 0,
  itemType: 'parts',
  lineItemTypeLabor: [
    {
      value: 'HR',
      text: 'Hours',
    },
    each,
  ],
  lineItemTypeParts: [
    {
      value: 'DZ',
      text: 'Dozen',
    },
    {
      value: 'OZ',
      text: 'Ounce',
    },
    {
      value: 'GAL',
      text: 'Gallon',
    },
    {
      value: 'QT',
      text: 'Quart',
    },
    {
      value: 'LB',
      text: 'Pounds',
    },
    {
      value: 'FT',
      text: 'Feet',
    },
    {
      value: 'IN',
      text: 'Inch',
    },
    each,
  ],
}

export const sortByDate = (arr) => {
  if (arr?.length >= 1) {
    arr.sort((a, b) => {
      return new Date(b.formatted_date) - new Date(a.formatted_date)
    })
  }
  return arr
}

export const calculateTotals = (lineItems) => {
  let catalogParts = 0
  let nonCatalog = 0
  let catalogLabor = 0
  let quantities = 0
  let incurredTotal = 0
  let hours = 0
  for (let item of lineItems) {
    const cost = item.completed_line_cost
    if (cost) {
      if (item.incurred_item) {
        incurredTotal += cost
      }
      if (item.nonCatalog) {
        nonCatalog += cost
      } else if (item.line_item_type.toLowerCase() === 'parts') {
        catalogParts += cost
      } else {
        const unit = item.unit_of_measure
        const amount = item.completed_quantity
        catalogLabor += cost
        quantities += unit === 'EA' && amount
        hours += unit === 'HR' && amount
      }
    }
  }
  const grandTotal = catalogParts + catalogLabor + nonCatalog
  const totals = {
    catalog_parts_total: catalogParts,
    catalog_labor_total: catalogLabor,
    non_catalog_total: nonCatalog,
    grand_total: grandTotal,
    incurred_total: incurredTotal,
  }
  return [totals, quantities, hours]
}

export const separateLogs = (workOrder) => {
  let workOrderLogs = workOrder?.work_order_logs
  let proposalNotes = workOrder?.proposal_notes
  // If wo has falsy proposal_notes property, it's directly from API, so need to separate logs
  // If truthy proposal_notes property, just need to return logs from previous state as they are already separated
  if (!proposalNotes) {
    proposalNotes = []
    const nonProposalLogs = []
    const sortedLogs = sortByDate(workOrderLogs) || []
    for (const log of sortedLogs) {
      const type = log.log_type
      const isProposalNote =
        type === 'VENDOR_PROPOSAL' || type === 'TGT_PROP_REVIEW'
      isProposalNote ? proposalNotes.push(log) : nonProposalLogs.push(log)
    }
    workOrderLogs = nonProposalLogs
  }
  return { proposalNotes: proposalNotes, nonProposalLogs: workOrderLogs }
}

const isValidFile = (url) => {
  const validFileTypes = ['jpg', 'pdf', 'jpeg', 'gif', 'bmp', 'png']
  const extension = url?.split('.')?.pop()
  return validFileTypes.includes(extension)
}

const getFilteredAttachments = (attachments) => {
  // remove all those that do not have a valid file extension
  // attachments with falsy urls are also removed
  const filteredAtachments = attachments?.filter((attachment) =>
    isValidFile(attachment.url),
  )
  return filteredAtachments
}

export default function workOrdersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NON_CATALOG_LINE_ITEM_TYPE: {
      const newState = {
        ...state,
        itemType: action.itemType.toLowerCase(),
      }
      return newState
    }
    case SET_WORK_ORDER_ATTACHMENTS_INDEX: {
      const newState = {
        ...state,
        workOrder: {
          ...state.workOrder,
          attachmentsIndex: action.attachmentsIndex,
        },
      }
      return newState
    }
    case SET_WORK_ORDER_ATTACHMENTS: {
      const newState = {
        ...state,
        workOrder: {
          ...state.workOrder,
          attachments: action.attachments,
        },
      }
      return newState
    }
    case SET_WORK_ORDERS: {
      const workOrders = action.workOrders
      // dataGrid doesn't seem to support nested JSON refrerences for fields, so we need to lift them up
      workOrders.forEach((workOrder) => {
        // Manually setting id until work order id is added to pmwovisit common model, id required by datagrid
        workOrder['id'] = workOrder.work_order_number
        const pmContract = workOrder.pm_contract
        workOrder.converted_response = pmContract?.converted_response
        workOrder.converted_repair = pmContract?.converted_repair
      })
      const newState = {
        ...state,
        workOrders: workOrders,
      }
      return newState
    }
    case SET_TOGGLE: {
      const newState = {
        ...state,
        toggle: action.toggle,
      }
      return newState
    }
    case SET_PREVIEW: {
      const newState = {
        ...state,
        preview: action.preview,
      }
      return newState
    }
    case SET_VISITS: {
      const visits = action.visits
      const newState = {
        ...state,
        visits: visits,
      }
      return newState
    }
    case SET_WORK_ORDER: {
      const workOrder = action.workOrder
      const filteredAttachments = getFilteredAttachments(workOrder.attachments)
      const workOrder_plan_item = workOrder?.work_plan_item
      const { proposalNotes, nonProposalLogs } = separateLogs(workOrder)
      const updatedTotals = workOrder.work_plan_item
        ? calculateTotals(workOrder.work_plan_item)
        : []
      const newState = {
        ...state,
        workOrder: {
          ...workOrder,
          attachments: filteredAttachments,
          work_order_logs: nonProposalLogs,
          proposal_notes: proposalNotes,
          line_item_totals: updatedTotals[0],
          total_catalog_quantities: updatedTotals[1],
          total_catalog_hours: updatedTotals[2],
          non_catalog_summary: {
            line_item_totals: workOrder_plan_item
              ?.filter((wo) => wo.is_non_catalog)
              .reduce((sum, curr) => sum + curr.completed_line_cost, 0),
            line_item_hrs: workOrder_plan_item
              ?.filter((wo) => wo.is_non_catalog && wo.unit_of_measure === 'HR')
              .reduce((sum, curr) => sum + curr.completed_quantity, 0),
            line_item_qty: workOrder_plan_item
              ?.filter((wo) => wo.is_non_catalog && wo.unit_of_measure !== 'HR')
              .reduce((sum, curr) => sum + curr.completed_quantity, 0),
          },
          catalog_parts_summary: {
            line_item_totals: workOrder_plan_item
              ?.filter(
                (wo) => !wo.is_non_catalog && wo.line_item_type === 'Parts',
              )
              .reduce((sum, curr) => sum + curr.completed_line_cost, 0),
            line_item_qty: workOrder_plan_item
              ?.filter(
                (wo) => !wo.is_non_catalog && wo.line_item_type === 'Parts',
              )
              .reduce((sum, curr) => sum + curr.completed_quantity, 0),
          },
        },
      }
      return newState
    }
    case SET_SELECTED_WORK_LOG: {
      return {
        ...state,
        selectedWorkLog: action.selectedWorkLog,
      }
    }
    case SET_TOTAL_COUNT_EXPORT: {
      const newState = {
        ...state,
        totalCountExport: action.totalCountExport,
      }
      return newState
    }
    default:
      return state
  }
}
