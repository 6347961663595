import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { VENDOR_ADMIN, CANCEL, OK, LOG_OUT } from '../../globalConstants'
import { getURLSearchParams } from '../../windowManager'
import { useAuth } from '@praxis/component-auth'

const DIALOG_TITLE = 'alert-dialog-title'
const DIALOG_DESCRIPTION = 'alert-dialog-description'
class ExitDialog extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
    }
  }

  onHashChange = () => {
    const searchParams = getURLSearchParams()
    const open = Boolean(searchParams.get('open'))
    this.setState({ open: open })
  }

  onClose = () => {
    window.history.back()
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  render = () => {
    const { open } = this.state

    /* hooks, like auth, must be used inside a functional component */
    const ConfirmLogoutButton = () => {
      const auth = useAuth()
      return (
        <Button onClick={() => auth.logout()} autoFocus>
          {OK}
        </Button>
      )
    }

    return (
      <Dialog
        onClose={this.onClose}
        open={open}
        aria-labelledby={DIALOG_TITLE}
        aria-describedby={DIALOG_DESCRIPTION}
      >
        <DialogTitle id={DIALOG_TITLE}>Confirm {LOG_OUT}</DialogTitle>
        <DialogContent>
          <DialogContentText id={DIALOG_DESCRIPTION}>
            {LOG_OUT} of {VENDOR_ADMIN}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose}>{CANCEL}</Button>
          <ConfirmLogoutButton />
        </DialogActions>
      </Dialog>
    )
  }
}

export default ExitDialog
