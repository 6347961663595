import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import BigNumberCard from './Common/BigNumberCard'
import {
  getRepairFilters,
  getGreenfieldTimePeriod,
} from '../../service/common/GreenfieldService'
import { useEnv } from '@praxis/component-runtime-env'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0, 2, 0),
    },
    gridItem: {
      paddingBottom: theme.spacing(2),
    },
  }),
)

const SameDayFix = ({ year, period, business, companyId, calendar }) => {
  const classes = useStyles()
  const filters = getRepairFilters(year, period, business, companyId, calendar)
  const commonTimePeriod = getGreenfieldTimePeriod()
  const env = useEnv()
  const cards = env.greenfield.cards

  const commonGridItemProps = {
    item: true,
    xs: 12,
  }

  return (
    <Paper square className={classes.paper}>
      <Grid container>
        <Grid
          {...commonGridItemProps}
          lg={6}
          container
          justifyContent="flex-start"
          className={classes.gridItem}
        >
          <BigNumberCard
            cardNumber={cards.sameDayFixBigNumber}
            filters={filters}
            timePeriod={commonTimePeriod}
          />
        </Grid>
        <Grid {...commonGridItemProps}>
          <Typography variant="subtitle2">
            Measures work orders which are fixed on the the same day the vendor
            technician 1st checks in on-site. From 1st check-in to VREPAIR
            status.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SameDayFix
