/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Alert,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
} from '@mui/material'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { fetchData } from '../../../service/common/HttpService'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      margin: theme.spacing(0, 2),
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
    },
    table: {
      backgroundColor: theme.palette.secondary.action,
      marginBottom: theme.spacing(2),
    },
  }),
)
export const AssetDetails = ({ workOrder, shouldRender, toggleRender }) => {
  const classes = useStyles()
  const [assetDetails, setAssetDetails] = useState({})
  const [error, setError] = useState(false)
  useEffect(async () => {
    try {
      setError(false)
      toggleRender(true)
      const response = await fetchData(
        `location/${workOrder.location_id}/asset/${workOrder.asset_number}`,
      )
      setAssetDetails(response)
    } catch (error) {
      setError(true)
    } finally {
      toggleRender(false)
    }
  }, [])
  return (
    <>
      {/* No error, details are returned and are finished loading */}
      {!error && Object.keys(assetDetails).length && !shouldRender ? (
        <Grid className={classes.outerContainer} container>
          <Grid item xs={12} md={6}>
            <Table data-testid="assetDetails" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <strong>Details</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell width={1}>
                    <Typography noWrap>Name:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.asset_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography noWrap>Number:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.asset_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography noWrap>Description:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.asset_description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography noWrap>Priority:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.priority}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={6}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <strong>Specifications</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell width={1}>
                    <Typography noWrap>Make:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.asset_make}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography noWrap>Model:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.asset_model}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography noWrap>Serial Number:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.serial_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography noWrap>Install Date:</Typography>
                  </TableCell>
                  <TableCell>{assetDetails.install_date}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      ) : null}
      {/* Error thrown getting asset details, so show error */}
      {error && (
        <Alert
          className={classes.alert}
          severity="error"
          data-testid="alert-error"
        >
          Unable to connect to the asset details service.
        </Alert>
      )}
      {/* No error, no asset details and finished loading */}
      {!error && !Object.keys(assetDetails).length && !shouldRender && (
        <Alert
          className={classes.alert}
          severity="warning"
          data-testid="alert-warning"
        >
          No information found for this asset.
        </Alert>
      )}
      {/* No error, but still loading asset details */}
      {!error && shouldRender && (
        <Alert
          className={classes.alert}
          severity="info"
          data-testid="alert-info"
        >
          Loading asset details information...
        </Alert>
      )}
    </>
  )
}
export const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}
const mapDispatchToProps = {
  toggleRender,
}
export default connect(mapStateToProps, mapDispatchToProps)(AssetDetails)
