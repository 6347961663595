/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setToggle } from '../WorkOrdersStore/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    refreshButton: {
      position: 'absolute',
      margin: theme.spacing(-0.2, 0, 0, 2),
    },
  }),
)

export const RefreshButton = ({
  setToggle,
  toggle,
  buttonLabel = 'Try Again?',
}) => {
  const classes = useStyles()
  return (
    <Button
      component="span"
      variant="outlined"
      color="inherit"
      className={classes.refreshButton}
      size="small"
      onClick={() => setToggle(!toggle)}
    >
      {buttonLabel}
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    toggle: state.workOrdersReducer.toggle,
  }
}

const mapDispatchToProps = {
  setToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshButton)
