import { makeStyles, createStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import {
  DELETE_ERROR,
  DELETE_SUCCESS,
  FORMATTER,
  SAVING_ERROR,
  QUANTITY_SUCCESS,
  INCURRED_SUCCESS,
  DELETING,
  SAVING,
  GOLDEN_RATIO_AS_PERCENT_REVERSED,
} from '../../../../globalConstants'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import {
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Input,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { deleteData, putData } from '../../../../service/common/HttpService'
import { setMessage } from '../../../UserFeedback/actionCreator'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import { NumericFormat } from 'react-number-format'
import { Checkbox, Stack } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    totals: {
      fontWeight: 'bold',
    },
    tableContainer: {
      marginBottom: theme.spacing(2),
    },
    editIcon: {
      opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
      marginLeft: -theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
  }),
)

export const SavedMaterialCatalogLineItems = ({
  workOrder,
  shouldRender,
  toggleRender,
  setWorkOrder,
  setMessage,
}) => {
  const catalogPartsSummary = workOrder.catalog_parts_summary || {}
  const workPlanItems = workOrder.work_plan_item || []
  const partsLineItems = workPlanItems.filter(
    (x) =>
      x.is_non_catalog === false &&
      x.line_item_type !== null &&
      x.line_item_type?.toLowerCase() === 'parts',
  )

  const classes = useStyles()

  const COMPLETED_QUANTITY = 'completed_quantity'
  const INCURRED_ITEM = 'incurred_item'

  const columns = [
    {
      field: 'description',
      headerName: 'Description',
    },
    {
      field: 'unit_of_measure',
      headerName: 'Unit of Measure',
    },
    {
      field: 'completed_amount',
      headerName: 'Cost',
    },
    {
      field: 'completed_quantity',
      headerName: 'Quantity',
    },
    {
      field: 'completed_line_cost',
      headerName: 'Line Cost',
    },
    {
      field: 'incurred_item',
      headerName: 'Incurred Item?',
      width: 'auto',
    },
    {
      field: 'delete',
      headerName: <div data-html2canvas-ignore="true">Delete</div>,
      renderCell: (params) => {
        return (
          <IconButton
            disabled={shouldRender}
            onClick={() => {
              handleDelete(params.row)
            }}
            data-html2canvas-ignore="true"
          >
            <DeleteForeverIcon />
          </IconButton>
        )
      },
    },
  ]
  const handleDelete = async (row) => {
    setMessage(DELETING)

    const deleteBody = [
      {
        line_item_type: 'Parts',
        work_order_plan_item_id: row.work_order_plan_item_id,
      },
    ]
    toggleRender(true)
    try {
      await deleteData(
        `work_order/${workOrder.work_order_number}/lineitem`,
        deleteBody,
      )
      //If successfull then reset workorder in redux
      const newLineItems = workOrder.work_plan_item.filter((x) => {
        return x.work_order_plan_item_id !== row.work_order_plan_item_id
      })

      const newWorkOrder = {
        ...workOrder,
        work_plan_item: newLineItems,
      }
      setWorkOrder(newWorkOrder)
      setMessage(DELETE_SUCCESS)
    } catch (e) {
      setMessage(DELETE_ERROR)
    } finally {
      toggleRender(false)
    }
  }

  const /* istanbul ignore next */ handleEdit = async (event, row) => {
      const itemId = row.work_order_plan_item_id
      const eventTarget = event.target
      const fieldName = eventTarget.name
      const isIncurredItem = fieldName === INCURRED_ITEM
      const newValue = isIncurredItem
        ? eventTarget.checked
        : Number(eventTarget.value)
      const filteredLineItems = workPlanItems.filter(
        (r) => r.work_order_plan_item_id === itemId,
      )
      const firstFilteredLineItem = filteredLineItems[0]

      const putBody = isIncurredItem
        ? [
            {
              ...firstFilteredLineItem,
              incurred_item: newValue,
              is_proposed_work: false,
              is_completed_work: true,
            },
          ]
        : [
            {
              ...firstFilteredLineItem,
              completed_quantity: newValue,
              is_proposed_work: false,
              is_completed_work: true,
            },
          ]
      if (
        newValue !== firstFilteredLineItem.completed_quantity &&
        newValue !== firstFilteredLineItem.incurred_item
      ) {
        setTimeout(() => {
          setMessage(SAVING)
        }, 100)
        toggleRender(true)
        try {
          await putData(
            `work_order/${workOrder.work_order_number}/lineitem`,
            putBody,
          )
          // After success, we know line items are in maximo, but could still be in write queue for Core
          // To prevent desync, we manually update line items attached to work order
          const updateLineItemForReducer = isIncurredItem
            ? { incurred_item: newValue }
            : {
                completed_quantity: newValue,
                completed_line_cost:
                  firstFilteredLineItem.completed_amount * newValue,
              }
          const updatedLineItems = workOrder.work_plan_item.map((obj) =>
            obj.work_order_plan_item_id === itemId
              ? {
                  ...obj,
                  ...updateLineItemForReducer,
                }
              : obj,
          )
          const updatedWorkOrder = {
            ...workOrder,
            work_plan_item: updatedLineItems,
          }
          setWorkOrder(updatedWorkOrder)
          setMessage(isIncurredItem ? INCURRED_SUCCESS : QUANTITY_SUCCESS)
        } catch (e) {
          setMessage(SAVING_ERROR)
        } finally {
          toggleRender(false)
        }
      }
    }

  const formatCurrency = (value) => FORMATTER.format(value)

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {partsLineItems.length ? (
            <>
              {partsLineItems.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.unit_of_measure}</TableCell>
                  <TableCell>
                    {FORMATTER.format(row.completed_amount || 0)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                      <NumericFormat
                        name={COMPLETED_QUANTITY}
                        customInput={Input}
                        value={row.completed_quantity || ''}
                        defaultValue={row.completed_quantity}
                        onBlur={(event) => {
                          event.target.value !== row.completed_quantity &&
                            handleEdit(event, row)
                        }}
                        decimalScale={2}
                        disabled={shouldRender}
                        allowNegative={false}
                      />
                      <EditIcon fontSize="small" className={classes.editIcon} />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {FORMATTER.format(row.completed_line_cost || 0)}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      name={INCURRED_ITEM}
                      onChange={(event) => handleEdit(event, row)}
                      disabled={shouldRender}
                      checked={row.incurred_item}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      data-html2canvas-ignore="true"
                      disabled={shouldRender}
                      onClick={() => {
                        handleDelete(row)
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={1} className={classes.totals}>
                  Totals:
                </TableCell>
                <TableCell
                  colSpan={1}
                  className={classes.totals}
                  id="catalogPartsQty"
                >
                  {catalogPartsSummary.line_item_qty}
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={classes.totals}
                  id="catalogPartsTotals"
                >
                  {formatCurrency(catalogPartsSummary.line_item_totals)}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography>No rows</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setWorkOrder,
  setMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavedMaterialCatalogLineItems)
