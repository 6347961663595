/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { Stack } from '@mui/material'
import { CircularProgress, IconButton, Typography } from '@material-ui/core'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import { fetchData } from '../../service/common/HttpService'
import { connect } from 'react-redux'
import { getURLSearchParams } from '../../windowManager'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'
import {
  setWorkOrderAttachments,
  setWorkOrderAttachmentsIndex,
} from '../WorkOrders/WorkOrdersStore/actionCreator'
import { ViewCarousel, Close } from '@material-ui/icons'
import { setURLSearchParams } from '../../windowManager'
import {
  GOLDEN_RATIO_AS_PERCENT,
  GOLDEN_RATIO_AS_PERCENT_REVERSED,
} from '../../globalConstants'
import { Document, Page, pdfjs } from 'react-pdf'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import DownloadIcon from '@mui/icons-material/Download'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DETAIL_GRID_THEME_HEIGHT = 3.5

// track this variable to help avoid memory leaks in edge cases
let isMounted = false

const useStyles = makeStyles((theme) => ({
  container: {
    height: theme.spacing(34),
    position: 'relative',
    overflow: 'hidden',
    borderColor: alpha(theme.palette.primary.main, GOLDEN_RATIO_AS_PERCENT),
    borderWidth: 1,
    borderStyle: 'solid',
  },
  fullHeight: {
    height: '100vh',
  },
  actionStack: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.secondary.action,
    backgroundColor: alpha(theme.palette.primary.main, GOLDEN_RATIO_AS_PERCENT),
    height: theme.spacing(DETAIL_GRID_THEME_HEIGHT),
    borderBottomLeftRadius: theme.spacing(GOLDEN_RATIO_AS_PERCENT),
  },
  stepper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: theme.palette.secondary.action,
    backgroundColor: alpha(theme.palette.primary.main, GOLDEN_RATIO_AS_PERCENT),
    height: theme.spacing(DETAIL_GRID_THEME_HEIGHT),
  },
  heightAuto: {
    height: 'auto',
  },
  button: {
    color: theme.palette.secondary.action,
    '&:disabled': {
      color: 'inherit',
      opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  pdf: {
    position: 'absolute',
    top: 0,
    margin: '0px auto',
  },
  backgroundImage: {
    opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
    filter: 'blur(8px)',
    backgroundSize: 'cover',
  },
  contentImage: {
    backgroundSize: 'contain',
  },
  icon: {
    color: theme.palette.secondary.action,
  },
  iconButton: {
    padding: (0, theme.spacing(GOLDEN_RATIO_AS_PERCENT)),
  },
  contentStack: {
    height: '100%',
  },
  brokenImageIcon: {
    fontSize: theme.spacing(10),
    opacity: GOLDEN_RATIO_AS_PERCENT,
  },
  viewCarousel: {
    fontSize: theme.spacing(25),
    opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
  },
  pointer: {
    cursor: 'pointer',
  },
  caption: {
    marginLeft: theme.spacing(2),
  },
  pagination: {
    position: 'absolute',
    top: theme.spacing(6),
    right: theme.spacing(2.5),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
  },
}))

const ImageCarousel = ({
  attachments = [],
  setWorkOrderAttachments,
  setWorkOrderAttachmentsIndex,
  inline = false,
  attachmentsIndex = 0,
  toggle,
}) => {
  const classes = useStyles()
  const workOrderNumber = getURLSearchParams().get('work_order_number')
  const attachmentsLength = attachments.length
  const multipleAttachments = attachmentsLength > 1
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const options = {
    isEvalSupported: false,
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const handleNext = () => {
    setPageNumber(1)
    setWorkOrderAttachmentsIndex(attachmentsIndex + 1)
  }

  const handleBack = () => {
    setPageNumber(1)
    setWorkOrderAttachmentsIndex(attachmentsIndex - 1)
  }

  const cloneAttachments = () => {
    // we don't mutate state directly...
    return attachments.map((value) => Object.assign({}, value))
  }

  const fetchAttachment = async (index) => {
    const clonedAttachments = cloneAttachments()
    const attachment = clonedAttachments[index]
    try {
      const response = await fetchData(
        `work_order/${workOrderNumber}/image/${attachment.attachment_id}`,
      )
      attachment.src = `data:application/pdf;base64,${response}`
    } catch (e) {
      attachment.error = true
    } finally {
      attachment.loaded = true
      attachment.work_order_number ===
        getURLSearchParams().get('work_order_number') &&
        isMounted &&
        setWorkOrderAttachments(clonedAttachments)
    }
  }

  useEffect(() => {
    if (inline) {
      isMounted = true
      return () => {
        // clean up after we leave
        isMounted = false
        setWorkOrderAttachments([])
      }
    }
  }, [toggle])

  useEffect(() => {
    if (attachmentsLength && isMounted && inline) {
      for (let i = 0; i < attachmentsLength; i++) {
        if (!attachments[i].loaded) {
          fetchAttachment(i)
          break
        }
      }
    }
  }, [attachments])

  const onClickZoom = () => {
    inline &&
      attachmentsLength &&
      setURLSearchParams([{ name: 'image_dialog', value: true }])
  }

  const downloadImage = (attachment) => {
    const a = document.createElement('a')
    const url = attachment.src

    a.href = url
    a.download = attachment.url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const getDownloadBtn = () => {
    // only show the download button if the content it will download is ready to download
    const attachment = attachments[attachmentsIndex]
    const isValid = attachment?.loaded && !attachment?.error
    return (
      isValid && (
        <IconButton
          className={classes.iconButton}
          onClick={() => downloadImage(attachments[attachmentsIndex])}
        >
          <DownloadIcon className={classes.icon} fontSize="small" />
        </IconButton>
      )
    )
  }

  const getContent = () => {
    let content = <CircularProgress />
    const attachment = attachments[attachmentsIndex]
    const attachmentSrc = attachment?.src
    const extension = attachment?.url.split('.').pop()
    const style = {
      backgroundImage: `url(${attachmentSrc})`,
    }

    if (attachment?.loaded && !attachment?.error) {
      content =
        extension !== 'pdf' ? (
          <>
            <div
              className={`${classes.backgroundImage} ${classes.image}`}
              style={style}
            />
            <div
              className={`${classes.contentImage} ${classes.image}`}
              style={style}
            />
          </>
        ) : (
          <>
            <Document
              file={attachmentSrc}
              onLoadSuccess={onDocumentLoadSuccess}
              className={`${classes.pdf}`}
              options={options}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            {numPages > 1 && !inline && (
              <Stack
                spacing={1}
                direction="row"
                className={classes.pagination}
                alignItems="center"
              >
                <IconButton
                  disabled={pageNumber < 2}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Typography>
                  {pageNumber} of {numPages}
                </Typography>
                <IconButton
                  onClick={() => setPageNumber(pageNumber + 1)}
                  disabled={pageNumber === numPages}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Stack>
            )}
          </>
        )
    }
    if (attachment?.loaded && attachment?.error) {
      content = <BrokenImageIcon className={classes.brokenImageIcon} />
    }
    return content
  }

  const commonButtonProps = {
    size: inline ? 'small' : 'large',
    className: classes.button,
  }

  return (
    <div className={`${classes.container} ${!inline && classes.fullHeight}`}>
      <Stack
        justifyContent="center"
        alignItems="center"
        className={`${classes.contentStack} ${
          inline && attachmentsLength && classes.pointer
        }`}
        onClick={onClickZoom}
      >
        {attachmentsLength ? (
          getContent()
        ) : (
          <ViewCarousel className={classes.viewCarousel} />
        )}
      </Stack>
      <Stack
        className={classes.actionStack}
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {attachmentsLength ? (
          <Typography variant="caption" className={classes.caption} noWrap>
            {attachments[attachmentsIndex].url}
          </Typography>
        ) : null}
        {attachmentsLength && inline ? (
          <>
            {getDownloadBtn()}

            <IconButton className={classes.iconButton} onClick={onClickZoom}>
              <ZoomInIcon className={classes.icon} fontSize="small" />
            </IconButton>
          </>
        ) : null}
        {!inline && (
          <>
            {getDownloadBtn()}

            <IconButton
              className={classes.iconButton}
              onClick={() => {
                window.history.back()
              }}
            >
              <Close className={classes.icon} fontSize="medium" />
            </IconButton>
          </>
        )}
      </Stack>
      {attachmentsLength && (
        <MobileStepper
          steps={attachmentsLength}
          variant="text"
          className={`${classes.stepper} ${!inline && classes.heightAuto}`}
          activeStep={attachmentsIndex}
          nextButton={
            multipleAttachments && (
              <Button
                {...commonButtonProps}
                onClick={handleNext}
                disabled={attachmentsIndex === attachmentsLength - 1}
              >
                {!inline && 'NEXT'}
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            multipleAttachments && (
              <Button
                {...commonButtonProps}
                onClick={handleBack}
                disabled={attachmentsIndex === 0}
              >
                <KeyboardArrowLeft />
                {!inline && 'PREVIOUS'}
              </Button>
            )
          }
        />
      )}
    </div>
  )
}

const mapDispatchToProps = {
  setWorkOrderAttachments,
  setWorkOrderAttachmentsIndex,
}

const mapStateToProps = (state) => {
  return {
    attachments: state.workOrdersReducer.workOrder.attachments,
    attachmentsIndex: state.workOrdersReducer.workOrder.attachmentsIndex,
    toggle: state.workOrdersReducer.toggle,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel)
