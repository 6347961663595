import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { CSVLink } from 'react-csv'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { postGreenfieldData } from '../../service/common/HttpService'
import { connect } from 'react-redux'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { setMessage } from '../UserFeedback/actionCreator'

const DOWNLOAD_IN_PROGRESS = 'We are downloading your file'
const DOWNLOAD_EMPTY = 'The service returned no data to download'
const DOWNLOAD_ERROR = 'There was an error downloading your report'

const styles = (theme) => ({
  icon: {
    color: theme.palette.secondary.action,
  },
})
class Download extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      headers: [],
    }
    this.csvLink = React.createRef()
  }

  getReportData = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    const { toggleRender, setMessage, reportParams } = this.props
    const { cardNumber, body } = reportParams
    let message = null
    setMessage(DOWNLOAD_IN_PROGRESS)
    try {
      toggleRender(true)
      const response = await postGreenfieldData(cardNumber, body)
      if (response?.query_results?.length) {
        const headers =
          response?.card_config?.card_query_attribute?.columns.map((column) => {
            return { label: column.column_display_name, key: column.ref_id }
          })
        this.setState({ data: response.query_results, headers: headers })
        this.csvLink.current.link.click()
      } else {
        message = DOWNLOAD_EMPTY
      }
    } catch (e) {
      message = DOWNLOAD_ERROR
    } finally {
      toggleRender(false)
      setMessage(message)
    }
  }

  render() {
    const { classes, reportParams } = this.props
    const state = this.state
    const reportTitle = reportParams.reportTitle
    const fileName = reportTitle && `${reportTitle.replace(/ /g, '_')}.csv`
    return (
      <CSVLink
        data={state.data}
        headers={state.headers}
        filename={fileName}
        ref={this.csvLink}
      >
        <IconButton
          onClick={(event) => this.getReportData(event)}
          aria-label={'Download'}
        >
          <SaveAltIcon className={classes.icon} />
        </IconButton>
      </CSVLink>
    )
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Download))
