/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Container,
  ListSubheader,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { fetchData, postData } from '../../../../service/common/HttpService'
import { setMessage } from '../../../UserFeedback/actionCreator'
import { connect } from 'react-redux'
import LoadingButton from '../../../LoadingButton/LoadingButton'
import {
  FORMATTER,
  GOLDEN_RATIO_AS_PERCENT_REVERSED,
} from '../../../../globalConstants'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import { Stack } from '@mui/material'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import clsx from 'clsx'
import EditIcon from '@mui/icons-material/Edit'

const useStyles = makeStyles((theme) =>
  createStyles({
    listSubheader: {
      marginTop: theme.spacing(2),
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    buttonContainer: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.secondary.action,
    },
    editIcon: {
      opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
      marginLeft: -theme.spacing(2),
      marginTop: theme.spacing(1.5),
      float: 'right',
    },
    grid: {
      backgroundColor: theme.palette.secondary.action,
      '& .ag-root-wrapper': {
        borderRadius: '6px 6px 0px 0px!important',
      },
      '& .ag-row.ag-row-hover::before': {
        backgroundColor: 'transparent !important',
      },
    },
    quantityCell: {
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
  }),
)

const MAX_LINES_SAVE = 10

export const CatalogLaborLineSelection = ({
  workOrder,
  companyId,
  technicianId,
  setMessage,
  setWorkOrder,
  toggleRender,
  shouldRender,
  preview,
}) => {
  const classes = useStyles()
  const [lineItems, setLineItems] = useState([])
  const woNumber = workOrder.work_order_number
  const clearLineItemCache = () => {
    const tempLineItems = lineItems.map((a) => {
      return { ...a }
    })

    tempLineItems.forEach((lineItem) => {
      lineItem.completed_quantity = undefined
    })

    setLineItems(tempLineItems)
  }

  const handleChange = (event, row, index) => {
    const value = event.newValue
    const tempLineItems = lineItems.map((a) => {
      return { ...a }
    })
    const currentLineItem = tempLineItems[index]
    currentLineItem.completed_quantity = value
    currentLineItem.total = row.amount * value
    setLineItems(tempLineItems)
  }

  const getLineItemData = async () => {
    let lineItems = []
    try {
      toggleRender(true)
      setLineItems(lineItems)
      const response = await fetchData(
        `company/${companyId}/catalog/services?reported_date=${workOrder.reported_date}&commodity_code=${workOrder.classification_code}&site_id=${workOrder.location_id}&page_number=0&per_page=1000`,
      )
      const partsList = response.catalog_items
      lineItems = partsList && partsList
      partsList.length && setLineItems(lineItems)
    } catch {
      setMessage('There was an error loading line items.')
    } finally {
      setLineItems(lineItems)
      toggleRender(false)
    }
  }

  useEffect(() => {
    clearLineItemCache()
    workOrder?.location_id && getLineItemData()
  }, [workOrder.work_order_number])

  const getPostObject = () => {
    const tempLineItems = []
    lineItems.forEach((lineItem) => {
      lineItem?.completed_quantity > 0 &&
        tempLineItems.push({
          is_proposed_work: false,
          is_completed_work: true,
          is_non_catalog: false,
          description: lineItem.description
            .replace(/(\r\n|\n|\r)/gm, '')
            .replace(/['"]+/g, ''),
          line_item_type: lineItem.line_item_type,
          location_id: lineItem.location_id,
          unit_of_measure: lineItem.unit_of_measure,
          supplier_part_id: lineItem.supplier_part_id,
          catalog_name: lineItem.catalog_name,
          request_by: technicianId,
          completed_quantity: lineItem.completed_quantity,
          completed_amount: lineItem.amount,
          iso_labor_type: lineItem.iso_part_description,
          iso_labor_type2: '',
        })
    })
    return tempLineItems
  }

  const handleSaveClick = /* istanbul ignore next */ async () => {
    const postObj = getPostObject()
    const fetchUrl = `work_order/${woNumber}/lineitem`
    try {
      toggleRender(true)
      if (postObj.length > MAX_LINES_SAVE) {
        setMessage(
          `Please limit to saving up to ${MAX_LINES_SAVE} line items at a time`,
        )
      } else {
        setMessage('We are adding your line items...')
        await postData(fetchUrl, postObj)
        // Get updated line items from Maximo pass through endpoint and update workOrder state
        const response = await fetchData(`maximo/work_order/${woNumber}`)
        setWorkOrder({
          ...workOrder,
          work_plan_item: response.work_plan_item,
        })
        clearLineItemCache()
        setMessage('Line item quantities were saved successfully')
      }
    } catch {
      setMessage('There was an error saving line items.')
    } finally {
      toggleRender(false)
    }
  }

  const columns = [
    {
      field: 'description',
      headerName: 'Description',
      filter: 'agTextColumnFilter',
      flex: 1,
      sortable: true,
      floatingFilter: true,
      menuTabs: [],
    },
    {
      field: 'amount',
      headerName: 'Unit Cost',
      filter: 'agNumberColumnFilter',
      flex: 1,
      sortable: true,
      valueFormatter: (value) => FORMATTER.format(value.value),
      floatingFilter: true,
      menuTabs: [],
    },
    {
      field: 'completed_quantity',
      filter: 'agNumberColumnFilter',
      floatingFilter: true,
      menuTabs: [],
      editable: !shouldRender ? true : false,
      headerName: 'Hours/Quantity',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
      },
      sortable: true,
      onCellValueChanged: (params) => {
        const row = params.data
        const rowIndex = lineItems.findIndex((r) => r.id === row.id)
        handleChange(params, row, rowIndex)
      },
      cellClass: clsx(classes.quantityCell),
      cellRenderer: (params) => {
        return (
          <div>
            {params.value}
            <EditIcon className={classes.editIcon} />
          </div>
        )
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      filter: 'agNumberColumnFilter',
      menuTabs: [],
      floatingFilter: true,
      flex: 1,
      valueGetter: (params) => {
        const total = params.data?.completed_quantity * params.data?.amount || 0
        return params.data.total ? total : 0
      },
      valueFormatter: (value) => {
        return FORMATTER.format(value.value)
      },
      sortable: true,
      cellStyle: { textAlign: 'right' },
    },
  ]

  return (
    <>
      {!preview && (
        <Container
          maxWidth="xl"
          disableGutters
          className={classes.outerContainer}
          data-html2canvas-ignore="true"
        >
          <ListSubheader
            disableSticky
            color="primary"
            className={classes.listSubheader}
          >
            <strong id="listSubheaderLabel">
              Catalog Labor Line Selection
            </strong>
          </ListSubheader>
          <div
            className={`ag-theme-quartz ${classes.grid}`}
            style={{
              height: lineItems.length ? '50vh' : 250,
            }}
          >
            {!shouldRender || lineItems.length ? (
              <AgGridReact
                rowData={lineItems}
                columnDefs={columns}
                rowModelType={'clientSide'}
                rowHeight={50}
                stopEditingWhenCellsLoseFocus={true}
              />
            ) : null}
            {shouldRender && !lineItems.length && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <CircularProgress />
              </Stack>
            )}
          </div>
          {lineItems.length ? (
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonContainer}
            >
              <LoadingButton
                color={'primary'}
                loading={shouldRender}
                disabled={shouldRender}
                variant={'contained'}
                onClick={handleSaveClick}
                text={'Save Catalog Labor Items'}
              />
            </Grid>
          ) : null}
        </Container>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    companyId: state.authCredentialsReducer.companyId,
    technicianId:
      state.authCredentialsReducer.auth.session.userInfo.technicianId,
    shouldRender: state.linearProgressReducer.shouldRender,
    preview: state.workOrdersReducer.preview,
  }
}
const mapDispatchToProps = {
  setMessage,
  setWorkOrder,
  toggleRender,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogLaborLineSelection)
