import { SET_STATUS_COUNTS, SET_AGING_WORK_ORDERS } from './actionType'

export function setStatusCounts(statusCounts) {
  return {
    type: SET_STATUS_COUNTS,
    statusCounts: statusCounts,
  }
}

export function setAgingWorkOrders(workOrders) {
  return {
    type: SET_AGING_WORK_ORDERS,
    workOrders: workOrders,
  }
}
