import {
  SET_FISCAL_CALENDAR_SELECTION,
  SET_FISCAL_YEAR_SELECTION,
  SET_FISCAL_YEAR_OPTIONS,
  SET_FISCAL_PERIOD_SELECTION,
  SET_FISCAL_PERIOD_OPTIONS,
  SET_BUSINESS_SELECTION,
  SET_BUSINESS_OPTIONS,
} from './actionType'

export function setFiscalCalendarSelection(fiscalCalendarSelection) {
  return {
    type: SET_FISCAL_CALENDAR_SELECTION,
    fiscalCalendarSelection: fiscalCalendarSelection,
  }
}

export function setFiscalYearSelection(fiscalYearSelection) {
  return {
    type: SET_FISCAL_YEAR_SELECTION,
    fiscalYearSelection: fiscalYearSelection,
  }
}

export function setFiscalYearOptions(fiscalYearOptions) {
  return {
    type: SET_FISCAL_YEAR_OPTIONS,
    fiscalYearOptions: fiscalYearOptions,
  }
}

export function setFiscalPeriodSelection(fiscalPeriodSelection) {
  return {
    type: SET_FISCAL_PERIOD_SELECTION,
    fiscalPeriodSelection: fiscalPeriodSelection,
  }
}

export function setFiscalPeriodOptions(fiscalPeriodOptions) {
  return {
    type: SET_FISCAL_PERIOD_OPTIONS,
    fiscalPeriodOptions: fiscalPeriodOptions,
  }
}

export function setBusinessSelection(businessSelection) {
  return {
    type: SET_BUSINESS_SELECTION,
    businessSelection: businessSelection,
  }
}

export function setBusinessOptions(businessOptions) {
  return {
    type: SET_BUSINESS_OPTIONS,
    businessOptions: businessOptions,
  }
}
