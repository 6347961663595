/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { ListSubheader, Container } from '@material-ui/core'
import { fetchData, putData } from '../../../service/common/HttpService'
import { connect } from 'react-redux'
import { getURLSearchParams } from '../../../windowManager'
import { setMessage } from '../../UserFeedback/actionCreator'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setToggle } from '../WorkOrdersStore/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    innerContainer: {
      backgroundColor: theme.palette.secondary.action,
      padding: theme.spacing(2),
      borderRadius: 4,
    },
  }),
)

export const WorkOrderCauses = ({
  workOrder,
  setMessage,
  toggleRender,
  toggle,
  shouldRender,
  setToggle,
}) => {
  const classes = useStyles()
  const workOrderNumber = getURLSearchParams().get('work_order_number')
  const [causeList, setCauseList] = useState([])
  const [error, setError] = useState(false)
  const [causeRender, setCauseRender] = useState(false)

  const errorMessageHandler = (error, message) => {
    const errorResponseDataMessage = error.response?.data.message
    const processedMessage =
      errorResponseDataMessage !== 'null' ? errorResponseDataMessage : message
    setMessage(processedMessage)
    setError(true)
  }

  const onChange = async (event) => {
    try {
      const eventCause = event.target.value
      toggleRender(true)
      setCauseRender(true)
      setError(false)
      setMessage(`Updating cause to ${eventCause}...`)
      await putData(`work_order/${workOrderNumber}`, {
        cause: eventCause,
      })
      setMessage(`Cause has been updated to ${eventCause}.`)
    } catch (error) {
      errorMessageHandler(
        error,
        'There was an error updating the work order cause.',
      )
    } finally {
      toggleRender(false)
      setCauseRender(false)
      setToggle(!toggle)
    }
  }

  useEffect(async () => {
    if (workOrder.problem_code !== undefined) {
      setCauseRender(true)
      toggleRender(true)
      setError(false)
      try {
        const response = await fetchData(
          `work_order/${workOrderNumber}/problem/${workOrder.problem_code}/causes`,
        )
        setCauseList(response || [])
      } catch (error) {
        errorMessageHandler(
          error,
          'There was an error getting the work order causes.',
        )
      } finally {
        toggleRender(false)
        setCauseRender(false)
      }
    }
  }, [workOrder.problem_code])
  return (
    <Container maxWidth="xl" disableGutters className={classes.outerContainer}>
      <ListSubheader disableSticky color="primary">
        <strong>Causes</strong>
      </ListSubheader>
      <FormControl fullWidth className={classes.innerContainer}>
        {
          // utilizing both causeRender and shouldRender toggles to ensure that cause is only rendered when cause is changed,
          //  but still use the loading bar from shouldRender
          causeRender && shouldRender && !error && (
            <Typography>Causes Are Loading . . .</Typography>
          )
        }
        {!causeRender && !error ? (
          <>
            <Select
              value={causeList.length > 0 ? workOrder.cause : ''}
              onChange={(event) => onChange(event)}
            >
              {causeList.map((cause, index) => {
                return (
                  <MenuItem value={cause.failure_code} key={index}>
                    {cause.failure_code}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>
              Selected cause. If blank, no cause is selected.
            </FormHelperText>
          </>
        ) : null}
        {
          // there was an error
          error && (
            <Typography>
              There was a problem loading causes / no causes found.
            </Typography>
          )
        }
      </FormControl>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    toggle: state.workOrdersReducer.toggle,
    workOrder: state.workOrdersReducer.workOrder,
    companyId: state.authCredentialsReducer.companyId,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
  setToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderCauses)
