import { setURLSearchParams } from '../../../windowManager'
import CreateWorkLog from './CreateWorkLog'
import LoadingButton from '../../LoadingButton/LoadingButton'
import { connect } from 'react-redux'

export const AddWorkLogButton = ({ text, urlParam, logType, shouldRender }) => {
  const onClick = () => {
    setURLSearchParams([{ name: urlParam, value: true }])
  }

  return (
    <>
      <LoadingButton
        text={text}
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={shouldRender}
      />
      <CreateWorkLog text={text} urlParam={urlParam} logType={logType} />
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}
export default connect(mapStateToProps, null)(AddWorkLogButton)
