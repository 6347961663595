import {
  SET_FISCAL_CALENDAR_SELECTION,
  SET_FISCAL_YEAR_SELECTION,
  SET_FISCAL_YEAR_OPTIONS,
  SET_FISCAL_PERIOD_SELECTION,
  SET_FISCAL_PERIOD_OPTIONS,
  SET_BUSINESS_SELECTION,
  SET_BUSINESS_OPTIONS,
} from './actionType'
import { getSessionStorage } from '../../../windowManager'
import moment from 'moment'
import { FISCAL_CAL_MONTH } from '../../../globalConstants'

const storage = getSessionStorage()

const getLabel = (periodSelection) => {
  const dateFormat = 'M/DD'
  return periodSelection?.acct_mo_label
    ? periodSelection.acct_mo_label
    : `Week ${periodSelection?.acct_wk_i}: ${moment(
        periodSelection?.st_wk_chosen,
      ).format(dateFormat)} -
        ${moment(periodSelection?.end_wk_chosen).format(dateFormat)}`
}

export const initialState = {
  fiscalCalendarSelection:
    (storage && storage.getItem(SET_FISCAL_CALENDAR_SELECTION)) ||
    FISCAL_CAL_MONTH,
  fiscalYearSelection:
    (storage && storage.getItem(SET_FISCAL_YEAR_SELECTION)) ||
    new Date().getFullYear(),
  fiscalYearOptions: [],
  fiscalPeriodSelection:
    (storage && JSON.parse(storage.getItem(SET_FISCAL_PERIOD_SELECTION))) ||
    null,
  fiscalPeriodOptions: [],
  previousTwoPeriodOptions: [],
  businessSelection: (storage && storage.getItem(SET_BUSINESS_SELECTION)) || '',
  businessOptions: [],
  fiscalPeriodSelectionLabel: '',
}

export default function filtersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FISCAL_CALENDAR_SELECTION: {
      storage && storage.removeItem(SET_FISCAL_PERIOD_SELECTION)
      const calendarSelection = action.fiscalCalendarSelection
      const newState = {
        ...state,
        fiscalCalendarSelection: calendarSelection,
      }
      storage &&
        storage.setItem(SET_FISCAL_CALENDAR_SELECTION, calendarSelection)
      return newState
    }
    case SET_FISCAL_YEAR_SELECTION: {
      storage && storage.removeItem(SET_FISCAL_PERIOD_SELECTION)
      const yearSelection = action.fiscalYearSelection.toString()
      const newState = {
        ...state,
        fiscalYearSelection: yearSelection,
        fiscalPeriodSelection: null,
      }
      storage && storage.setItem(SET_FISCAL_YEAR_SELECTION, yearSelection)
      return newState
    }
    case SET_FISCAL_YEAR_OPTIONS: {
      const savedYearSelection =
        storage && storage.getItem(SET_FISCAL_YEAR_SELECTION)
      const yearOptions = action.fiscalYearOptions
      const newYearSelection = savedYearSelection || yearOptions[0].toString()
      const newState = {
        ...state,
        fiscalYearOptions: yearOptions,
        fiscalYearSelection: newYearSelection,
      }
      storage && storage.setItem(SET_FISCAL_YEAR_SELECTION, newYearSelection)
      return newState
    }
    case SET_FISCAL_PERIOD_SELECTION: {
      const periodSelection = action.fiscalPeriodSelection
      const newState = {
        ...state,
        fiscalPeriodSelection: periodSelection,
        fiscalPeriodSelectionLabel: getLabel(periodSelection),
      }
      if (periodSelection) {
        storage &&
          storage.setItem(
            SET_FISCAL_PERIOD_SELECTION,
            JSON.stringify(periodSelection),
          )
      }
      return newState
    }
    case SET_FISCAL_PERIOD_OPTIONS: {
      const options = action.fiscalPeriodOptions
      const selectedPeriodOptions = options.selected_year
      const previousPeriodOptions = options.previous_year
      const previousTwoPeriodOptions = previousPeriodOptions.concat(
        selectedPeriodOptions,
      )
      const calendarSelection = state.fiscalCalendarSelection
      const savedPeriodSelection =
        storage && JSON.parse(storage.getItem(SET_FISCAL_PERIOD_SELECTION))
      const foundPeriod =
        savedPeriodSelection &&
        selectedPeriodOptions.find(
          (period) =>
            period[calendarSelection] ===
            savedPeriodSelection[calendarSelection],
        )
      const newPeriodSelection =
        foundPeriod || selectedPeriodOptions[selectedPeriodOptions.length - 1]
      const newState = {
        ...state,
        fiscalPeriodOptions: selectedPeriodOptions,
        previousTwoPeriodOptions: previousTwoPeriodOptions,
        fiscalPeriodSelection: newPeriodSelection,
        fiscalPeriodSelectionLabel: getLabel(newPeriodSelection),
      }
      if (newPeriodSelection) {
        storage &&
          storage.setItem(
            SET_FISCAL_PERIOD_SELECTION,
            JSON.stringify(newPeriodSelection),
          )
      }
      return newState
    }
    case SET_BUSINESS_SELECTION: {
      const businessSelection = action.businessSelection
      const newState = {
        ...state,
        businessSelection: businessSelection,
      }
      storage && storage.setItem(SET_BUSINESS_SELECTION, businessSelection)
      return newState
    }
    case SET_BUSINESS_OPTIONS: {
      const businessOptions = action.businessOptions
      const savedBusinessSelection =
        storage && storage.getItem(SET_BUSINESS_SELECTION)
      const newBusinessSelection = savedBusinessSelection || businessOptions[0]
      const newState = {
        ...state,
        businessOptions: businessOptions,
        businessSelection: newBusinessSelection,
      }
      storage && storage.setItem(SET_BUSINESS_SELECTION, newBusinessSelection)
      return newState
    }
    default:
      return state
  }
}
