/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Grid,
  Alert,
  createStyles,
  Typography,
  Container,
  Dialog,
  IconButton,
} from '@mui/material'
import LoadingButton from '../LoadingButton/LoadingButton'
import { getURLSearchParams } from '../../windowManager'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { postData } from '../../service/common/HttpService'
import { setMessage } from '../UserFeedback/actionCreator'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CircularProgress from '@material-ui/core/CircularProgress'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import PhotoIcon from '@material-ui/icons/Photo'
import { useDropzone } from 'react-dropzone'
import { Close } from '@material-ui/icons'
import { setToggle } from '../WorkOrders/WorkOrdersStore/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    modalHeader: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.action,
      padding: theme.spacing(1, 2),
    },
    container: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2, 0),
      backgroundColor: theme.palette.secondary.action,
    },
    icon: {
      color: theme.palette.secondary.action,
    },
    paper: {
      backgroundColor: theme.palette.background.default,
    },
    photoIcon: {
      margin: theme.spacing(0, 2, 0, 0.6),
    },
    bodyTextContainer: {
      padding: theme.spacing(2, 1),
    },
    imagePreview: {
      width: '100%',
      borderWidth: theme.spacing(0, 0.5, 0.5, 0.5),
      borderColor: theme.palette.primary.alternate,
      borderStyle: 'solid',
      backgroundColor: theme.palette.primary.main,
      display: 'block',
    },
    gridThumb: {
      padding: theme.spacing(1),
      width: '100%',
    },
    thumbTypography: {
      color: theme.palette.secondary.action,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1, 1.5),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    statusText: {
      color: theme.palette.secondary.action,
    },
    imageIcon: {
      color: theme.palette.secondary.action,
      marginRight: theme.spacing(1.5),
    },
    statusHeader: {
      padding: theme.spacing(1, 1.5),
      backgroundColor: theme.palette.primary.alternate,
    },
    progressIcon: {
      margin: theme.spacing(0, 2, 0, 2.5),
    },
    introHeader: {
      margin: theme.spacing(1, 0, 2, 0),
    },
    thumbContainer: {
      marginTop: theme.spacing(1),
    },
    alert: {
      margin: theme.spacing(3, 1, 0, 1),
    },
    actionBar: {
      paddingRight: theme.spacing(1),
    },
    pdfPreview: {
      width: '100%',
      height: '30em',
      borderWidth: theme.spacing(0, 0.5, 0.5, 0.5),
      borderColor: theme.palette.primary.alternate,
      borderStyle: 'solid',
      backgroundColor: theme.palette.primary.main,
      display: 'block',
    },
  }),
)

export const AttachmentUpload = ({ setMessage, setToggle, toggle }) => {
  const theme = useTheme()
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const filesLength = files.length
  // fileIndex tracks which file is currently uploading from the files array
  const [fileIndex, setFileIndex] = useState(0)
  // fileUploadToggle is a boolean we flip back and forth to trigger the custom useEffect
  // further down in the class
  const [fileUploadToggle, setFileUploadToggle] = useState(false)
  // flags if we are in the process of uploading attachments
  const [uploadingAttachments, setUploadingAttachments] = useState(false)
  const searchParams = getURLSearchParams()
  const workOrderNumber = searchParams.get('work_order_number')
  const imageIconClass = classes.imageIcon
  const statusTextClass = classes.statusText
  const circularProgressSize = 24
  const MAX_FILES = 5
  const UPLOAD_ATTACHMENTS = 'Upload Attachments'
  // delivers the thumb previews and user feedback on individual upload progress
  const thumbs = files.map((file, index) => (
    <Grid item key={index} xs={12} sm={6} md={4} className={classes.gridThumb}>
      <Typography className={classes.thumbTypography}>{file.path}</Typography>
      <Grid container className={classes.statusHeader} alignItems="center">
        {
          // is the file both not loaded and not loading?
          // then it's queued
          !file.loaded && !file.loading && (
            <>
              <HourglassEmptyIcon className={imageIconClass} />
              <Typography component="span" className={statusTextClass}>
                Queued {`${index + 1} of ${filesLength}`}
              </Typography>
            </>
          )
        }
        {
          // did the error prop get tripped?
          // then the upload on this image failed
          file.error && (
            <>
              <ErrorIcon className={imageIconClass} />
              <Typography component="span" className={statusTextClass}>
                Upload Failed
              </Typography>
            </>
          )
        }
        {
          // is the file both not loaded, and currently loading?
          // then it is the one currently being uploaded
          !file.loaded && file.loading && (
            <>
              <CircularProgress
                size={circularProgressSize}
                className={imageIconClass}
              />
              <Typography component="span" className={statusTextClass}>
                Uploading...
              </Typography>
            </>
          )
        }
        {
          // is the file not in an error state?
          // is the file also not loading?
          // is the file also done being loaded?
          // then the process has completed successfully
          !file.error && !file.loading && file.loaded && (
            <>
              <CheckCircleIcon className={imageIconClass} />
              <Typography component="span" className={statusTextClass}>
                Upload Successful
              </Typography>
            </>
          )
        }
      </Grid>
      {file.type === 'application/pdf' ? (
        <div>
          <object
            data={`${file.preview}#toolbar=0`}
            title={file.name}
            className={classes.pdfPreview}
          >
            <p>{file.name}</p>
          </object>
        </div>
      ) : (
        <img
          src={file.preview}
          alt={file.name}
          className={classes.imagePreview}
        />
      )}
    </Grid>
  ))

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = (error) => reject(error)
    })

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg, application/pdf',
    maxFiles: MAX_FILES,
    // onDrop is fired after the user has provided some images for upload
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            loaded: false,
            // is this the first file in the array?
            // if so, set it's loading status to true, cause FIFO
            loading: index === 0 ? true : false,
            error: false,
          }),
        ),
      )
      // this the beginning of the process,
      // so we set the file index to 0 (first file)
      setFileIndex(0)
      // we flip the toggle boolean to start the uploadImage process
      // in the custom useEffect further down in the class
      setFileUploadToggle(!fileUploadToggle)
    },
  })

  const uploadAttachment = async () => {
    setUploadingAttachments(true)
    // get a handle to the current file we are uploading
    const file = files[fileIndex]
    // get a handle to the next file index
    const nextIndex = fileIndex + 1
    // get a handle to the next file we will be uploading
    const nextFile = files[nextIndex]
    try {
      const postBody = {
        name: file.name,
        description: file.name,
        base64_data: await toBase64(file),
      }
      await postData(`work_order/${workOrderNumber}/attachment`, postBody)
    } catch (e) {
      setMessage(`Image upload service failed. Please try again later.`)
      file.error = true
    } finally {
      // we set loaded to true, and loading to false,
      // as regardless of what state the uploaded ended up in,
      // succeess or failure, we are done with the process now,
      // so loaded is always true, and loading is always false here
      file.loaded = true
      file.loading = false
      // if the nextFile is truthy, then set its state to loading
      if (nextFile) {
        nextFile.loading = true
      }
      // update the file index to indicate we are moving on to the next image
      setFileIndex(nextIndex)
      // flip the toggle boolean to trigger the custom useEffect logic
      setFileUploadToggle(!fileUploadToggle)
    }
  }

  useEffect(() => {
    // this is called when the component mounts,
    // and whenever the fileUploadToggle boolean is flipped.
    // is the fileIndex less than the total number of files?
    // if yes, we have another image to attempt an upload on...
    // if no, we are done.
    if (fileIndex < filesLength) {
      uploadAttachment()
    } else {
      setUploadingAttachments(false)
    }
  }, [fileUploadToggle])

  // this fires if the user tries to select too many images
  useEffect(() => {
    fileRejections.length > MAX_FILES &&
      setMessage(
        `Too many images selected. Please choose ${MAX_FILES} or less.`,
      )
  }, [fileRejections.length])

  const [open, setOpen] = useState(false)

  const onHashChange = () => {
    const searchParams = getURLSearchParams()
    const uploadParam = Boolean(searchParams.get('upload'))
    setOpen(uploadParam)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const handleClose = () => {
    // only close if we are not in the middle of uploading attachments
    if (!uploadingAttachments) {
      // if files array is not empty, we uploaded files - refresh work order to see new attachments
      files.length && setToggle(!toggle)
      // clear files to reset file upload process
      setFiles([])
      window.history.back()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { backgroundColor: theme.palette.background.default },
      }}
      fullScreen
    >
      <Grid container className={classes.modalHeader}>
        <Grid item xs={9}>
          <Typography variant="h5">{UPLOAD_ATTACHMENTS}</Typography>
        </Grid>
        <Grid item container justifyContent={'right'} xs={3}>
          <IconButton className={classes.iconButton} onClick={handleClose}>
            <Close className={classes.icon} fontSize="medium" />
          </IconButton>
        </Grid>
      </Grid>
      <Container className={classes.container} maxWidth="xl">
        {fileIndex < filesLength ? (
          <Grid container className={classes.introHeader}>
            <Grid container alignItems="center">
              <CircularProgress
                className={classes.progressIcon}
                size={circularProgressSize}
              />
              <Typography component="span">
                <strong>
                  Currently uploading file {fileIndex + 1} / {filesLength} ...
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert
                variant="filled"
                severity="warning"
                className={classes.alert}
              >
                Please stay on this page until your images have finished
                uploading.
              </Alert>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container alignItems="center">
              <PhotoIcon fontSize="large" className={classes.photoIcon} />
              <Typography variant="h6" component="span">
                How to {UPLOAD_ATTACHMENTS}
              </Typography>
            </Grid>
            <Grid className={classes.bodyTextContainer}>
              <Typography>
                Please click on the {UPLOAD_ATTACHMENTS} button below to select
                up to <strong>{MAX_FILES}</strong> files at a time to attach to
                work order number <strong>{workOrderNumber}</strong>. <br />
                Accepted file types are <strong>.jpeg</strong>,{' '}
                <strong>.jpg</strong>,<strong> .png</strong>, and{' '}
                <strong>.pdf</strong>.
              </Typography>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <LoadingButton
                  text={'back'}
                  variant="outlined"
                  onClick={handleClose}
                  aria-label="back"
                  color="primary"
                />
              </Grid>
              <Grid
                item
                {...getRootProps({ className: 'dropzone' })}
                className={classes.actionBar}
              >
                <input {...getInputProps()} />
                <LoadingButton
                  text={'select attachments'}
                  variant="contained"
                  color="primary"
                  icon={<AttachFileIcon />}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container className={classes.thumbContainer}>
          {thumbs}
        </Grid>
      </Container>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    toggle: state.workOrdersReducer.toggle,
    userId: state.authCredentialsReducer.auth.session.userInfo.technicianId,
    workOrder: state.workOrdersReducer.workOrder,
  }
}

const mapDispatchToProps = { setMessage, setToggle }

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentUpload)
