import React, { Component } from 'react'
import Home from '../Home/Home'
import UserFeedback from '../UserFeedback/UserFeedback'
import { Routes, Route } from 'react-router-dom'
import withRouter from '../../common/withRouter'
import OutsideGeofenceReport from '../ScoreCard/VisitCompliance/OutsideGeofenceReport'
import MissedCICOReport from '../ScoreCard/VisitCompliance/MissedCICOReport'
import ResponseFailsReport from '../ScoreCard/ResponseCompliance/ResponseFailsReport'
import RepairFailsReport from '../ScoreCard/RepairCompliance/RepairFailsReport'
import CompliantVisitsReport from '../ScoreCard/VisitCompliance/CompliantVisitsReport'
import { withAuth } from '@praxis/component-auth'
import { withStyles } from '@material-ui/core/styles'
import authUtilFunctions from '../AuthUtil'
import { LOGIN_COOKIE } from '../../globalConstants'
import WorkOrderLists from '../WorkOrders/WorkOrderLists/WorkOrderLists'
import MapView from '../WorkOrders/MapView/MapView'
import WorkOrderDetails from '../WorkOrders/WorkOrderDetails/WorkOrderDetails'
import WorkLogs from '../WorkOrders/WorkLogs/WorkLogs'
import LineItems from '../WorkOrders/LineItems/LineItems'
import WorkOrderCauseRemedy from '../WorkOrders/WorkOrderCauseRemedy/WorkOrderCauseRemedy'
import { withEnv } from '@praxis/component-runtime-env'
import { connect } from 'react-redux'
import Dashboard from '../Dashboard/Dashboard'
import { AnalyticsProvider } from '@praxis/component-analytics'
import AssetDetails from '../WorkOrders/AssetDetails/AssetDetails'
import AppRestrictedDialog from '../WorkOrders/common/AppRestrictedDialog'

const styles = (theme) => ({
  offset: theme.mixins.toolbar,
})

export class GatedContent extends Component {
  componentDidUpdate(prevProps) {
    const { auth, location } = this.props
    if (location !== prevProps.location) {
      authUtilFunctions.authUtil(LOGIN_COOKIE, auth.session)
    }
  }
  render = () => {
    const { classes, env } = this.props
    const eventManagerConfiguration = {
      appName: 'vendoradmin',
      apiKey: env.analytics.apiKey,
      url: env.analytics.url,
    }
    return (
      <AnalyticsProvider
        eventManagerConfiguration={eventManagerConfiguration}
        trackLocations
      >
        <AppRestrictedDialog />
        <div className={classes.offset} />
        <UserFeedback />
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/home'} element={<Home />} />
          <Route
            path={'/outside-geofence'}
            element={<OutsideGeofenceReport />}
          />
          <Route path={'/missed-cico'} element={<MissedCICOReport />} />
          <Route path={'/response-fails'} element={<ResponseFailsReport />} />
          <Route path={'/repair-fails'} element={<RepairFailsReport />} />
          <Route
            path={'/compliant-visits'}
            element={<CompliantVisitsReport />}
          />
          <Route path={'/dashboard'} element={<Dashboard />} />
          <Route path={'/work-orders'} element={<WorkOrderLists />} />
          <Route
            path={'/work-order-details'}
            element={
              <>
                <WorkOrderDetails />
                <WorkLogs />
              </>
            }
          />
          <Route
            data-testid="line-items"
            path={'/work-order-details/lineitems'}
            element={
              <>
                <WorkOrderDetails />
                <LineItems />
              </>
            }
          />
          <Route
            path={'/work-order-details/cause-remedy'}
            element={
              <>
                <WorkOrderDetails />
                <WorkOrderCauseRemedy />
              </>
            }
          />
          <Route
            path={'/work-order-details/asset-details'}
            element={
              <>
                <WorkOrderDetails />
                <AssetDetails />
              </>
            }
          />
          <Route
            path={'/work-order-details/history-details'}
            element={
              <>
                <WorkOrderDetails />
              </>
            }
          />
          <Route path={'/work-order-details/map-view'} element={<MapView />} />
        </Routes>
      </AnalyticsProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default connect(mapStateToProps)(
  withAuth(null)(withStyles(styles)(withRouter(withEnv()(GatedContent)))),
)
