import React from 'react'
import { connect } from 'react-redux'
import ScoreCardReport from '../Common/ScoreCardReport'
import { getReportParams } from '../../../windowManager'
import { getRepairFilters } from '../../../service/common/GreenfieldService'
import { useEnv } from '@praxis/component-runtime-env'

const REPAIR_FAILS_TITLE = 'Repair Time Failures'

const RepairFailsReport = ({ companyId }) => {
  const { year, business, period, periodLabel, calendar } = getReportParams()
  const filters = getRepairFilters(year, period, business, companyId, calendar)
  const env = useEnv()

  return (
    <ScoreCardReport
      title={REPAIR_FAILS_TITLE}
      cardNumber={env.greenfield.cards.repairFails}
      filters={filters}
      business={business}
      year={year}
      periodLabel={periodLabel}
    />
  )
}

export const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default connect(mapStateToProps, null)(RepairFailsReport)
