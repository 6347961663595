import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { connect } from 'react-redux'
import { setFiscalYearSelection, setFiscalYearOptions } from './actionCreator'
import {
  NUMBER_OF_YEARS_OF_INFORMATION,
  FIRST_YEAR_OF_INFORMATION,
} from '../../../globalConstants'
export class YearDropdown extends Component {
  componentDidMount = () => {
    this.getOptions()
  }

  componentDidUpdate = (prevProps) => {
    const { fiscalCalendarSelection } = this.props
    if (prevProps.fiscalCalendarSelection !== fiscalCalendarSelection) {
      this.getOptions()
    }
  }

  getOptions = () => {
    const { setFiscalYearOptions } = this.props
    const options = []
    const currentYear = new Date().getFullYear()
    // setting the minOffset and maxoffset to get data for the range of previous 7 years
    // checking to see the year is 2019 and above as there is no data before 2019
    const maxOffset =
      currentYear >= FIRST_YEAR_OF_INFORMATION &&
      currentYear <=
        FIRST_YEAR_OF_INFORMATION + NUMBER_OF_YEARS_OF_INFORMATION - 1
        ? currentYear - FIRST_YEAR_OF_INFORMATION
        : NUMBER_OF_YEARS_OF_INFORMATION
    for (let i = 0; i <= maxOffset; i++) {
      options.push(currentYear - i)
    }
    setFiscalYearOptions(options)
  }

  handleChange = (event) => {
    const { setFiscalYearSelection } = this.props
    setFiscalYearSelection(event.target.value)
  }

  render = () => {
    const { fiscalYearSelection, fiscalYearOptions } = this.props
    const fiscalYearOptionsLength =
      fiscalYearOptions && fiscalYearOptions.length

    return (
      fiscalYearOptionsLength && (
        <FormControl fullWidth>
          <Select
            value={fiscalYearSelection || ''}
            onChange={this.handleChange}
          >
            {fiscalYearOptions.map((option, index) => {
              return (
                <MenuItem value={option} key={index}>
                  {option}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    fiscalCalendarSelection: state.filtersReducer.fiscalCalendarSelection,
    fiscalYearSelection: state.filtersReducer.fiscalYearSelection,
    fiscalYearOptions: state.filtersReducer.fiscalYearOptions,
  }
}

const mapDispatchToProps = {
  setFiscalYearSelection,
  setFiscalYearOptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(YearDropdown)
