import React from 'react'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  actionText: {
    marginLeft: theme.spacing(1),
  },
})

const ActionButton = ({
  text,
  onClick,
  icon,
  disabled = false,
  variant = 'text',
  classes,
  color = 'default',
  className,
}) => {
  return (
    <Button
      aria-label={text}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      color={color}
      className={className}
    >
      {icon}
      <Typography variant={'button'} className={classes.actionText}>
        {text}
      </Typography>
    </Button>
  )
}

export default withStyles(styles)(ActionButton)
