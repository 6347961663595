import React, { Component } from 'react'
import GatedContent from './GatedContent'
import {
  setAuthCredentials,
  setCompany,
} from '../../common/UserAuth/actionCreator'
import { fetchData } from '../../service/common/HttpService'
import { withAuth } from '@praxis/component-auth'
import { connect } from 'react-redux'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { setMessage, setError } from '../SplashScreen/actionCreator'
import Login from '../Login/Login'
import SplashScreen from '../SplashScreen/SplashScreen'
import authUtilFunctions from '../AuthUtil'
import { LOGIN_COOKIE } from '../../globalConstants'

class Layout extends Component {
  componentDidMount = async () => {
    const {
      auth,
      setMessage,
      toggleRender,
      setError,
      setCompany,
      setAuthCredentials,
    } = this.props
    const authSession = auth.session
    if (authSession && authSession.validated) {
      setAuthCredentials(auth)
      try {
        const data = await fetchData(`company_data`)
        setCompany(data)
      } catch (e) {
        setError(true)
        setMessage('Unable to get Company ID')
        toggleRender(false)
      }
    } else {
      authUtilFunctions.authUtil(LOGIN_COOKIE, authSession)
    }
  }
  render = () => {
    const { companyId, auth } = this.props
    let content = <Login />
    if (auth.session) {
      content = companyId ? <GatedContent /> : <SplashScreen />
    }
    return content
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

const mapDispatchToProps = {
  setAuthCredentials,
  setCompany,
  toggleRender,
  setMessage,
  setError,
}

export default withAuth(null)(
  connect(mapStateToProps, mapDispatchToProps)(Layout),
)
