/*
  Page: Login
  Author: Felix Herrera
  Description: Automatically renders OAuth 2 login
*/
import { useAuth } from '@praxis/component-auth'

const Login = () => {
  useAuth().login() // connect to the `AuthContext` provided by `App` below, using a React hook.
  return null
}

export default Login
