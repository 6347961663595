import { useLocation, useNavigate, useParams } from 'react-router-dom'

// With react-router-dom 6, withRouter no longer supported - per documentation recommend using built in hooks to recreate the same functionality
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

export default withRouter
