import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { BACK } from '../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    leftIconButton: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(-1.5),
      color: theme.palette.secondary.action,
    },
  }),
)

const BackButton = () => {
  const classes = useStyles()
  const handleArrowBackClick = () => {
    window.history.back()
  }

  return (
    <IconButton
      onClick={handleArrowBackClick}
      aria-label={BACK}
      className={classes.leftIconButton}
    >
      <ArrowBack />
    </IconButton>
  )
}

export default BackButton
