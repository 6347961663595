import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BigNumberCard from '../Common/BigNumberCard'
import TrendCard from '../Common/TrendCard'
import MetricsTableCard from '../Common/MetricsTableCard'
import ActionButton from '../../ActionButton/ActionButton'
import {
  getResponseFilters,
  getGreenfieldTimePeriod,
  getResponseTrendFilters,
} from '../../../service/common/GreenfieldService'
import withRouter from '../../../common/withRouter'
import { useEnv } from '@praxis/component-runtime-env'
import Typography from '@material-ui/core/Typography'
import { useNavigate } from 'react-router-dom'
import { FISCAL_CAL_MONTH } from '../../../globalConstants'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      paddingBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0, 2, 0),
    },
  }),
)

const ResponseCompliance = ({
  year,
  period,
  periodLabel,
  business,
  companyId,
  calendar,
  fiscalPeriodSelection,
  previousTwoPeriodOptions,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const filters = getResponseFilters(
    year,
    period,
    business,
    companyId,
    calendar,
  )
  const trendFilters = getResponseTrendFilters(
    business,
    companyId,
    fiscalPeriodSelection,
    previousTwoPeriodOptions,
    calendar,
  )
  const commonTimePeriod = getGreenfieldTimePeriod()
  const reportsParams = `year=${year}&business=${business}&period=${period}&label=${periodLabel}&calendar=${calendar}`
  const isFiscalCalMonth = calendar === FISCAL_CAL_MONTH
  const env = useEnv()
  const cards = env.greenfield.cards
  const commonGridItemProps = {
    item: true,
    xs: 12,
    className: classes.gridItem,
  }

  return (
    <Paper square className={classes.paper}>
      <Grid container>
        <Grid
          {...commonGridItemProps}
          lg={6}
          container
          justifyContent="flex-start"
        >
          <BigNumberCard
            cardNumber={cards.responseComplianceBigNumber}
            filters={filters}
            timePeriod={commonTimePeriod}
          />
        </Grid>
        <Grid {...commonGridItemProps} lg={6}>
          <TrendCard
            cardNumber={
              isFiscalCalMonth
                ? cards.responseTrendCardMonth
                : cards.responseTrendCardWeek
            }
            cardTitle={'Response Trend'}
            filters={trendFilters}
          />
        </Grid>
        <Grid {...commonGridItemProps}>
          <Typography variant="subtitle2">
            Measures from earliest start date (time sent to vendor for CM/EMs or
            LOS start time for PMs) to time the vendor 1st checks-in on site.
          </Typography>
        </Grid>
        <Grid {...commonGridItemProps}>
          <MetricsTableCard
            cardNumber={cards.responseTotalsCard}
            filters={filters}
            timePeriod={commonTimePeriod}
          />
        </Grid>
        <Grid {...commonGridItemProps}>
          <MetricsTableCard
            cardNumber={cards.responseSLACard}
            filters={filters}
            timePeriod={commonTimePeriod}
          />
        </Grid>
      </Grid>
      <ActionButton
        text={'response fails'}
        variant={'contained'}
        color={'primary'}
        onClick={() => navigate(`/response-fails#?${reportsParams}`)}
      />
    </Paper>
  )
}
const mapStateToProps = (state) => {
  return {
    previousTwoPeriodOptions: state.filtersReducer.previousTwoPeriodOptions,
    fiscalPeriodSelection: state.filtersReducer.fiscalPeriodSelection,
  }
}
export default withRouter(connect(mapStateToProps, null)(ResponseCompliance))
