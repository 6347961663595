import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Filters from '../ScoreCard/Filters/Filters'

const DRAWER_WIDTH = 240
const DRAWER_Y_OFFSET = 128

const styles = (theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    position: 'relative',
    zIndex: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top: DRAWER_Y_OFFSET,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'left',
  },
})

const SideMenu = ({ classes }) => {
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      open
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography>Filter By:</Typography>
      </div>
      <Divider />
      <Filters />
    </Drawer>
  )
}

export default withStyles(styles)(SideMenu)
