import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { connect } from 'react-redux'
import { setBusinessSelection, setBusinessOptions } from './actionCreator'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { fetchData } from '../../../service/common/HttpService'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import {
  LOADING,
  FAILED_TO_LOAD,
  NO_FILTER_OPTIONS,
  FILTER_FETCH_ERROR,
} from '../../../globalConstants'
import { setMessage } from '../../UserFeedback/actionCreator'

const styles = (theme) => ({
  gridChildTitle: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.alternate,
    color: theme.palette.secondary.action,
  },
})

export class BusinessDropdown extends Component {
  constructor() {
    super()
    this.state = {
      displayMessage: LOADING,
    }
  }

  componentDidMount = async () => {
    const { toggleRender, setBusinessOptions, companyId, setMessage } =
      this.props
    let displayMessage = NO_FILTER_OPTIONS
    try {
      toggleRender(true)
      const response = await fetchData(
        `reports/businesses/?vendor=${companyId}`,
      )
      response.query_results.length &&
        setBusinessOptions(
          response.query_results.map((option) => option.pm_business_group),
        )
    } catch (e) {
      displayMessage = FAILED_TO_LOAD
      setMessage(FILTER_FETCH_ERROR)
    } finally {
      toggleRender(false)
      this.setState({ displayMessage: displayMessage })
    }
  }

  handleChange = (event) => {
    const { setBusinessSelection } = this.props
    setBusinessSelection(event.target.value)
  }

  render = () => {
    const { classes, businessOptions, businessSelection } = this.props
    const businessOptionsLength = businessOptions && businessOptions.length
    return (
      <>
        <Grid item className={classes.gridChildTitle} xs={12}>
          <Typography variant={'body2'}>Business</Typography>
        </Grid>
        <Grid item xs={12}>
          {businessOptionsLength ? (
            <FormControl fullWidth>
              <Select
                value={businessOptionsLength ? businessSelection : ''}
                onChange={this.handleChange}
              >
                {businessOptions.map((option, index) => {
                  return (
                    <MenuItem value={option} key={index}>
                      {option}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          ) : (
            <Typography>{this.state.displayMessage}</Typography>
          )}
        </Grid>
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    businessOptions: state.filtersReducer.businessOptions,
    businessSelection: state.filtersReducer.businessSelection,
    companyId: state.authCredentialsReducer.companyId,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setBusinessSelection,
  setBusinessOptions,
  setMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(BusinessDropdown))
