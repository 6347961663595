import { Container } from '@material-ui/core'
import WorkOrderCauses from './WorkOrderCauses'
import WorkOrderRemedies from './WorkOrderRemedies'

export const WorkOrderCauseRemedy = () => {
  return (
    <Container maxWidth="xl" disableGutters>
      <WorkOrderCauses />
      <WorkOrderRemedies />
    </Container>
  )
}

export default WorkOrderCauseRemedy
