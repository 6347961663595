/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import React, { useState, useEffect } from 'react'
import { ListSubheader } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { fetchData } from '../../../../service/common/HttpService'
import { connect } from 'react-redux'
import { setMessage } from '../../../UserFeedback/actionCreator'
import CircularProgress from '@material-ui/core/CircularProgress'
import SavedMaterialCatalogLineItems from './SavedPartsCatalogLineItems'
import IncrementDecrementButton from '../IncrementDecrementButton/IncrementDecrementButton'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { FORMATTER } from '../../../../globalConstants'
import { Stack } from '@mui/material'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    listSubheader: {
      marginTop: theme.spacing(1),
    },
  }),
)

export const MaterialLineItems = ({
  workOrder,
  companyId,
  setMessage,
  preview,
  shouldRender,
  toggleRender,
}) => {
  const classes = useStyles()
  const [materialLineItems, setMaterialLineItems] = useState([])

  const getParts = async () => {
    toggleRender(true)
    setMaterialLineItems([])
    try {
      const response = await fetchData(
        `company/${companyId}/catalog/parts?site_id=TPART&page_number=0&commodity_code=${workOrder.classification_code}&per_page=1000&reported_date=${workOrder.reported_date}`,
      )
      setMaterialLineItems(response.catalog_items || [])
    } catch (error) {
      setMessage('There was an error loading catalog parts.')
    } finally {
      toggleRender(false)
    }
  }

  useEffect(() => {
    workOrder.classification_code && workOrder.reported_date && getParts()
  }, [workOrder.classification_code, workOrder.reported_date])

  const getListSubheader = (title) => {
    return (
      <ListSubheader
        disableSticky
        color="primary"
        className={classes.listSubheader}
      >
        <strong>{title}</strong>
      </ListSubheader>
    )
  }

  const columns = [
    {
      field: 'description',
      headerName: 'Description',
      filter: 'agTextColumnFilter',
      flex: 1,
      floatingFilter: true,
    },
    {
      field: 'supplier_part_id',
      headerName: 'Part ID',
      filter: 'agTextColumnFilter',
      flex: 1,
      floatingFilter: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      filter: 'agNumberColumnFilter',
      flex: 1,
      floatingFilter: true,
      valueFormatter: (params) => {
        return FORMATTER.format(params.value)
      },
    },
    {
      headerName: 'Quantity',
      flex: 1,
      sortable: false,
      cellRenderer: (params) => {
        return <IncrementDecrementButton optionDetails={params.data} />
      },
    },
  ]

  return (
    <Container maxWidth="xl" disableGutters className={classes.outerContainer}>
      {getListSubheader('Catalog Parts Line Items')}
      <SavedMaterialCatalogLineItems />
      {!preview && (
        <>
          {getListSubheader('Catalog Parts')}
          <div
            className="ag-theme-quartz"
            style={{
              height: materialLineItems.length ? '50vh' : 250,
            }}
          >
            {!shouldRender || materialLineItems.length ? (
              <AgGridReact
                rowModelType={'clientSide'}
                rowData={materialLineItems}
                columnDefs={columns}
                rowHeight={50}
              />
            ) : null}
            {shouldRender && !materialLineItems.length && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%' }}
              >
                <CircularProgress />
              </Stack>
            )}
          </div>
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    companyId: state.authCredentialsReducer.companyId,
    preview: state.workOrdersReducer.preview,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialLineItems)
