import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import {
  TableContainer,
  TableRow,
  Container,
  ListSubheader,
  TableCell,
  Select,
  MenuItem,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { FORMATTER, MAX_PRECISION } from '../../../../globalConstants'
import { connect } from 'react-redux'
import { postData, fetchData } from '../../../../service/common/HttpService'
import { setMessage } from '../../../SplashScreen/actionCreator'
import LoadingButton from '../../../LoadingButton/LoadingButton'
import {
  setWorkOrder,
  setNonCatalogLineItemType,
} from '../../WorkOrdersStore/actionCreator'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import { useAnalytics } from '@praxis/component-analytics'
import { Alert } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    listSubheader: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    tableContainer: {
      backgroundColor: theme.palette.secondary.action,
      borderRadius: 4,
    },
  }),
)

const NonCatalogLineItems = ({
  workOrder,
  setMessage,
  setWorkOrder,
  userId,
  setNonCatalogLineItemType,
  itemType,
  lineItemTypeLabor,
  lineItemTypeParts,
  preview,
  toggleRender,
  shouldRender,
  userInfo,
}) => {
  const DEFAULT_UNITS = 0
  const DEFAULT_COST_PER_TYPE = 0
  const DEFAULT_UNIT_TYPE = 'EA'
  const DEFAULT_ITEM_TYPE = 'parts'
  const DEFAULT_DESC = ''
  const classes = useStyles()
  const [unitType, setUnitType] = useState(DEFAULT_UNIT_TYPE)
  const [costPerType, setCostPerType] = useState(DEFAULT_COST_PER_TYPE)
  const [units, setUnits] = useState(DEFAULT_UNITS)
  const [description, setDescription] = useState(DEFAULT_DESC)
  const { trackCustomEvent } = useAnalytics()

  const handleUnitsChange = (value) => {
    const index = value.indexOf('.')
    const newValue =
      index >= 0
        ? value.substr(0, index) + value.substr(index, MAX_PRECISION + 1)
        : value
    setUnits(newValue)
  }

  const addNonCatalogLineItem = async () => {
    const postBody = [
      {
        is_completed_work: true,
        is_proposed_work: false,
        is_non_catalog: true,
        description: description,
        line_item_type: itemType,
        location_id: workOrder.location_id,
        unit_of_measure: unitType,
        completed_quantity: Number(units),
        completed_amount: Number(costPerType),
        supplier_part_id: 'PMWA_Non_Catalog_Item2',
        catalog_name: 'PMWA_Non_Catalog_Item_Creations',
        iso_labor_type: 'PMWA_Non_Catalog_Item',
        request_by: userId,
        iso_labor_type2: '',
      },
    ]

    try {
      const workOrderNumber = workOrder.work_order_number
      toggleRender(true)
      setMessage('Creating your line item...')
      await postData(`work_order/${workOrderNumber}/lineitem`, postBody)
      setMessage('Line item created, updating line item list...')
      // Get updated line items from Maximo pass through endpoint and update workOrder state
      const response = await fetchData(`maximo/work_order/${workOrderNumber}`)
      setWorkOrder({
        ...workOrder,
        work_plan_item: response.work_plan_item,
      })
      trackCustomEvent(
        'Create Non Catalog Line Item',
        workOrderNumber,
        userInfo.firstName + userInfo.lastName,
        userInfo.technicianId,
      )
      setNonCatalogLineItemType(DEFAULT_ITEM_TYPE)
      setDescription(DEFAULT_DESC)
      setUnitType(DEFAULT_UNIT_TYPE)
      setCostPerType(DEFAULT_COST_PER_TYPE)
      setUnits(DEFAULT_UNITS)
      setMessage('Line item list updated successfully.')
    } catch (e) {
      setMessage('Unable to create line item')
    } finally {
      toggleRender(false)
    }
  }

  const menuItems =
    itemType?.toLowerCase() === 'labor' ? lineItemTypeLabor : lineItemTypeParts

  return (
    <>
      {!preview && (
        <Container
          maxWidth="xl"
          disableGutters
          className={classes.outerContainer}
          data-html2canvas-ignore="true"
        >
          <ListSubheader
            disableSticky
            color="primary"
            className={classes.listSubheader}
          >
            <strong>Create Non-Catalog Line Item</strong>
          </ListSubheader>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={'50%'}>Description</TableCell>
                  <TableCell width={'10%'}>Type</TableCell>
                  <TableCell width={'10%'}>Unit Type</TableCell>
                  <TableCell width={'10%'}>Cost Per Units</TableCell>
                  <TableCell width={'10%'}>Units</TableCell>
                  <TableCell width={'10%'}>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      id="description"
                      fullWidth
                      multiline
                      value={description}
                      onChange={(event) => {
                        setDescription(event.target.value)
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      value={itemType}
                      onChange={(event) => {
                        setUnitType(DEFAULT_UNIT_TYPE)
                        setNonCatalogLineItemType(event.target.value)
                      }}
                    >
                      <MenuItem value={'parts'}>Parts</MenuItem>
                      <MenuItem value={'labor'}>Labor</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      value={unitType}
                      onChange={(event) => {
                        setUnitType(event.target.value)
                      }}
                    >
                      {menuItems?.map((menuItem, index) => {
                        return (
                          <MenuItem value={menuItem.value} key={index}>
                            {menuItem.text}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <NumericFormat
                      data-testid="cost-input"
                      customInput={TextField}
                      value={costPerType}
                      onFocus={(event) => {
                        !costPerType && event.target.select()
                      }}
                      onChange={(event) => {
                        setCostPerType(event.target.value)
                      }}
                      decimalScale={2}
                      allowNegative={false}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <NumericFormat
                      data-testid="unit-input"
                      customInput={TextField}
                      value={units}
                      onFocus={(event) => {
                        !units && event.target.select()
                      }}
                      onChange={(event) => {
                        handleUnitsChange(event.target.value)
                      }}
                      decimalScale={2}
                      allowNegative={false}
                    />
                  </TableCell>
                  <TableCell data-testid="total">
                    {isNaN(costPerType) || isNaN(units)
                      ? '$0.00'
                      : FORMATTER.format(costPerType * units)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Alert severity="info">
                      <Typography>
                        All fields required to add a non-catalog item.
                      </Typography>
                    </Alert>
                  </TableCell>
                  <TableCell colSpan={4} align="right">
                    <LoadingButton
                      color={'primary'}
                      variant={'contained'}
                      onClick={addNonCatalogLineItem}
                      text={'Add Non-Catalog Item'}
                      loading={shouldRender}
                      disabled={
                        !description ||
                        !unitType ||
                        !costPerType ||
                        costPerType === '0' ||
                        !units ||
                        units === '0' ||
                        shouldRender ||
                        !description.trim()
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    lineItemTypeLabor: state.workOrdersReducer.lineItemTypeLabor,
    lineItemTypeParts: state.workOrdersReducer.lineItemTypeParts,
    workOrder: state.workOrdersReducer.workOrder,
    userId: state.authCredentialsReducer.auth.session.userInfo.technicianId,
    itemType: state.workOrdersReducer.itemType,
    preview: state.workOrdersReducer.preview,
    shouldRender: state.linearProgressReducer.shouldRender,
    userInfo: state.authCredentialsReducer.auth.session.userInfo,
  }
}

const mapDispatchToProps = {
  setMessage,
  setWorkOrder,
  setNonCatalogLineItemType,
  toggleRender,
}

export default connect(mapStateToProps, mapDispatchToProps)(NonCatalogLineItems)
