/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ToggleButtonGroup } from '@material-ui/lab'
import { ToggleButton } from '@material-ui/lab'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { getURLSearchParams, setURLSearchParams } from '../../../windowManager'
import { Stack } from '@mui/system'
import { GOLDEN_RATIO_AS_PERCENT_REVERSED } from '../../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    toggleButtonGroup: {
      backgroundColor: theme.palette.secondary.action,
      marginTop: theme.spacing(1.5),
      justifyContent: 'flex-end',
    },
    toggleButton: {
      '&&': { color: theme.palette.primary.main },
    },
    disabled: {
      opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
    },
  }),
)
const filterIndexParam = 'wo_filter_index'

const onFilterChange = (event) => {
  const wofilterindex = event.currentTarget.getAttribute('woFilterIndex')
  setURLSearchParams([{ name: filterIndexParam, value: wofilterindex }], true)
}

export const WorkOrderFilterToggleButton = () => {
  const openWorkOrders = 'current'
  const completedWorkOrders = 'complete'
  const lastYearOfWorkOrders = 'all'
  const classes = useStyles()
  const [woFilterIndex, setWoFilterIndex] = useState(openWorkOrders)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const onHashChange = () => {
    const newParams = getURLSearchParams()
    let disabled = false
    const currentFilterIndex = newParams.get(filterIndexParam)
    if (newParams.get('wo_search')) {
      setWoFilterIndex(null)
      disabled = true
    } else {
      !currentFilterIndex
        ? setURLSearchParams(
            [{ name: filterIndexParam, value: openWorkOrders }],
            true,
          )
        : setWoFilterIndex(currentFilterIndex)
    }
    setDisabled(disabled)
  }

  return (
    <Stack spacing={1}>
      <ToggleButtonGroup
        value={woFilterIndex}
        exclusive
        onChange={onFilterChange}
        aria-label="Work Order Status"
        className={`${classes.toggleButtonGroup} ${
          disabled && classes.disabled
        }`}
        color="primary"
      >
        <ToggleButton
          aria-label={openWorkOrders}
          wofilterindex={openWorkOrders}
          value={openWorkOrders}
          className={classes.toggleButton}
          disabled={disabled}
        >
          Open
        </ToggleButton>
        <ToggleButton
          aria-label={completedWorkOrders}
          wofilterindex={completedWorkOrders}
          value={completedWorkOrders}
          className={classes.toggleButton}
          disabled={disabled}
        >
          Completed last 90 days
        </ToggleButton>
        <ToggleButton
          aria-label={lastYearOfWorkOrders}
          wofilterindex={lastYearOfWorkOrders}
          value={lastYearOfWorkOrders}
          className={classes.toggleButton}
          disabled={disabled}
        >
          All last 12 months
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

export default WorkOrderFilterToggleButton
