import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import FiscalCalendar from './FiscalCalendar'
import YearDropdown from './YearDropdown'
import PeriodDropdown from './PeriodDropdown'
import BusinessDropdown from './BusinessDropdown'

const styles = (theme) => ({
  gridParent: {
    padding: theme.spacing(2),
  },
})

const Filters = ({ classes }) => {
  return (
    <Grid container className={classes.gridParent} spacing={2}>
      <FiscalCalendar />
      <Grid item xs={12}>
        <YearDropdown />
      </Grid>
      <Grid item xs={12}>
        <PeriodDropdown />
      </Grid>
      <BusinessDropdown />
    </Grid>
  )
}

export default withStyles(styles)(Filters)
