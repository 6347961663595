import { SET_AUTH_CREDENTIALS, SET_COMPANY } from './actionType'

export function setAuthCredentials(auth) {
  return {
    type: SET_AUTH_CREDENTIALS,
    auth: auth,
  }
}

export function setCompany(company) {
  return {
    type: SET_COMPANY,
    companyId: company.company_id,
    companyName: company.company_name,
  }
}
