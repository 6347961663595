import React, { Suspense } from 'react'
import Typography from '@material-ui/core/Typography'
import 'greenfield-visualizations/greenfieldvisualizations.css'
import { useEnv } from '@praxis/component-runtime-env'
const GreenfieldVisualizations = React.lazy(
  () => import('greenfield-visualizations'),
)

const TrendCard = ({ cardNumber, filters, cardTitle }) => {
  const env = useEnv()
  const envGreenfield = env.greenfield

  const fixedCard = {
    id: cardNumber,
    filters: filters,
  }

  return (
    <>
      <Typography variant="h6">{cardTitle}</Typography>
      <Suspense fallback={null}>
        <GreenfieldVisualizations
          fixedCard={fixedCard}
          apiKey={envGreenfield.apikey}
          environment={envGreenfield.environment}
          cardStyles={{
            height: '40vh',
            border: '1px solid',
          }}
        />
      </Suspense>
    </>
  )
}

export default TrendCard
