import { SET_AUTH_CREDENTIALS, SET_COMPANY } from './actionType'

const initialState = {
  auth: {
    session: {
      userInfo: {},
    },
  },
}

export default function authCredentialsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_CREDENTIALS: {
      const session = action.auth.session
      const userInfo = session.userInfo
      const newState = {
        ...state,
        auth: {
          session: {
            userInfo: {
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              technicianId: userInfo.lanId,
            },
          },
        },
      }
      return newState
    }
    case SET_COMPANY: {
      const newState = {
        ...state,
        companyId: action.companyId,
        companyName: action.companyName,
      }
      return newState
    }
    default:
      return state
  }
}
