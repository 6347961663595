import apiConfig from '../../apiConfig'
import axios from 'axios'

let vendorId = null
// Should only be called by vendorIdMiddleware
export const setVendorId = (id) => {
  vendorId = id
}

export const getBearer = () => {
  let bearerToken = localStorage.getItem('access_token')
  bearerToken = !bearerToken.includes('Bearer ')
    ? `Bearer ${bearerToken}`
    : bearerToken
  return bearerToken
}

const getConfig = async () => {
  const config = await apiConfig()
  const headers = {
    Authorization: getBearer(),
    'Content-Type': 'application/json',
  }
  if (config?.environment === 'dev') {
    headers['masenv'] = 'dev'
  }
  return {
    headers: headers,
    crossDomain: true,
  }
}

const getDeleteConfig = async (body) => {
  const config = await apiConfig()
  const headers = {
    Authorization: getBearer(),
    'Content-Type': 'application/json',
  }
  if (config?.environment === 'dev') {
    headers['masenv'] = 'dev'
  }
  return {
    headers: headers,
    crossDomain: true,
    data: body,
  }
}

const encodeUrlParams = (url) => {
  const splitUrl = url.split('?')
  let newUrl = `${splitUrl[0]}?`
  const entries = new URLSearchParams(splitUrl[1]).entries()
  for (const entry of entries) {
    newUrl += `${entry[0]}=${window.encodeURIComponent(entry[1])}&`
  }
  newUrl += newUrl.match(/work_order\//) && `vendor=${vendorId}&`
  newUrl = newUrl.slice(0, -1)
  return newUrl
}

const configureUrl = async (url) => {
  const config = await apiConfig()
  const apiKey = `${config.auth.apiKey}`
  const baseUrl = `${config.vendorAdmin.host}`
  const encodedUrl = `${baseUrl}${encodeUrlParams(url)}`
  const separator = encodedUrl.indexOf('?') === -1 ? '?' : '&'
  return `${encodedUrl}${separator}key=${apiKey}`
}

// url is the complete url with the all the parameters
export const fetchData = async (url) => {
  const finalUrl = await configureUrl(url)
  const response = await axios.get(finalUrl, await getConfig())
  return response.data
}

export const postData = async (url, body) => {
  const finalUrl = await configureUrl(url)
  const response = await axios.post(finalUrl, body, await getConfig())
  return response.data
}

export const putData = async (url, body) => {
  const finalUrl = await configureUrl(url)
  const response = await axios.put(finalUrl, body, await getConfig())
  return response.data
}

export const deleteData = async (url, body) => {
  const finalUrl = await configureUrl(url)
  const response = await axios.delete(finalUrl, await getDeleteConfig(body))
  return response.data
}

export const postGreenfieldData = async (url, body) => {
  const config = await apiConfig()
  const greenfieldConfig = config.greenfield
  const finalUrl = `${greenfieldConfig.greenfieldURL}${url}&key=${greenfieldConfig.apikey}`
  const response = await axios.post(finalUrl, body, await getConfig())
  return response.data
}
