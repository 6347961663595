/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import React, { useEffect, useState } from 'react'
import Header from '../../Header/Header'
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { fetchData } from '../../../service/common/HttpService'
import { getURLSearchParams } from '../../../windowManager'
import { connect } from 'react-redux'
import { setWorkOrders } from '../WorkOrdersStore/actionCreator'
import { FORMATTER, OPEN_WORK_ORDERS } from '../../../globalConstants'
import AlertGroup from '../common/AlertGroup'
import Clipboard from '../Clipboard/Clipboard'
import WorkOrderLink from '../WorkOrderLink/WorkOrderLink'
import Alert from '@material-ui/lab/Alert'
import { WorkOrderFilterToggleButton } from './WorkOrderFilterToggleButton'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { Stack } from '@mui/system'
import CustomCsvDownloadButton from './CustomCsvDownloadButton'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

const useStyles = makeStyles((theme) =>
  createStyles({
    dataGrid: {
      backgroundColor: theme.palette.secondary.action,
      margin: theme.spacing(1.2),
    },
    alert: {
      margin: theme.spacing(1.2),
    },
    offset: theme.mixins.toolbar,
    filterTabs: {
      padding: theme.spacing(0.5, 1.4),
    },
    exportBtn: {
      padding: theme.spacing(0.9),
      backgroundColor: theme.palette.secondary.action,
    },
  }),
)

export const WorkOrderLists = ({
  setWorkOrders,
  workOrders,
  toggle,
  toggleRender,
}) => {
  const DEFAULT_PAGE_SIZE = 10000
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [filteredWorkOrders, setFilteredWorkorders] = useState([])
  const [gridApi, setGridApi] = useState(null)
  const classes = useStyles()
  const DEFAULT_PAGE = 0
  const DEFAULT_FILTER_BY_INDEX = 'current'
  const FILTER_BY_INDEX = 'wo_filter_index'
  const urlSearchParams = getURLSearchParams()
  const companyId = urlSearchParams.get('company_id')
  const getFilterBy = () =>
    urlSearchParams.get(FILTER_BY_INDEX) || DEFAULT_FILTER_BY_INDEX

  const getWorkOrders = async () => {
    try {
      toggleRender(true)
      const urlSearchParams = getURLSearchParams()
      const toggleParam =
        urlSearchParams.get(FILTER_BY_INDEX) || DEFAULT_FILTER_BY_INDEX
      const companyId = urlSearchParams.get('company_id')
      const url = `company/${companyId}/work_orders/${toggleParam}?page_size=${DEFAULT_PAGE_SIZE}&page_number=${DEFAULT_PAGE}`
      setWorkOrders([])
      setLoading(true)
      setError(false)
      const response = await fetchData(url)
      const workOrders = response.work_orders
      // just like when we first used GraphQL with serviceTech, we will employ
      // ignore next until we understand how to write unit tests against useEffect
      if (workOrders.length) {
        setWorkOrders(workOrders)
        setFilteredWorkorders(workOrders)
        setWorkOrderArray(workOrders)
      }
    } catch {
      setError(true)
    } finally {
      setLoading(false)
      toggleRender(false)
    }
  }
  useEffect(() => {
    getWorkOrders()
  }, [toggle])

  const onGridReady = (params) => {
    setGridApi(params.api)
    const filterModel = decodeURIComponent(localStorage.savedFilters)
    const sortState = JSON.parse(localStorage.getItem('sortState'))
    if (sortState !== 'undefined') {
      setTimeout(() => {
        params.api.applyColumnState({ state: sortState, applyOrder: true })
      }, 50)
    }
    if (filterModel && filterModel !== 'undefined') {
      const parsedFilterModel = JSON.parse(filterModel)
      setTimeout(() => {
        params.api.setFilterModel(parsedFilterModel)
      }, 50)
    }
  }
  const dateSortCompare = (valueA, valueB) => {
    const dateA = new Date(valueA)
    const dateB = new Date(valueB)
    return dateA.getTime() - dateB.getTime()
  }

  const columns = [
    {
      field: 'work_order_number',
      headerName: 'Work Order',
      width: 200,
      cellRenderer: (params) => {
        return (
          <>
            <WorkOrderLink
              wonum={params.value}
              currentWorkOrderPage={DEFAULT_PAGE}
              companyId={companyId}
              totalCount={filteredWorkOrders.length}
            />
            <Clipboard wonum={params.value} />
          </>
        )
      },
    },
    {
      field: 'site',
      headerName: 'Site ID',
      width: 100,
    },
    {
      field: 'work_order_description',
      headerName: 'Description',
      width: 400,
    },
    { field: 'work_type', headerName: 'Work Type', width: 120 },
    { field: 'work_order_status', headerName: 'Status', width: 100 },
    {
      field: 'status_date',
      headerName: 'Status Date',
      width: 210,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'lvm_status',
      headerName: 'LVM Status',
      width: 150,
    },
    {
      field: 'vendor_wo_val',
      headerName: 'Work Validation',
      width: 150,
    },
    {
      field: 'not_to_exceed',
      headerName: 'NTE',
      width: 90,
      cellRenderer: (params) => {
        return FORMATTER.format(params.value)
      },
      comparator: (a, b) => dateSortCompare(a, b),
    },
    { field: 'asset_name', headerName: 'Asset', width: 160 },
    { field: 'area_of_store', headerName: 'Area of Store', width: 140 },
    { field: 'problem', headerName: 'Problem', width: 220 },
    { field: 'region', headerName: 'Region', width: 100 },
    { field: 'group', headerName: 'Group', width: 100 },
    { field: 'district', headerName: 'District', width: 100 },
    { field: 'city', headerName: 'City', width: 140 },
    { field: 'state', headerName: 'State', width: 90 },
    {
      field: 'last_vendor_work_log',
      headerName: 'Last Vendor Work Log',
      width: 210,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'earliest_start',
      headerName: `Earliest Start`,
      width: 210,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'arrive_by',
      headerName: `Arrive By`,
      width: 210,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'first_check_in',
      headerName: `First Check-In`,
      width: 300,
      comparator: (valueA, valueB) => {
        const dateA = new Date(valueA)
        const dateB = new Date(valueB)
        return dateA.getTime() - dateB.getTime()
      },
    },
    {
      field: 'last_check_in',
      headerName: `Last Check-In`,
      width: 310,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'last_check_out',
      headerName: `Last Check-Out`,
      width: 310,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'repair_by',
      headerName: `Repair By`,
      width: 210,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'converted_repair',
      headerName: 'Repair Expectation',
      width: 175,
      comparator: (a, b) => dateSortCompare(a, b),
    },
    {
      field: 'converted_response',
      headerName: 'Response Expectation',
      width: 200,
      comparator: (a, b) => dateSortCompare(a, b),
    },
  ]

  const setWorkOrderArray = (workOrders) => {
    const newWoNumsArray = []
    workOrders.forEach(function (workOrder) {
      newWoNumsArray.push(workOrder.work_order_number)
    })
    window.localStorage.setItem(
      'workOrderArray',
      JSON.stringify(newWoNumsArray),
    )
  }
  const onHashChange = async (event) => {
    const openWasSelected =
      event?.oldURL?.indexOf('open') !== -1 ||
      event?.newURL?.indexOf('open') !== -1

    const oldSearchQuery = new URLSearchParams(event?.oldURL.split('#')[1]).get(
      'wo_filter_index',
    )
    const newSearchQuery = new URLSearchParams(event?.newURL.split('#')[1]).get(
      'wo_filter_index',
    )
    if (oldSearchQuery !== newSearchQuery || !openWasSelected) {
      getWorkOrders()
    }
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [toggle])

  const onSortChanged = () => {
    const sortState = gridApi?.getColumnState().filter((column) => column.sort)
    if (!sortState || Object.keys(sortState).length === 0) {
      window.localStorage.removeItem('sortState')
    } else {
      window.localStorage.setItem('sortState', JSON.stringify(sortState))
    }
    const sortedData = []
    gridApi?.forEachNodeAfterFilterAndSort((node) => {
      sortedData.push(node.data)
    })
    setWorkOrderArray(sortedData)
    setFilteredWorkorders(sortedData)
  }

  const onFilterChanged = () => {
    const filterModel = gridApi?.getFilterModel()
    if (!filterModel || Object.keys(filterModel).length === 0) {
      window.localStorage.removeItem('savedFilters')
    } else {
      window.localStorage.setItem('savedFilters', JSON.stringify(filterModel))
    }
    const filteredWorkorders = []
    gridApi?.forEachNodeAfterFilterAndSort((node) => {
      filteredWorkorders.push(node.data)
    })
    setFilteredWorkorders(filteredWorkorders)
    setWorkOrderArray(filteredWorkorders)
  }
  return (
    <>
      <Header renderTabs />
      <div className={classes.offset} />
      <AlertGroup
        loading={loading}
        error={error}
        numWorkOrders={workOrders.length}
      />
      {
        getFilterBy() === DEFAULT_FILTER_BY_INDEX && (
          <Alert variant="filled" severity="info" className={classes.alert}>
            Work Orders in the {OPEN_WORK_ORDERS} page will remain on the page
            until the work orders are completed.
          </Alert>
        ) //only show this alert when Open work orders is selected
      }

      <Grid container item xs={12} className={classes.filterTabs}>
        <Grid xs={6} item className={classes.exportBtn}>
          <CustomCsvDownloadButton filteredWorkOrders={filteredWorkOrders} />
        </Grid>
        <Grid item xs={6}>
          <WorkOrderFilterToggleButton />
        </Grid>
      </Grid>
      <div
        className={`ag-theme-quartz ${classes.dataGrid}`}
        style={{
          height: workOrders.length ? '70vh' : 250,
        }}
      >
        {!loading || workOrders.length ? (
          <AgGridReact
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            rowData={workOrders}
            columnDefs={columns}
            rowHeight={50}
            defaultColDef={{
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              suppressHeaderMenuButton: true,
            }}
          />
        ) : null}
        {loading && !workOrders.length && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <CircularProgress />
          </Stack>
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    workOrders: state.workOrdersReducer.workOrders || [],
    toggle: state.workOrdersReducer.toggle,
  }
}
const mapDispatchToProps = {
  setWorkOrders,
  toggleRender,
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderLists)
