import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Grid, Typography, ListSubheader, CardHeader } from '@material-ui/core'
import { Alert } from '@mui/material'
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import VisitHistory from './VisitHistory'
import ExpandableDiv from '../../../common/ExpandableDiv'
import { NA } from '../../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.primary.alternate,
      color: theme.palette.secondary.action,
    },
    alert: {
      margin: theme.spacing(1),
    },
    visitSubheader: {
      marginRight: theme.spacing(1),
      fontWeight: 'bold',
      color: theme.palette.secondary.action,
    },
    visitTimeStamp: {
      color: theme.palette.secondary.action,
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    outerContainer: {
      padding: theme.spacing(0, 1),
    },
    listSubheader: {
      margin: theme.spacing(0, 1),
    },
  }),
)

export const VisitDetails = ({ visits = [], shouldRender }) => {
  const classes = useStyles()

  const commonGridItemNameProps = {
    item: true,
    xs: 4,
  }

  const commonGridItemValueProps = {
    item: true,
    xs: 8,
  }

  const getGridChild = (row) => {
    const visitId = row?.visit_id
    const visitStatus = row?.visit_status

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={visitId}
        id={`visit-${visitId}`}
        className={classes.cardContent}
      >
        <Card>
          <CardHeader
            title={row.technician_name}
            className={classes.cardHeader}
            subheader={
              <>
                <Typography component="span" className={classes.visitSubheader}>
                  Create Date:
                </Typography>
                <Typography component="span" className={classes.visitTimeStamp}>
                  {row?.created_timestamp}
                </Typography>
              </>
            }
          />
          <CardContent>
            <Grid container>
              {visitStatus.toLowerCase() === 'follow-up' && (
                <>
                  <Grid {...commonGridItemNameProps}>
                    <strong>Follow-Up Reason:</strong>
                  </Grid>
                  <Grid {...commonGridItemValueProps}>
                    {row?.follow_up_reason}
                  </Grid>
                </>
              )}
              <Grid {...commonGridItemNameProps}>
                <strong>Status:</strong>
              </Grid>
              <Grid {...commonGridItemValueProps}>{visitStatus}</Grid>
              <Grid {...commonGridItemNameProps}>
                <strong>Tech Notes:</strong>
              </Grid>
              <Grid {...commonGridItemValueProps} xs={12}>
                <ExpandableDiv text={row?.visit_comments || NA} />
              </Grid>
              <Grid item xs={12}>
                {row?.visit_history?.length > 0 && <VisitHistory row={row} />}
              </Grid>
              <Grid {...commonGridItemNameProps}>
                <strong>Visit ID:</strong>
              </Grid>
              <Grid {...commonGridItemValueProps}>{visitId}</Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  return (
    <Container disableGutters className={classes.outerContainer} maxWidth="xl">
      <ListSubheader
        disableSticky
        color="primary"
        className={classes.listSubheader}
      >
        Visits
      </ListSubheader>
      {!shouldRender ? (
        !visits?.length ? (
          <Alert
            severity="warning"
            className={classes.alert}
            data-testid="alert"
          >
            No Visits found.
          </Alert>
        ) : (
          <Grid container>{visits.map((row) => getGridChild(row))}</Grid>
        )
      ) : (
        <Alert severity="info" className={classes.alert}>
          Loading Visits...
        </Alert>
      )}
    </Container>
  )
}
const mapStateToProps = (state) => {
  return {
    visits: state.workOrdersReducer.visits,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

export default connect(mapStateToProps, null)(VisitDetails)
