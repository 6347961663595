import { SET_MESSAGE } from './actionType'

export const initialState = {
  message: null,
  open: false,
}

export default function userFeedbackReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MESSAGE: {
      const actionMessage = action.message
      let open = false
      if (actionMessage) {
        open = true
      }
      const newState = {
        message: action.message,
        severity: action.severity,
        open: open,
      }
      return newState
    }
    default:
      return state
  }
}
