import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { getURLSearchParams } from '../../windowManager'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    tab: {
      padding: theme.spacing(2.5),
      color: theme.palette.secondary.action,
    },
    indicator: {
      backgroundColor: theme.palette.primary.action,
      // needed to make the indicator a little taller for visibility.
      // golden ratio expressed as percentage
      height: theme.spacing(0.62),
    },
  }),
)

export const PrimaryNavTabs = ({ companyId }) => {
  const classes = useStyles()
  const urlSearchParams = getURLSearchParams()
  // self-inform the component of the current tab index via url params
  const tabIndex = parseInt(urlSearchParams.get('primary_tab_index')) || 0
  const tabClass = classes.tab

  return (
    <Tabs
      value={tabIndex}
      classes={{ indicator: classes.indicator }}
      variant="fullWidth"
    >
      <Tab
        label={
          <Typography>
            <strong>Scorecard</strong>
          </Typography>
        }
        className={tabClass}
        // instead of wrapping the tab in a link, let's be clever and turn the tab INTO a link
        component={Link}
        to={'/home'}
        replace
      />
      <Tab
        label={
          <Typography>
            <strong>Dashboard</strong>
          </Typography>
        }
        className={tabClass}
        component={Link}
        to={'/dashboard#?primary_tab_index=1'}
        replace
      />
      <Tab
        label={
          <Typography>
            <strong>Work Orders</strong>
          </Typography>
        }
        className={tabClass}
        component={Link}
        to={`/work-orders#?company_id=${companyId}&primary_tab_index=2`}
        replace
      />
    </Tabs>
  )
}

const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default connect(mapStateToProps, null)(PrimaryNavTabs)
