import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import VisitCompliance from './VisitCompliance/VisitCompliance'
import ResponseCompliance from './ResponseCompliance/ResponseCompliance'
import RepairCompliance from './RepairCompliance/RepairCompliance'
import SameDayFix from './SameDayFix'
import { FISCAL_CAL_MONTH } from '../../globalConstants'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  workOrderButton: {
    marginLeft: theme.spacing(2),
  },
  subheaderContainer: {
    position: 'relative',
  },
  offset: theme.mixins.toolbar,
  workOrderLink: {
    textDecoration: 'none',
  },
}))

const ScoreCard = ({
  fiscalCalendarSelection,
  fiscalYearSelection,
  fiscalPeriodSelection,
  previousTwoPeriodOptions,
  businessSelection,
  periodLabel,
  companyId,
  companyName,
}) => {
  const month = fiscalCalendarSelection === FISCAL_CAL_MONTH
  const selectedPeriod = month
    ? fiscalPeriodSelection?.acct_mo_i?.toString()
    : fiscalPeriodSelection?.acct_wk_i?.toString()
  const canRender =
    fiscalYearSelection &&
    selectedPeriod &&
    businessSelection &&
    previousTwoPeriodOptions?.length
  const classes = useStyles()

  return canRender ? (
    <>
      <div className={classes.offset} />
      <div className={classes.subheaderContainer}>
        <Typography
          variant="h5"
          component="span"
        >{`${companyName} Scorecard`}</Typography>
      </div>
      <VisitCompliance
        year={fiscalYearSelection}
        period={selectedPeriod}
        periodLabel={periodLabel}
        business={businessSelection}
        companyId={companyId}
        calendar={fiscalCalendarSelection}
      />
      <ResponseCompliance
        year={fiscalYearSelection}
        period={selectedPeriod}
        periodLabel={periodLabel}
        business={businessSelection}
        companyId={companyId}
        calendar={fiscalCalendarSelection}
      />
      <RepairCompliance
        year={fiscalYearSelection}
        period={selectedPeriod}
        periodLabel={periodLabel}
        business={businessSelection}
        companyId={companyId}
        calendar={fiscalCalendarSelection}
      />
      <SameDayFix
        year={fiscalYearSelection}
        period={selectedPeriod}
        periodLabel={periodLabel}
        business={businessSelection}
        companyId={companyId}
        calendar={fiscalCalendarSelection}
      />
    </>
  ) : null
}

export const mapStateToProps = (state) => {
  return {
    fiscalCalendarSelection: state.filtersReducer.fiscalCalendarSelection,
    fiscalYearSelection: state.filtersReducer.fiscalYearSelection,
    fiscalPeriodSelection: state.filtersReducer.fiscalPeriodSelection,
    previousTwoPeriodOptions: state.filtersReducer.previousTwoPeriodOptions,
    businessSelection: state.filtersReducer.businessSelection,
    periodLabel: state.filtersReducer.fiscalPeriodSelectionLabel,
    companyId: state.authCredentialsReducer.companyId,
    companyName: state.authCredentialsReducer.companyName,
  }
}

export default connect(mapStateToProps, null)(ScoreCard)
