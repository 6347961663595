import { setURLSearchParams } from '../../windowManager'
import AttachmentUpload from './AttachmentUpload'
import LoadingButton from '../LoadingButton/LoadingButton'
import { connect } from 'react-redux'
import AttachFileIcon from '@mui/icons-material/AttachFile'

export const AttachmentUploadButton = ({ shouldRender }) => {
  const onClick = () => {
    setURLSearchParams([{ name: 'upload', value: true }])
  }

  const buttonText = 'Upload Attachments'

  return (
    <>
      <LoadingButton
        text={buttonText}
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={shouldRender}
        icon={<AttachFileIcon />}
      />
      <AttachmentUpload />
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}
export default connect(mapStateToProps, null)(AttachmentUploadButton)
