import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = (theme) => ({
  progress: {
    color: theme.palette.secondary.action,
  },
})

const LoadingButton = ({
  text,
  onClick,
  disabled = false,
  variant = 'text',
  classes,
  color = 'default',
  className,
  loading = false,
  icon,
}) => {
  const CIRCULAR_PROGRESS_SIZE = 24

  return (
    <Button
      data-html2canvas-ignore="true"
      aria-label={text}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      color={color}
      className={className}
      startIcon={icon}
      endIcon={
        loading && (
          <CircularProgress
            className={classes.progress}
            size={CIRCULAR_PROGRESS_SIZE}
          />
        )
      }
    >
      {text}
    </Button>
  )
}

export default withStyles(styles)(LoadingButton)
