/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import React, { useRef } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import CsvDownloadButton from 'react-json-to-csv'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { setMessage, setError } from '../../SplashScreen/actionCreator'
import { connect } from 'react-redux'
import { toggleRender } from '../../MatLinearProgress/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    exportButton: {
      color: theme.palette.primary.action,
      fontSize: 13,
      '&:hover': {
        backgroundColor: '#f7fafd',
      },
    },
    hiddenDownload: {
      display: 'none',
    },
  }),
)

export const CustomCsvDownloadButton = ({
  filteredWorkOrders,
  setMessage,
  toggleRender,
  shouldRender,
}) => {
  const classes = useStyles()

  const callExportedWorkOrders = async () => {
    try {
      toggleRender(true)
      setTimeout(() => {
        setMessage('Export in progress...')
      }, 10)
      setError(false)
      csvButtonRef.current.children[0].click()
    } catch (error) {
      setError(true)
      setMessage('Unexpected error occured')
    } finally {
      setMessage('Export completed')
      toggleRender(false)
    }
  }

  const csvButtonRef = useRef(null)

  return (
    <>
      <div ref={csvButtonRef}>
        <CsvDownloadButton
          className={classes.hiddenDownload}
          data={filteredWorkOrders}
          delimiter=","
        />
      </div>
      <Button
        startIcon={<SaveAltIcon />}
        disabled={shouldRender || !filteredWorkOrders.length}
        onClick={callExportedWorkOrders}
        className={classes.exportButton}
      >
        export
      </Button>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    shouldRender: state.linearProgressReducer.shouldRender,
    workOrders: state.workOrdersReducer.workOrders || [],
  }
}

const mapDispatchToProps = {
  setMessage,
  toggleRender,
  setError,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomCsvDownloadButton)
