import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { VENDOR_ADMIN } from '../../globalConstants'
import MatLinearProgress from '../MatLinearProgress/MatLinearProgress'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { connect } from 'react-redux'
import ActionButton from '../ActionButton/ActionButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import ExitButton from '../Header/ExitButton'

const styles = (theme) => ({
  gridParent: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    color: theme.palette.secondary.action,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  logo: {
    height: 48,
    margin: theme.spacing(2),
  },
  gridItem: {
    marginTop: theme.spacing(3),
  },
  gridCentered: {
    textAlign: 'center',
    width: '100vw',
  },
  actionButton: {
    color: theme.palette.primary.action,
  },
})

class SplashScreen extends Component {
  componentDidMount = () => {
    this.props.toggleRender(true)
  }
  componentWillUnmount = () => {
    this.props.toggleRender(false)
  }
  render = () => {
    const { classes, message, error } = this.props
    return (
      <>
        <MatLinearProgress />
        <Grid container className={classes.gridParent} alignItems={'center'}>
          <Grid item className={classes.gridCentered}>
            <Grid item>
              <Typography variant={'h6'}>{VENDOR_ADMIN}</Typography>
            </Grid>
            <Grid item>
              <img
                src={require('../../images/targetlogo.png')}
                className={classes.logo}
                alt={'Target-Logo'}
              />
            </Grid>
            <Grid item>
              <Typography>{message}</Typography>
            </Grid>
            {error && (
              <>
                <Grid item className={classes.gridItem}>
                  <ActionButton
                    text={'try again'}
                    icon={<RefreshIcon />}
                    variant={'contained'}
                    onClick={() => window.location.reload()}
                    className={classes.actionButton}
                  />
                </Grid>
                <Grid item className={classes.gridItem}>
                  <ExitButton fullButton />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.splashScreenReducer.message,
    error: state.splashScreenReducer.error,
  }
}

const mapDispatchToProps = {
  toggleRender,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(SplashScreen))
