import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import { connect } from 'react-redux'
import { setFiscalCalendarSelection } from './actionCreator'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { FISCAL_CAL_MONTH, FISCAL_CAL_WEEK } from '../../../globalConstants'

const styles = (theme) => ({
  gridChildTitle: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.alternate,
    color: theme.palette.secondary.action,
  },
})

const FiscalCalendar = ({
  fiscalCalendarSelection,
  setFiscalCalendarSelection,
  classes,
}) => {
  const handleChange = (event) => {
    setFiscalCalendarSelection(event.target.value)
  }

  const fiscalCalendarOptions = [
    {
      label: 'Fiscal Year & Month',
      value: FISCAL_CAL_MONTH,
    },
    {
      label: 'Fiscal Year & Week',
      value: FISCAL_CAL_WEEK,
    },
  ]

  return (
    <>
      <Grid item className={classes.gridChildTitle} xs={12}>
        <Typography variant={'body2'}>Date Range</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <RadioGroup
            aria-label="fiscal calendar selection"
            value={fiscalCalendarSelection}
            onChange={handleChange}
          >
            {fiscalCalendarOptions.map((option, index) => {
              return (
                <FormControlLabel
                  value={option.value}
                  key={index}
                  control={<Radio color={'default'} />}
                  label={option.label}
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    fiscalCalendarSelection: state.filtersReducer.fiscalCalendarSelection,
  }
}

const mapDispatchToProps = {
  setFiscalCalendarSelection,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FiscalCalendar))
