import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { connect } from 'react-redux'
import {
  setFiscalYearSelection,
  setFiscalPeriodSelection,
  setFiscalPeriodOptions,
} from './actionCreator'
import { fetchData } from '../../../service/common/HttpService'
import moment from 'moment'
import {
  FISCAL_CAL_MONTH,
  FISCAL_CAL_WEEK,
  FIRST_YEAR_OF_INFORMATION,
} from '../../../globalConstants'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import Typography from '@material-ui/core/Typography'
import {
  LOADING,
  FAILED_TO_LOAD,
  NO_FILTER_OPTIONS,
  FILTER_FETCH_ERROR,
} from '../../../globalConstants'
import { setMessage } from '../../UserFeedback/actionCreator'

export class PeriodDropdown extends Component {
  constructor() {
    super()
    this.state = {
      displayMessage: LOADING,
      loading: true,
    }
  }

  componentDidMount = () => {
    this.getOptions()
  }

  componentDidUpdate = (prevProps) => {
    const { fiscalCalendarSelection, fiscalYearSelection } = this.props
    if (
      prevProps.fiscalCalendarSelection !== fiscalCalendarSelection ||
      prevProps.fiscalYearSelection !== fiscalYearSelection
    ) {
      this.setState({
        displayMessage: LOADING,
        loading: true,
      })
      this.getOptions()
    }
  }

  fetchOptionsFromAPI = async (year) => {
    const { toggleRender, fiscalCalendarSelection, setMessage } = this.props
    let response = []
    try {
      toggleRender(true)
      response = await fetchData(
        `reports/fiscal_calendars/?fiscal_calendar_option=${fiscalCalendarSelection}&year=${year}`,
      )
    } catch (e) {
      this.setState({
        displayMessage: FAILED_TO_LOAD,
      })
      setMessage(FILTER_FETCH_ERROR)
    } finally {
      this.setState({
        loading: false,
      })
      toggleRender(false)
    }
    return response
  }

  getOptions = async () => {
    const {
      fiscalYearSelection,
      setFiscalYearSelection,
      setFiscalPeriodOptions,
    } = this.props
    const response = await this.fetchOptionsFromAPI(fiscalYearSelection)
    const fiscalOptions = response.selected_year
    // if no fiscal periods for current year gets options from previous year
    if (!fiscalOptions.length) {
      if (fiscalYearSelection - 1 >= FIRST_YEAR_OF_INFORMATION) {
        setFiscalYearSelection(fiscalYearSelection - 1)
      } else {
        this.setState({
          displayMessage: NO_FILTER_OPTIONS,
        })
      }
    }
    setFiscalPeriodOptions(response)
  }

  handleChange = (event) => {
    const { setFiscalPeriodSelection } = this.props
    setFiscalPeriodSelection(event.target.value)
  }

  render = () => {
    const {
      fiscalCalendarSelection,
      fiscalPeriodOptions,
      fiscalPeriodSelection,
    } = this.props

    const fiscalOptionsLength =
      fiscalPeriodOptions && fiscalPeriodOptions.length
    const displayWeek =
      fiscalCalendarSelection === FISCAL_CAL_WEEK &&
      Array.isArray(fiscalPeriodOptions) &&
      fiscalOptionsLength
    const displayMonth =
      fiscalCalendarSelection === FISCAL_CAL_MONTH &&
      Array.isArray(fiscalPeriodOptions) &&
      fiscalOptionsLength

    const displayMessage = this.state.displayMessage
    const dateFormat = 'M/DD'
    const resultsReady =
      fiscalOptionsLength &&
      !this.state.loading &&
      displayMessage !== FAILED_TO_LOAD &&
      displayMessage !== NO_FILTER_OPTIONS

    return resultsReady ? (
      <FormControl fullWidth>
        <Select
          value={fiscalPeriodSelection || ''}
          onChange={this.handleChange}
        >
          {displayMonth &&
            fiscalPeriodOptions.map((option, index) => {
              return (
                <MenuItem value={option} key={index}>
                  {option.acct_mo_label}
                </MenuItem>
              )
            })}
          {displayWeek &&
            fiscalPeriodOptions.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>
                  {`Week ${option.acct_wk_i}: ${moment(
                    option.st_wk_chosen,
                  ).format(dateFormat)} -
                  ${moment(option.end_wk_chosen).format(dateFormat)}`}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
    ) : (
      <Typography>{this.state.displayMessage}</Typography>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    fiscalCalendarSelection: state.filtersReducer.fiscalCalendarSelection,
    fiscalYearSelection: state.filtersReducer.fiscalYearSelection,
    fiscalPeriodSelection: state.filtersReducer.fiscalPeriodSelection,
    fiscalPeriodOptions: state.filtersReducer.fiscalPeriodOptions,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setFiscalYearSelection,
  setFiscalPeriodSelection,
  setFiscalPeriodOptions,
  setMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodDropdown)
