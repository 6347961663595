// These dependencies are bundled within praxis-scripts
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from '@praxis/component-auth'
import Layout from './Layout/Layout'
import { EnvProvider } from '@praxis/component-runtime-env'
import authUtilFunctions from './AuthUtil'
import { LOGIN_COOKIE } from '../globalConstants'

const App = () => {
  return (
    <EnvProvider configPath={'/config.json'}>
      {({ env }) => {
        return (
          <AuthProvider
            authorizationUrl={env.auth.authorizationUrl}
            clientId={env.auth.clientId}
            logoutUrl={env.auth.logoutUrl}
            loginRedirect={`${window.location.origin}/home`}
            SSOCookieName={LOGIN_COOKIE}
            doesSSOCookieMatch={authUtilFunctions.authUtil}
          >
            <Router>
              <Layout />
            </Router>
          </AuthProvider>
        )
      }}
    </EnvProvider>
  )
}

export default App
