import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Stack } from '@mui/material'
import { Beenhere, DepartureBoard, PersonPinCircle } from '@material-ui/icons'
import { getURLSearchParams } from '../../../windowManager'
import { Link } from 'react-router-dom'
import MapIcon from '@material-ui/icons/Map'
import { NA } from '../../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    tableHeader: {
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      verticalAlign: 'top',
    },
    tableContainer: {
      margin: theme.spacing(1, 0, 3, 0),
    },
    mapIcon: {
      color: theme.palette.primary.action,
    },
    tableCell: {
      borderBottom: 'none',
    },
    divider: {
      marginLeft: theme.spacing(1.4),
      backgroundColor: theme.palette.primary.alternate,
    },
  }),
)

export const VisitHistory = ({ row }) => {
  const visitHistory = row?.visit_history

  const getMapLink = () => {
    const urlSearchParams = getURLSearchParams()
    const workOrderNumber = urlSearchParams.get('work_order_number')

    const checkInLatitude = row.check_in_latitude
    const checkInLongitude = row.check_in_longitude

    const checkOutLatitude = row.check_out_latitude
    const checkOutLongitude = row.check_out_longitude

    const storeLatitude = row.latitude
    const storeLongitude = row.longitude

    const checkInCoordinates = checkInLatitude && checkInLongitude
    const checkOutCoordinates = checkOutLatitude && checkOutLongitude
    const storeCoordinates = storeLatitude && storeLongitude

    //includes T because visit core returns store id without a T
    const storeLocationId = `T${row.location_id}`

    // we dont render the map link for visits unless there is a checkin (latitude & longitude) or a check out
    //  (latitude & longitude), or if the store has latitude and lognitude
    // these are needed to render the map correctly to show distance from the store

    if (checkInCoordinates || checkOutCoordinates || storeCoordinates) {
      // lets build url params without manual concatenation, but searchParams API instead
      const urlSearchParams = new URL(window.location).searchParams

      const params = {
        work_order_number: workOrderNumber,
        visit_id: row.visit_id,
        check_in_latitude: checkInLatitude,
        check_in_longitude: checkInLongitude,
        check_out_latitude: checkOutLatitude,
        check_out_longitude: checkOutLongitude,
        latitude: storeLatitude,
        longitude: storeLongitude,
        check_in_geo_distance: row.check_in_geo_distance,
        check_out_geo_distance: row.check_out_geo_distance,
        location_name: row.location_name,
        wo_location_id: storeLocationId,
      }

      // loop through params props, add name-value pairs to search params to build link
      for (const [key, value] of Object.entries(params)) {
        urlSearchParams.append(key, value)
      }

      return (
        <Link
          target="_blank"
          to={`/work-order-details/map-view#?${urlSearchParams}`}
        >
          <MapIcon className={classes.mapIcon} />
        </Link>
      )
    }
  }

  const classes = useStyles()
  const getWorkFlowDetails = (item, currentFlow) => {
    const currentFlowEvent = currentFlow?.event
    switch (currentFlowEvent) {
      case 'Check-out':
        return {
          eventType: 'Check Out',
          icon: <Beenhere />,
          dateTime: item?.check_out_time,
          geoFence: item?.check_out_geofence || NA,
        }
      case 'Check-in':
        return {
          eventType: 'Check In',
          icon: <PersonPinCircle />,
          dateTime: item?.check_in_time,
          geoFence: item?.check_in_geofence || NA,
        }
      case 'En route':
        return {
          eventType: 'ETA',
          icon: <DepartureBoard />,
          dateTime: item?.estimated_time_arrival,
          geoFence: NA,
        }
      default:
        return null
    }
  }
  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Event Type</TableCell>
            <TableCell className={classes.tableHeader}>Date / Time</TableCell>
            <TableCell className={classes.tableHeader}>
              <Stack direction="row" spacing={1}>
                <span>Geo</span>
                <span>{getMapLink(row)}</span>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitHistory.map(
            (history, index) =>
              getWorkFlowDetails(row, history) && (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    <Stack direction="row" spacing={1}>
                      <span>
                        {getWorkFlowDetails(row, history).icon}
                        {index < visitHistory.length - 2 && (
                          <Divider
                            orientation="vertical"
                            className={classes.divider}
                          />
                        )}
                      </span>
                      <span>{getWorkFlowDetails(row, history).eventType}</span>
                    </Stack>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {getWorkFlowDetails(row, history).dateTime}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {getWorkFlowDetails(row, history).geoFence}
                  </TableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default VisitHistory
