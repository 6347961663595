import React from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      padding: theme.spacing(0.5, 1.5),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    paper: {
      margin: theme.spacing(0, 2, 0, 4),
      flexGrow: 0.5,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
)

const UserInfo = ({ companyName, firstName, lastName }) => {
  const classes = useStyles()
  const userNameAndCompanyName = `${firstName} ${lastName}, ${companyName}`
  return (
    <Paper className={classes.paper}>
      <Typography variant="body2" className={classes.text} align="center">
        {userNameAndCompanyName}
      </Typography>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    companyName: state.authCredentialsReducer.companyName,
    firstName: state.authCredentialsReducer.auth.session.userInfo.firstName,
    lastName: state.authCredentialsReducer.auth.session.userInfo.lastName,
  }
}

export default connect(mapStateToProps, null)(UserInfo)
