import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { Stack } from '@mui/material'
import moment from 'moment'
import { connect } from 'react-redux'
import Assignment from '@material-ui/icons/Assignment'
import FlightLand from '@material-ui/icons/FlightLand'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import AttachMoney from '@material-ui/icons/AttachMoney'

const MAX_WORK_ORDER_HISTORY = 4

const useStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      height: '100%',
      display: 'flex',
    },
    table: {
      backgroundColor: theme.palette.secondary.action,
      borderBottom: `1px solid ${theme.palette.background.default}`,
      marginLeft: theme.spacing(2),
    },
    tableCell: {
      borderBottom: 'none',
      padding: theme.spacing(1.7, 2),
    },
    divider: {
      backgroundColor: theme.palette.primary.alternate,
      position: 'absolute',
      top: theme.spacing(4),
      height: theme.spacing(6),
      left: '50%',
    },
    dividerAlt: {
      height: theme.spacing(3.5),
    },
  }),
)

const WorkOrderHistory = ({ workOrder }) => {
  const classes = useStyles()

  const woHistory = [
    {
      eventType: 'Earliest start',
      dateTime: workOrder?.earliest_start,
      icon: <Assignment />,
    },
    {
      eventType: 'Arrive by',
      dateTime: workOrder?.arrive_by,
      icon: <FlightLand />,
    },
    {
      eventType: 'Repair by',
      dateTime: workOrder?.repair_by,
      icon: <AssignmentTurnedIn />,
    },
  ]
  if (workOrder?.invoice_by) {
    woHistory.push({
      eventType: 'Invoice by',
      dateTime: workOrder?.invoice_by,
      icon: <AttachMoney />,
    })
  }

  const woHistoryLength = woHistory.length

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          {woHistory.map((history, index) => {
            const eventType = history.eventType
            const dateTime = history.dateTime
            return (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  <Stack direction="row" spacing={1.5}>
                    <span style={{ position: 'relative' }}>
                      {history.icon}
                      {index < woHistoryLength - 1 && (
                        <Divider
                          orientation="vertical"
                          className={`${classes.divider} ${
                            woHistoryLength === MAX_WORK_ORDER_HISTORY &&
                            classes.dividerAlt
                          }`}
                        />
                      )}
                    </span>
                    <span>{eventType}</span>
                  </Stack>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  data-test={eventType}
                  align="right"
                >
                  {dateTime ? moment(dateTime).format('MM/DD/YY h:mma') : 'N/A'}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    toggle: state.workOrdersReducer.toggle,
    workOrder: state.workOrdersReducer.workOrder,
  }
}

export default connect(mapStateToProps, null)(WorkOrderHistory)
