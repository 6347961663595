import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  workOrderLink: {
    color: theme.palette.primary.action,
  },
}))

const WorkOrderLink = ({ wonum, companyId }) => {
  const classes = useStyles()

  return (
    <Link
      to={`/work-order-details#?work_order_number=${wonum}&company_id=${companyId}`}
      className={classes.workOrderLink}
    >
      {wonum}
    </Link>
  )
}

export default WorkOrderLink
