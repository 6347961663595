import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { HELP } from '../../globalConstants'
import ScoreCardHelp from '../ScoreCard/ScoreCardHelp'
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'

const styles = (theme) => ({
  helpButton: {
    color: theme.palette.secondary.action,
  },
  drawerPaper: {
    width: '40%',
    top: theme.spacing(8),
    padding: theme.spacing(2.5),
  },
})
class Help extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
    }
  }

  onHashChange = () => {
    const searchParams = getURLSearchParams()
    const open = Boolean(searchParams.get('help'))
    this.setState({ open: open })
  }

  onClose = () => {
    window.history.back()
  }

  componentDidMount = () => {
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  onClick = () => {
    setURLSearchParams([{ name: 'help', value: true }])
  }

  render = () => {
    const { open } = this.state
    const { classes } = this.props
    return (
      <>
        <IconButton
          className={classes.helpButton}
          onClick={this.onClick}
          aria-label={HELP}
        >
          <HelpOutlineIcon />
        </IconButton>
        <Drawer
          anchor={'right'}
          open={open}
          onClose={this.onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <ScoreCardHelp />
        </Drawer>
      </>
    )
  }
}

export default withStyles(styles)(Help)
