import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getURLSearchParams } from '../../../windowManager'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    tabs: {
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(0, 2),
    },
    tab: {
      padding: theme.spacing(2.5),
      color: theme.palette.secondary.action,
    },
    indicator: {
      backgroundColor: theme.palette.primary.action,
      height: theme.spacing(0.62),
    },
  }),
)

export const SubNavTabs = ({ currentCount, totalCount, companyId }) => {
  const classes = useStyles()
  const urlSearchParams = getURLSearchParams()

  // get tabindex from url on load or reloading of page
  const subTabIndex = parseInt(urlSearchParams.get('sub_tab_index')) || 0
  const workOrderNumber = urlSearchParams.get('work_order_number')
  const tabClass = classes.tab
  const [selectedTab, setSelectedTab] = useState(subTabIndex)

  const getLabel = (text) => {
    return (
      <Typography>
        <strong>{text}</strong>
      </Typography>
    )
  }

  const commonProps = {
    className: tabClass,
    component: Link,
    replace: true,
  }

  return (
    <Tabs
      value={selectedTab}
      classes={{ indicator: classes.indicator }}
      variant="fullWidth"
      className={classes.tabs}
      onChange={(event, value) => setSelectedTab(value)}
    >
      <Tab
        label={getLabel('Worklog')}
        to={`/work-order-details#?work_order_number=${workOrderNumber}&company_id=${companyId}&total_count=${totalCount}&current_count=${currentCount}`}
        {...commonProps}
      />
      <Tab
        label={getLabel('Billing')}
        data-cy="billingSubNavTab"
        to={`/work-order-details/lineitems#?work_order_number=${workOrderNumber}&company_id=${companyId}&total_count=${totalCount}&current_count=${currentCount}`}
        {...commonProps}
      />
      <Tab
        label={getLabel('Cause / Remedy')}
        to={`/work-order-details/cause-remedy#?work_order_number=${workOrderNumber}&sub_tab_index=2&company_id=${companyId}&total_count=${totalCount}&current_count=${currentCount}`}
        {...commonProps}
      />
      <Tab
        label={getLabel('Asset Details')}
        to={`/work-order-details/asset-details#?work_order_number=${workOrderNumber}&sub_tab_index=3&company_id=${companyId}&total_count=${totalCount}&current_count=${currentCount}`}
        {...commonProps}
      />
    </Tabs>
  )
}

const mapStateToProps = (state) => {
  return {
    searchState: state.materialLineItemsReducer.searchState,
    currentPage: state.materialLineItemsReducer.currentPage,
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default connect(mapStateToProps, null)(SubNavTabs)
