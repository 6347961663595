import React from 'react'
import Header from '../../Header/Header'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { MapContainer, TileLayer, Marker, Tooltip, Circle } from 'react-leaflet'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import { getURLSearchParams } from '../../../windowManager'
import L from 'leaflet'
import markerIconGreen from './icons/marker-icon-2x-green.png'
import markerIconRed from './icons/marker-icon-2x-red.png'
import targetLogo from './icons/target-logo.jpg'
import {
  CHECK_IN,
  CHECK_OUT,
  VISIT_ID,
  WORK_ORDER_NUMBER,
} from '../../../globalConstants'

const APP_BAR_HEIGHT = 64
const BOUNDS_PADDING = APP_BAR_HEIGHT * 2
const CHECK_IN_RADIUS_IN_MILES = 0.2
const CHECK_IN_RADIUS_IN_METERS = 322
const MILES_SHORTHAND = 'mi'
const RECORDED = 'recorded'
const NO = 'no'

const useStyles = makeStyles((theme) =>
  createStyles({
    mapContainer: {
      position: 'absolute',
      top: APP_BAR_HEIGHT,
      left: 0,
      right: 0,
      bottom: 0,
    },
    legend: {
      position: 'absolute',
      zIndex: 1000,
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.background.default,
    },
  }),
)

const MapView = () => {
  const classes = useStyles()

  const searchParams = getURLSearchParams()
  const workOrderNumber = searchParams.get('work_order_number')
  const visitId = searchParams.get('visit_id')
  const locationName = searchParams.get('location_name')
  const woLocationId = searchParams.get('wo_location_id')
  const checkInLatitude = Number.parseFloat(
    searchParams.get('check_in_latitude'),
  )
  const checkOutLatitude = Number.parseFloat(
    searchParams.get('check_out_latitude'),
  )
  const checkInLongitude = Number.parseFloat(
    searchParams.get('check_in_longitude'),
  )
  const checkOutLongitude = Number.parseFloat(
    searchParams.get('check_out_longitude'),
  )
  const storeLatitude = Number.parseFloat(searchParams.get('latitude'))
  const storeLongitude = Number.parseFloat(searchParams.get('longitude'))
  const checkInGeoDistance = Number.parseFloat(
    searchParams.get('check_in_geo_distance'),
  ).toFixed(2)
  const checkOutGeoDistance = Number.parseFloat(
    searchParams.get('check_out_geo_distance'),
  ).toFixed(2)

  const checkIn = [checkInLatitude, checkInLongitude]
  const checkOut = [checkOutLatitude, checkOutLongitude]
  const storeLocation = [storeLatitude, storeLongitude]

  const getLatLngBounds = [checkIn, checkOut, storeLocation]

  // generate a bounds array which the map requires to set a correct viewport
  const bounds = []
  for (var i = 0; i < getLatLngBounds.length; i++) {
    const getLatLngBound = getLatLngBounds[i]
    if (getLatLngBound[0] !== 0 || getLatLngBound[1] !== 0) {
      bounds.push(getLatLngBound)
    }
  }

  const targetIcon = L.icon({
    iconUrl: targetLogo,
    iconSize: 25,
  })
  const commonIconParams = {
    iconSize: [25, 41],
  }
  const greenIcon = new L.Icon({
    iconUrl: markerIconGreen,
    ...commonIconParams,
  })
  const redIcon = new L.Icon({
    iconUrl: markerIconRed,
    ...commonIconParams,
  })

  const getIcon = (distance) => {
    return distance <= CHECK_IN_RADIUS_IN_MILES ? greenIcon : redIcon
  }

  const typographyVariant = 'body1'
  const NOT_A_NUMBER = 'NaN'

  return (
    <>
      <Header />
      <MapContainer
        className={classes.mapContainer}
        bounds={bounds}
        boundsOptions={{
          padding: [BOUNDS_PADDING, BOUNDS_PADDING],
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Circle
          center={storeLocation}
          fillColor={'blue'}
          radius={CHECK_IN_RADIUS_IN_METERS}
        />
        <Marker position={storeLocation} icon={targetIcon}>
          <Tooltip permanent direction={'top'}>
            {locationName} ({woLocationId})
          </Tooltip>
        </Marker>
        {checkInGeoDistance !== NOT_A_NUMBER && (
          <Marker position={checkIn} icon={getIcon(checkInGeoDistance)}>
            <Tooltip permanent direction={'left'}>
              {CHECK_IN} {checkInGeoDistance} {MILES_SHORTHAND}
            </Tooltip>
          </Marker>
        )}
        {checkOutGeoDistance !== NOT_A_NUMBER && (
          <Marker position={checkOut} icon={getIcon(checkOutGeoDistance)}>
            <Tooltip permanent direction={'right'}>
              {CHECK_OUT} {checkOutGeoDistance} {MILES_SHORTHAND}
            </Tooltip>
          </Marker>
        )}
        <Paper className={classes.legend}>
          <Typography variant={typographyVariant}>
            <strong>{WORK_ORDER_NUMBER}:</strong> {workOrderNumber}
          </Typography>
          <Typography variant={typographyVariant}>
            <strong>{VISIT_ID}:</strong> {visitId}
          </Typography>
          {checkInGeoDistance === NOT_A_NUMBER && (
            <Typography variant={typographyVariant}>
              <strong>
                {CHECK_IN} {RECORDED}:
              </strong>{' '}
              {NO}
            </Typography>
          )}
          {checkOutGeoDistance === NOT_A_NUMBER && (
            <Typography variant={typographyVariant}>
              <strong>
                {CHECK_OUT} {RECORDED}:
              </strong>{' '}
              {NO}
            </Typography>
          )}
        </Paper>
      </MapContainer>
    </>
  )
}

export default MapView
