import { combineReducers } from 'redux'
import userFeedbackReducer from '../components/UserFeedback/reducer'
import linearProgressReducer from '../components/MatLinearProgress/reducer'
import filtersReducer from '../components/ScoreCard/Filters/reducer'
import authCredentialsReducer from '../common/UserAuth/reducer'
import splashScreenReducer from '../components/SplashScreen/reducer'
import workOrdersReducer from '../components/WorkOrders/WorkOrdersStore/reducer'
import materialLineItemsReducer from '../components/WorkOrders/LineItems/MaterialLineItems/reducer'
import dashboardReducer from '../components/Dashboard/reducer'

// ✅ SEARCHFILTER IMPORT MUST MATCH THE FOLDER NAME. SAME AS ALL THE OTHERS
const rootReducer = combineReducers({
  userFeedbackReducer,
  linearProgressReducer,
  filtersReducer,
  authCredentialsReducer,
  splashScreenReducer,
  workOrdersReducer,
  materialLineItemsReducer,
  dashboardReducer,
})

export default rootReducer
