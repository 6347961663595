import { SET_MESSAGE, SET_ERROR } from './actionType'

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message: message,
  }
}

export function setError() {
  return {
    type: SET_ERROR,
  }
}
