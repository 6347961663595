import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import BigNumberCard from '../Common/BigNumberCard'
import MetricsTableCard from '../Common/MetricsTableCard'
import ActionButton from '../../ActionButton/ActionButton'
import {
  getRepairFilters,
  getGreenfieldTimePeriod,
} from '../../../service/common/GreenfieldService'
import withRouter from '../../../common/withRouter'
import { useEnv } from '@praxis/component-runtime-env'
import Typography from '@material-ui/core/Typography'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      paddingBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0, 2, 0),
    },
  }),
)

const RepairCompliance = ({
  year,
  period,
  periodLabel,
  business,
  companyId,
  calendar,
}) => {
  const classes = useStyles()
  const filters = getRepairFilters(year, period, business, companyId, calendar)
  const commonTimePeriod = getGreenfieldTimePeriod()
  const reportsParams = `year=${year}&business=${business}&period=${period}&label=${periodLabel}&calendar=${calendar}`
  const env = useEnv()
  const cards = env.greenfield.cards
  const navigate = useNavigate()

  return (
    <Paper square className={classes.paper}>
      <Grid
        item
        xs={12}
        container
        className={classes.gridItem}
        justifyContent="flex-start"
      >
        <BigNumberCard
          cardNumber={cards.repairComplianceBigNumber}
          filters={filters}
          timePeriod={commonTimePeriod}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <Typography variant="subtitle2">
          Measures from earliest start date (time sent to vendor for CM/EMs or
          LOS start time for PMs) to work complete (WO in VREPAIR status).
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <MetricsTableCard
          cardNumber={cards.repairTotalsCard}
          filters={filters}
          timePeriod={commonTimePeriod}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <MetricsTableCard
          cardNumber={cards.repairSLACard}
          filters={filters}
          timePeriod={commonTimePeriod}
        />
      </Grid>
      <ActionButton
        text={'repair fails'}
        variant={'contained'}
        color={'primary'}
        onClick={() => navigate(`/repair-fails#?${reportsParams}`)}
      />
    </Paper>
  )
}

export default withRouter(RepairCompliance)
