import { SET_MESSAGE, SET_ERROR } from './actionType'

export const initialState = {
  message: 'Getting company info...',
  error: false,
}

export default function splashScreenReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MESSAGE: {
      const newState = {
        ...state,
        message: action.message,
      }
      return newState
    }
    case SET_ERROR: {
      const newState = {
        ...state,
        error: true,
      }
      return newState
    }
    default:
      return state
  }
}
