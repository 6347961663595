import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import HomeIcon from '@mui/icons-material/Home'
import withRouter from '../../common/withRouter'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const HomeButton = ({ companyId }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(`/work-orders#?company_id=${companyId}&primary_tab_index=2`)
  }
  return (
    <IconButton
      aria-label={'home'}
      data-testid={'homeButton'}
      onClick={handleClick}
      color="inherit"
    >
      <HomeIcon />
    </IconButton>
  )
}

const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default withRouter(connect(mapStateToProps, null)(HomeButton))
