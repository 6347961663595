import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Container,
  TableBody,
  Table,
  TableRow,
  TableHead,
  TableCell,
  ListSubheader,
} from '@material-ui/core'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.secondary.action,
      borderRadius: 4,
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    tableHeader: {
      fontWeight: 'bold',
      textAlign: 'center',
      width: '33%',
    },
    listSubheader: {
      fontWeight: 'bold',
    },
  }),
)

const TotalCheckInCheckOutHours = ({ workOrder }) => {
  const classes = useStyles()
  const visitHours = workOrder?.visit_hours
  const laborHours = Boolean(visitHours)
  const NOT_APPLICABLE = 'N/A'

  return (
    <Container maxWidth="xl" disableGutters className={classes.outerContainer}>
      <ListSubheader
        disableSticky
        color="primary"
        className={classes.listSubheader}
        data-cy="hoursSubheader"
      >
        Hours Recorded Inside and Outside of Geo-fence
      </ListSubheader>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>
              Total Hours On-site
            </TableCell>
            <TableCell className={classes.tableHeader}>
              Total Hours Off-site
            </TableCell>
            <TableCell className={classes.tableHeader}>
              Total Hours Checked-In and Checked-Out
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {laborHours ? (
              <>
                <TableCell align="center">
                  {visitHours?.total_hours_onsite || NOT_APPLICABLE}
                </TableCell>
                <TableCell align="center">
                  {visitHours?.total_hours_offsite || NOT_APPLICABLE}
                </TableCell>
                <TableCell align="center">
                  {visitHours?.total_ci_co_hours || NOT_APPLICABLE}
                </TableCell>
              </>
            ) : (
              <TableCell colSpan={3} align="center">
                No Results Found
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
  }
}

export default connect(mapStateToProps, null)(TotalCheckInCheckOutHours)
