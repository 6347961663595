/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Paper,
  Grid,
  Alert,
  TextField,
  createStyles,
  Modal,
  Typography,
  Stack,
} from '@mui/material'
import {
  DEFAULT_ERROR_MESSAGE,
  SUMMARY_TEXT,
  CANCEL,
} from '../../../globalConstants'
import LoadingButton from '../../LoadingButton/LoadingButton'
import { getURLSearchParams } from '../../../windowManager'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { fetchData, postData } from '../../../service/common/HttpService'
import { setWorkOrder } from '../WorkOrdersStore/actionCreator'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setMessage } from '../../UserFeedback/actionCreator'
import { GOLDEN_RATIO_AS_PERCENT } from '../../../globalConstants'

const MIN_CHARACTERS = 3
const MAX_CHARACTERS = 100
const NUM_TEXT_FIELD_ROWS = 15

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: `${GOLDEN_RATIO_AS_PERCENT * 100}%`,
    },
    modalHeader: {
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.action,
      padding: theme.spacing(1, 2),
    },
    gridParent: {
      padding: theme.spacing(2),
    },
  }),
)

export const CreateWorkLog = ({
  userId,
  workOrder,
  setWorkOrder,
  toggleRender,
  setMessage,
  text,
  urlParam,
  logType,
}) => {
  const woNumber = workOrder?.work_order_number
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [summaryCount, setSummaryCount] = useState(0)
  const [summaryData, setSummaryData] = useState('')
  const [detailsCount, setDetailsCount] = useState(0)
  const [detailsData, setDetailsData] = useState('')
  const classes = useStyles()

  const onHashChange = () => {
    const searchParams = getURLSearchParams()
    const AddWorkLogParam = Boolean(searchParams.get(urlParam))
    setOpen(AddWorkLogParam)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const isDisabled =
    !summaryData ||
    summaryCount > MAX_CHARACTERS ||
    summaryCount < MIN_CHARACTERS ||
    !detailsData ||
    detailsCount < MIN_CHARACTERS

  const handleSubmit = async () => {
    try {
      const postBody = {
        log_type: logType,
        description: summaryData,
        description_long_description: detailsData,
        create_by: userId,
      }
      const postURL = `work_order/${woNumber}/work_log`
      setError(false)
      setLoading(true)
      await postData(postURL, postBody)
      handleDetailsChange('')
      handleSummaryChange('')
      handleClose()
      toggleRender(true)
      await refreshWorkLogs()
    } catch {
      setError(true)
    } finally {
      setLoading(false)
      toggleRender(false)
    }
  }

  const refreshWorkLogs = async () => {
    // GET work order from maximo pass thru in api
    try {
      const fetchURL = `maximo/work_order/${woNumber}/`
      const response = await fetchData(fetchURL)
      setWorkOrder({
        ...workOrder,
        // Set proposal_notes to null because response from API is giving us all work logs in work_order_logs and we need to re-sort them in reducer
        proposal_notes: null,
        work_order_logs: response.work_order_logs,
      })
    } catch (e) {
      setMessage(DEFAULT_ERROR_MESSAGE + ': ' + e)
    }
  }

  const handleSummaryChange = (input) => {
    setSummaryData(input)
    setSummaryCount(input?.trim()?.length)
  }

  const handleDetailsChange = (input) => {
    setDetailsData(input)
    setDetailsCount(input?.trim()?.length)
  }

  const handleClose = () => {
    setError(false)
    window.history.back()
  }

  const summaryHelperText = () => {
    const count = Math.round((summaryCount / MAX_CHARACTERS) * 100)
    let text = ''
    if (count < MIN_CHARACTERS) {
      text = ` • Minimum of ${MIN_CHARACTERS} characters required for Summary`
    }
    if (count > MAX_CHARACTERS) {
      text = ` • Summary must not exceed ${MAX_CHARACTERS} characters`
    }
    return `${count} / ${MAX_CHARACTERS} ${text}`
  }

  const detailsHelperText = () => {
    return detailsCount < MIN_CHARACTERS
      ? `Minimum of ${MIN_CHARACTERS} characters required for Details`
      : ''
  }

  const commonGridChildProps = {
    xs: 12,
    item: true,
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className={classes.paper}>
        <Typography
          data-cy="createWorkLogHeader"
          className={classes.modalHeader}
          variant="h6"
        >
          {text}
        </Typography>
        <Grid container spacing={1} className={classes.gridParent}>
          {error && (
            <Grid {...commonGridChildProps}>
              <Grid>
                <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>
              </Grid>
            </Grid>
          )}
          <Grid {...commonGridChildProps}>
            <TextField
              label={SUMMARY_TEXT}
              error={
                summaryCount < MIN_CHARACTERS || summaryCount > MAX_CHARACTERS
              }
              helperText={summaryHelperText()}
              value={summaryData}
              onChange={(e) => handleSummaryChange(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid {...commonGridChildProps}>
            <TextField
              onChange={(e) => handleDetailsChange(e.target.value)}
              label={'Details'}
              value={detailsData}
              className={classes.detailsTextField}
              variant="outlined"
              multiline
              fullWidth
              rows={NUM_TEXT_FIELD_ROWS}
              error={detailsCount < MIN_CHARACTERS}
              helperText={detailsHelperText()}
            />
          </Grid>
          <Grid container {...commonGridChildProps} justifyContent="flex-end">
            <Stack direction="row" spacing={1}>
              <LoadingButton
                text={CANCEL}
                variant="outlined"
                onClick={handleClose}
                aria-label="Cancel"
                color="primary"
              />
              <LoadingButton
                aria-label="Submit"
                variant={'contained'}
                onClick={handleSubmit}
                text={text}
                loading={loading}
                disabled={loading || isDisabled}
                color="primary"
              />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: state.authCredentialsReducer.auth.session.userInfo.technicianId,
    workOrder: state.workOrdersReducer.workOrder,
  }
}

const mapDispatchToProps = { setWorkOrder, toggleRender, setMessage }

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkLog)
