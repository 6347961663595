import { FISCAL_CAL_MONTH } from '../../globalConstants'

const isFiscalCalMonth = (calendar) => {
  return calendar === FISCAL_CAL_MONTH
}

export const getGreenfieldTimePeriod = () => {
  return {
    calendar_type: 'Fiscal',
    interval: 'All Time',
    granularity: 'All',
    type: 'relative',
  }
}

function getCompleteParameters() {
  const argumentsBase = arguments[0]
  return (
    argumentsBase[0] &&
    argumentsBase[1] &&
    argumentsBase[2] &&
    argumentsBase[3] &&
    argumentsBase[4]
  )
}

export function getVisitComplianceFilters(
  year,
  period,
  business,
  companyId,
  calendar,
) {
  const completeParameters = getCompleteParameters(arguments)
  return completeParameters
    ? [
        {
          dimension: 'response_fiscal_year_id',
          pattern: [year],
          type: 'in',
        },
        {
          dimension: isFiscalCalMonth(calendar)
            ? 'response_fiscal_month_id'
            : 'response_fiscal_year_week_id',
          pattern: [period],
          type: 'in',
        },
        {
          dimension: 'business_n',
          pattern: [business],
          type: 'in',
        },
        {
          dimension: 'company_id',
          pattern: [companyId],
          type: 'in',
        },
      ]
    : null
}

const getLastSixPeriods = (
  isMonth,
  fiscalPeriodSelection,
  previousTwoPeriodOptions,
) => {
  const index = previousTwoPeriodOptions?.indexOf(fiscalPeriodSelection)
  const lastSixPeriods = previousTwoPeriodOptions?.slice(index - 5, index + 1)
  return lastSixPeriods.map((period) => {
    return isMonth ? period?.acct_mo_end_d : period?.st_wk_chosen
  })
}

export function getVisitTrendFilters(
  business,
  companyId,
  fiscalPeriodSelection,
  previousTwoPeriodOptions,
  calendar,
) {
  const completeParameters = getCompleteParameters(arguments)
  const isMonth = isFiscalCalMonth(calendar)
  const timePeriodDimension = isMonth
    ? 'response_fiscal_month_end_d'
    : 'response_fiscal_week_begin_d'
  return completeParameters
    ? [
        {
          dimension: 'business_n',
          pattern: [business],
          type: 'in',
        },
        {
          dimension: 'company_id',
          pattern: [companyId],
          type: 'in',
        },
        {
          dimension: timePeriodDimension,
          pattern: getLastSixPeriods(
            isMonth,
            fiscalPeriodSelection,
            previousTwoPeriodOptions,
          ),
          type: 'in',
        },
      ]
    : null
}

export function getResponseTrendFilters(
  business,
  companyId,
  fiscalPeriodSelection,
  previousTwoPeriodOptions,
  calendar,
) {
  const completeParameters = getCompleteParameters(arguments)
  const isMonth = isFiscalCalMonth(calendar)
  const timePeriodDimension = isMonth
    ? 'response_fiscal_month_end_d'
    : 'response_fiscal_week_begin_d'
  return completeParameters
    ? [
        {
          dimension: 'business_n',
          pattern: [business],
          type: 'in',
        },
        {
          dimension: 'company_id',
          pattern: [companyId],
          type: 'in',
        },
        {
          dimension: timePeriodDimension,
          pattern: getLastSixPeriods(
            isMonth,
            fiscalPeriodSelection,
            previousTwoPeriodOptions,
          ),
          type: 'in',
        },
      ]
    : null
}

export function getResponseFilters(
  year,
  period,
  business,
  companyId,
  calendar,
) {
  const completeParameters = getCompleteParameters(arguments)
  return completeParameters
    ? [
        {
          dimension: 'response_fiscal_year_id',
          display_name: 'response_fiscal_year_id',
          pattern: [year],
          type: 'in',
          obj_type: 'field',
        },
        {
          dimension: isFiscalCalMonth(calendar)
            ? 'response_fiscal_month_id'
            : 'response_fiscal_year_week_id',
          display_name: isFiscalCalMonth(calendar)
            ? 'response_fiscal_month_id'
            : 'response_fiscal_year_week_id',
          pattern: [period],
          type: 'in',
          obj_type: 'field',
        },
        {
          dimension: 'business_n',
          display_name: 'business_n',
          pattern: [business],
          type: 'in',
          obj_type: 'field',
        },
        {
          dimension: 'company_id',
          display_name: 'company_id',
          pattern: [companyId],
          type: 'in',
          obj_type: 'field',
        },
      ]
    : null
}

export function getRepairFilters(year, period, business, companyId, calendar) {
  const completeParameters = getCompleteParameters(arguments)
  return completeParameters
    ? [
        {
          dimension: 'repair_fiscal_year_id',
          pattern: [year],
          type: 'in',
        },
        {
          dimension: isFiscalCalMonth(calendar)
            ? 'repair_fiscal_month_id'
            : 'repair_fiscal_year_week_id',
          pattern: [period],
          type: 'in',
        },
        {
          dimension: 'business_n',
          pattern: [business],
          type: 'in',
        },
        {
          dimension: 'company_id',
          pattern: [companyId],
          type: 'in',
        },
      ]
    : null
}
