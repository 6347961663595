import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import { connect } from 'react-redux'
import {
  DELETE_ERROR,
  DELETE_SUCCESS,
  FORMATTER,
  SAVING_ERROR,
  QUANTITY_SUCCESS,
  INCURRED_SUCCESS,
  DELETING,
  SAVING,
  GOLDEN_RATIO_AS_PERCENT_REVERSED,
  QUANTITY_ERROR,
} from './../../../../globalConstants'
import { deleteData, putData } from '../../../../service/common/HttpService'
import { setMessage } from '../../../UserFeedback/actionCreator'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import EditIcon from '@mui/icons-material/Edit'
import { NumericFormat } from 'react-number-format'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  IconButton,
  ListSubheader,
  Paper,
  Alert,
  Grid,
  Input,
  CircularProgress,
  Stack,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { useAnalytics } from '@praxis/component-analytics'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      fontWeight: 'bold',
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      padding: theme.spacing(1, 0),
    },
    editIcon: {
      opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
      marginLeft: -theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
  }),
)

const formatCurrency = (value) => FORMATTER.format(value)

export const CatalogLaborLineItems = ({
  workOrder,
  setWorkOrder,
  setMessage,
  toggleRender,
  shouldRender,
  userInfo,
}) => {
  const { trackCustomEvent } = useAnalytics()
  const classes = useStyles()
  const totalCatalogQuantities = workOrder.total_catalog_quantities
  const totalCatalogHours = workOrder.total_catalog_hours
  const allLineItems = workOrder.work_plan_item || []
  const lineItems = allLineItems.filter(
    (x) =>
      x.is_non_catalog === false &&
      x.line_item_type !== null &&
      x.line_item_type?.toLowerCase() === 'labor',
  )

  // Used to track inputs for dynamic number of line items
  const [lineItemInputs, setLineItemInputs] = useState({})

  useEffect(() => {
    const newInputValues = { ...lineItemInputs }
    for (const item of lineItems) {
      newInputValues[item.work_order_plan_item_id] = item.completed_quantity
    }
    setLineItemInputs(newInputValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder])

  const isExceedsHours =
    totalCatalogHours > workOrder?.visit_hours?.total_hours_onsite

  const commonTypographyProps = {
    component: 'div',
    variant: 'body2',
    className: classes.typography,
  }

  const lineItemTotals = workOrder.line_item_totals || {}

  const columns = [
    {
      field: 'description',
      headerName: 'Description',
      width: '35%',
    },
    {
      field: 'completed_amount',
      headerName: 'Unit Cost',
      width: 'auto',
    },
    {
      field: 'completed_quantity',
      headerName: 'Hours/Quantity',
      width: 'auto',
    },
    {
      field: 'completed_line_cost',
      headerName: 'Total',
      width: 'auto',
    },
    {
      field: 'incurred_item',
      headerName: 'Incurred Item?',
      width: 'auto',
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 'auto',
    },
  ]

  const handleDelete = async (params) => {
    setMessage(DELETING)
    const itemId = params.work_order_plan_item_id
    const deleteBody = [
      {
        line_item_type: 'Labor',
        work_order_plan_item_id: itemId,
      },
    ]
    toggleRender(true)
    try {
      await deleteData(
        `work_order/${workOrder.work_order_number}/lineitem`,
        deleteBody,
      )
      // If successfull then reset workorder in redux
      const newLineItems = workOrder.work_plan_item.filter((x) => {
        return x.work_order_plan_item_id !== itemId
      })
      const newWorkOrder = {
        ...workOrder,
        work_plan_item: newLineItems,
      }
      setWorkOrder(newWorkOrder)
      setMessage(DELETE_SUCCESS)
    } catch (e) {
      setMessage(DELETE_ERROR)
    } finally {
      toggleRender(false)
    }
  }

  const handleEdit = async (event, row) => {
    const value = event.target.value
    const incurredClicked = value === 'on'
    const itemId = row.work_order_plan_item_id
    const newQuantity = incurredClicked ? row.completed_quantity : Number(value)
    const newIncurredValue = incurredClicked
      ? event.target.checked
      : row.incurred_item
    const filteredLineItems = lineItems.filter(
      (r) => r.work_order_plan_item_id === itemId,
    )
    const firstFilteredLineItem = filteredLineItems[0]
    const newLineItemTotal =
      firstFilteredLineItem.completed_amount * newQuantity

    const putBody = [
      {
        ...firstFilteredLineItem,
        completed_quantity: newQuantity,
        is_proposed_work: false,
        is_completed_work: true,
        incurred_item: newIncurredValue,
      },
    ]
    if (
      newQuantity !== firstFilteredLineItem.completed_quantity ||
      newIncurredValue !== firstFilteredLineItem.incurred_item
    ) {
      setTimeout(() => {
        setMessage(SAVING)
      }, 100)
      toggleRender(true)
      try {
        await putData(
          `work_order/${workOrder.work_order_number}/lineitem`,
          putBody,
        )
        // After success, we know line items are in maximo, but could still be in write queue for Core
        // To prevent desync, we manually update line items attached to work order
        const updatedLineItems = workOrder.work_plan_item.map((obj) =>
          obj.work_order_plan_item_id === itemId
            ? {
                ...obj,
                completed_quantity: newQuantity,
                completed_line_cost: newLineItemTotal,
                incurred_item: newIncurredValue,
              }
            : obj,
        )
        const updatedWorkOrder = {
          ...workOrder,
          work_plan_item: updatedLineItems,
        }
        trackCustomEvent(
          'Create Catalog Labor Line Item',
          workOrder.work_order_number,
          userInfo.firstName + userInfo.lastName,
          userInfo.technicianId,
        )
        setWorkOrder(updatedWorkOrder)
        setMessage(incurredClicked ? INCURRED_SUCCESS : QUANTITY_SUCCESS)
      } catch (e) {
        setMessage(SAVING_ERROR)
      } finally {
        toggleRender(false)
      }
    }
  }

  const handleChange = (event, itemId) => {
    const newInputValues = { ...lineItemInputs, [itemId]: event.target.value }
    setLineItemInputs(newInputValues)
  }

  return (
    <Container
      maxWidth="xxl"
      disableGutters
      className={classes.outerContainer}
      data-html2canvas-ignore="true"
    >
      <ListSubheader
        data-cy="catalogLaborSubheader"
        disableSticky
        color="inherit"
      >
        <strong>Catalog Labor Line Items</strong>
      </ListSubheader>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} width={column.width}>
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.length > 0 &&
              lineItems.map((row, index) => {
                const itemId = row.work_order_plan_item_id
                // Only return line item if the input is set in state(i.e., useEffect has ran) to avoid null pointer error
                return lineItemInputs[itemId] !== undefined ? (
                  <TableRow key={index}>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>${row.completed_amount}</TableCell>
                    <TableCell>
                      <Stack direction="row">
                        <NumericFormat
                          customInput={Input}
                          value={lineItemInputs[itemId]}
                          onChange={(event) => {
                            handleChange(event, itemId)
                          }}
                          onBlur={(event) => {
                            Number(event.target.value) !==
                              row.completed_quantity && handleEdit(event, row)
                          }}
                          decimalScale={2}
                          disabled={shouldRender}
                          allowNegative={false}
                        />
                        <EditIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {FORMATTER.format(row.completed_line_cost || 0)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.incurred_item}
                        disabled={shouldRender}
                        onChange={(event) => {
                          handleEdit(event, row)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        data-html2canvas-ignore="true"
                        disabled={shouldRender}
                        onClick={() => {
                          handleDelete(row)
                        }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : null
              })}
            {!shouldRender && !lineItems.length && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography {...commonTypographyProps} textAlign={'center'}>
                    No rows
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {shouldRender && !lineItems.length && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography {...commonTypographyProps} fontWeight="bold">
                  Totals
                </Typography>
              </TableCell>
              <TableCell>
                <Typography {...commonTypographyProps}>
                  Hours: {totalCatalogHours || 0}
                </Typography>
                <Typography {...commonTypographyProps}>
                  Quantity: {totalCatalogQuantities || 0}
                </Typography>
              </TableCell>
              <TableCell>
                {formatCurrency(lineItemTotals.catalog_labor_total || 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {isExceedsHours && (
        <Grid container className={classes.gridContainer}>
          <Grid style={{ flex: 3.5 }} />
          <Grid style={{ flex: 4.0 }}>
            <Alert severity="info" data-test={'exceed-hrs'}>
              {QUANTITY_ERROR}
            </Alert>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
    userInfo: state.authCredentialsReducer.auth.session.userInfo,
  }
}

const mapDispatchToProps = {
  setMessage,
  setWorkOrder,
  toggleRender,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogLaborLineItems)
