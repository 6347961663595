import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { setMessage } from '../../../UserFeedback/actionCreator'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import { deleteData, putData } from '../../../../service/common/HttpService'
import {
  DELETE_ERROR,
  DELETE_SUCCESS,
  FORMATTER,
  SAVING_ERROR,
  ITEM_SUCCESS,
  DELETING,
  SAVING,
  GOLDEN_RATIO_AS_PERCENT,
  GOLDEN_RATIO_AS_PERCENT_REVERSED,
} from './../../../../globalConstants'
import EditIcon from '@mui/icons-material/Edit'
import { NumericFormat } from 'react-number-format'
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Container,
  IconButton,
  ListSubheader,
  Paper,
  Select,
  MenuItem,
  Input,
  CircularProgress,
  Stack,
  Checkbox,
} from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    listSubheader: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    typography: {
      fontWeight: 'bold',
    },
    editIcon: {
      opacity: GOLDEN_RATIO_AS_PERCENT_REVERSED,
      marginLeft: -theme.spacing(2),
      marginTop: theme.spacing(0.5),
    },
  }),
)

const SavedNonCatalogItems = ({
  workOrder,
  shouldRender,
  setWorkOrder,
  setMessage,
  toggleRender,
  lineItemTypeLabor,
  lineItemTypeParts,
}) => {
  const lineItems = workOrder.work_plan_item || []
  const filteredLineItems = lineItems.filter((x) => x.is_non_catalog)
  const nonCatalogSummary = workOrder.non_catalog_summary || {}

  const classes = useStyles()

  const DESCRIPTION = 'description'
  const UNIT_OF_MEASURE = 'unit_of_measure'
  const COMPLETED_AMOUNT = 'completed_amount'
  const COMPLETED_QUANTITY = 'completed_quantity'
  const INCURRED_ITEM = 'incurred_item'

  const columns = [
    {
      field: DESCRIPTION,
      headerName: 'Description',
      width: '24%',
    },
    {
      field: 'line_item_type',
      headerName: 'Line Type',
      width: '9%',
    },
    {
      field: UNIT_OF_MEASURE,
      headerName: 'Unit Type',
      width: '8%',
    },
    {
      field: COMPLETED_AMOUNT,
      headerName: 'Unit Cost',
      width: '16%',
    },
    {
      field: COMPLETED_QUANTITY,
      headerName: 'Quantity',
      width: '16%',
    },
    {
      field: 'completed_line_cost',
      headerName: 'Line Total',
      width: '8%',
    },
    {
      field: INCURRED_ITEM,
      headerName: 'Incurred Item?',
      width: '8%',
    },
    {
      field: 'delete',
      headerName: <div data-html2canvas-ignore="true">Delete</div>,
      width: '8%',
    },
  ]

  // Used to track inputs for dynamic number of line items
  const [lineItemInputs, setLineItemInputs] = useState({})

  useEffect(() => {
    const newInputValues = { ...lineItemInputs }
    for (const item of filteredLineItems) {
      newInputValues[item.work_order_plan_item_id] = {
        description: item.description,
        completed_amount: item.completed_amount,
        completed_quantity: item.completed_quantity,
        incurred_item: item.incurred_item,
      }
    }
    setLineItemInputs(newInputValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder])

  const handleChange = (event, itemId) => {
    const oldItem = lineItemInputs[itemId]
    const eventTarget = event.target
    const field = eventTarget.name
    const value =
      field !== INCURRED_ITEM ? eventTarget.value : eventTarget.checked
    const newInputValues = {
      ...lineItemInputs,
      [itemId]: { ...oldItem, [field]: value },
    }
    setLineItemInputs(newInputValues)
  }

  const handleDelete = async (lineItem) => {
    setMessage(DELETING)

    const deleteBody = [
      {
        line_item_type: lineItem.line_item_type,
        work_order_plan_item_id: lineItem.work_order_plan_item_id,
      },
    ]
    toggleRender(true)
    try {
      await deleteData(
        `work_order/${workOrder.work_order_number}/lineitem`,
        deleteBody,
      )
      // If successfull then reset workorder in redux
      const newLineItems = workOrder.work_plan_item.filter((x) => {
        return x.work_order_plan_item_id !== lineItem.work_order_plan_item_id
      })

      const newWorkOrder = {
        ...workOrder,
        work_plan_item: newLineItems,
      }
      setWorkOrder(newWorkOrder)
      setMessage(DELETE_SUCCESS)
    } catch (e) {
      setMessage(DELETE_ERROR)
    } finally {
      toggleRender(false)
    }
  }

  const handleEdit = async (event, row) => {
    const eventTarget = event.target
    const field = eventTarget.name
    const value =
      field !== INCURRED_ITEM ? eventTarget.value : eventTarget.checked
    const newValue =
      field === UNIT_OF_MEASURE ||
      field === DESCRIPTION ||
      field === INCURRED_ITEM
        ? value
        : Number(value)
    const rowId = row.work_order_plan_item_id
    const paramsField = field === UNIT_OF_MEASURE ? field.toLowerCase() : field

    const updatedLineItems = filteredLineItems.filter(
      (r) => r.work_order_plan_item_id === rowId,
    )
    const firstFilteredLineItem = updatedLineItems[0]
    const putBody = [
      {
        ...firstFilteredLineItem,
        [paramsField]: newValue,
        is_proposed_work: false,
        is_completed_work: true,
      },
    ]
    if (newValue !== firstFilteredLineItem[paramsField]) {
      setTimeout(() => setMessage(SAVING), 100)
      toggleRender(true)

      try {
        await putData(
          `work_order/${workOrder.work_order_number}/lineitem`,
          putBody,
        )

        const newLineItems = workOrder.work_plan_item.map((obj) =>
          obj.work_order_plan_item_id === rowId
            ? {
                ...obj,
                [paramsField]: newValue,
              }
            : obj,
        )
        //need to update the line total edited item
        const updatedLineTotalObjects = newLineItems.map((obj) =>
          obj.work_order_plan_item_id === rowId
            ? {
                ...obj,
                completed_line_cost:
                  obj.completed_amount * obj.completed_quantity,
              }
            : obj,
        )

        const updatedWorkOrder = {
          ...workOrder,
          work_plan_item: updatedLineTotalObjects,
        }
        setWorkOrder(updatedWorkOrder)
        setMessage(ITEM_SUCCESS)
      } catch (e) {
        setMessage(SAVING_ERROR)
      } finally {
        toggleRender(false)
      }
    }
  }

  const formatCurrency = (value) => FORMATTER.format(value)

  const commonTypographyProps = {
    component: 'div',
    variant: 'body2',
    className: classes.typography,
  }

  const inputSize = 'small'

  return (
    <Container maxWidth="xxl" disableGutters className={classes.outerContainer}>
      <ListSubheader
        disableSticky
        color="inherit"
        className={classes.listSubheader}
      >
        <strong>Non-Catalog Line Items</strong>
      </ListSubheader>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} width={column.width}>
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLineItems.length
              ? filteredLineItems.map((row, index) => {
                  const itemId = row.work_order_plan_item_id
                  const itemType = row.line_item_type
                  const unitOfMeasure = row.unit_of_measure
                  const completedAmount = row.completed_amount
                  const completedQuantity = row.completed_quantity
                  const menuItems =
                    itemType?.toLowerCase() === 'labor'
                      ? lineItemTypeLabor
                      : lineItemTypeParts
                  // only return when inputs are set in state (i.e., useEffect not running anymore) to avoid null pointer
                  return lineItemInputs[itemId] ? (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          size={inputSize}
                          variant={'standard'}
                          disabled={shouldRender}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                disablePointerEvents
                                position="end"
                                style={{ opacity: GOLDEN_RATIO_AS_PERCENT }}
                              >
                                <EditIcon fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                          name={DESCRIPTION}
                          type="text"
                          value={lineItemInputs[itemId][DESCRIPTION]}
                          onChange={(event) => handleChange(event, itemId)}
                          onBlur={(event) => {
                            event.target.value !== row.description &&
                              handleEdit(event, row)
                          }}
                        />
                      </TableCell>
                      <TableCell>{itemType}</TableCell>
                      <TableCell>
                        <Select
                          size={inputSize}
                          value={unitOfMeasure}
                          variant="standard"
                          name={UNIT_OF_MEASURE}
                          disabled={shouldRender}
                          onChange={(event) => {
                            handleEdit(event, row)
                          }}
                        >
                          {menuItems?.map((menuItem, index) => {
                            return (
                              <MenuItem value={menuItem.value} key={index}>
                                {menuItem.text}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row">
                          <NumericFormat
                            name={COMPLETED_AMOUNT}
                            customInput={Input}
                            value={lineItemInputs[itemId][COMPLETED_AMOUNT]}
                            onChange={(event) => handleChange(event, itemId)}
                            decimalScale={2}
                            onBlur={(event) => {
                              Number(event.target.value) !== completedAmount &&
                                handleEdit(event, row)
                            }}
                            disabled={shouldRender}
                            allowNegative={false}
                          />
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row">
                          <NumericFormat
                            name={COMPLETED_QUANTITY}
                            customInput={Input}
                            value={lineItemInputs[itemId][COMPLETED_QUANTITY]}
                            onChange={(event) => handleChange(event, itemId)}
                            decimalScale={2}
                            onBlur={(event) => {
                              Number(event.target.value) !==
                                completedQuantity && handleEdit(event, row)
                            }}
                            disabled={shouldRender}
                            allowNegative={false}
                          />
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {FORMATTER.format(row.completed_line_cost || 0)}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          name={INCURRED_ITEM}
                          checked={lineItemInputs[itemId][INCURRED_ITEM]}
                          disabled={shouldRender}
                          onChange={(event) => handleEdit(event, row)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          data-html2canvas-ignore="true"
                          disabled={shouldRender}
                          onClick={() => {
                            handleDelete(row)
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null
                })
              : null}
            {!filteredLineItems?.length && !shouldRender && (
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography {...commonTypographyProps} textAlign={'center'}>
                    No rows
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {shouldRender && !filteredLineItems?.length && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell>
                <Typography {...commonTypographyProps} fontWeight="bold">
                  Totals
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  {...commonTypographyProps}
                  id="non-catalog-total-hrs"
                >
                  Hours: {nonCatalogSummary.line_item_hrs || 0}
                </Typography>
                <Typography
                  {...commonTypographyProps}
                  id="non-catalog-total-qty"
                >
                  Quantity: {nonCatalogSummary.line_item_qty || 0}
                </Typography>
              </TableCell>
              <TableCell id="non-catalog-total">
                {formatCurrency(nonCatalogSummary.line_item_totals || 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
    lineItemTypeLabor: state.workOrdersReducer.lineItemTypeLabor,
    lineItemTypeParts: state.workOrdersReducer.lineItemTypeParts,
  }
}
const mapDispatchToProps = {
  setMessage,
  setWorkOrder,
  toggleRender,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavedNonCatalogItems)
