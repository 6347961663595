import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import ScoreCard from '../ScoreCard/ScoreCard'

const styles = (theme) => ({
  content: {
    margin: theme.spacing(2),
    paddingLeft: 240,
  },
})

const Home = ({ classes }) => {
  return (
    <>
      <Header renderTabs />
      <SideMenu />
      <main className={classes.content}>
        <ScoreCard />
      </main>
    </>
  )
}

export default withStyles(styles)(Home)
