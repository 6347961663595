/*
👋 “Hey” from the Praxis team
Praxis ships with support for modern browsers, but you may have users or external vendors that require IE11.
If that’s the case - uncomment the below imports.
Learn more about our the default browser support in the README.

import '@praxis/react-app-polyfill/ie11'
import '@praxis/react-app-polyfill/stable'
*/

import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { onError } from '@praxis/component-logging'
import * as serviceWorker from './serviceWorker'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError

const theme = createTheme({
  palette: {
    primary: {
      main: '#333',
      alternate: '#666',
      action: '#366cd9',
    },
    secondary: {
      main: '#cc0000',
      alternate: '#333',
      action: '#fff',
    },
    background: {
      default: '#d6d6d6',
      focus: '#C3C3C3',
    },
  },
})

const store = configureStore()

const rootEl = document.getElementById('root')
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  rootEl,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
