/* eslint-disable react-hooks/exhaustive-deps */
// These dependencies are bundled within praxis-scripts
import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Alert from '@material-ui/lab/Alert'
import { Typography } from '@material-ui/core'
import RefreshButton from './RefreshButton'
import moment from 'moment'

const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      margin: theme.spacing(1.2),
    },
  }),
)

export const AlertGroup = ({
  loading,
  error,
  numWorkOrders,
  loadingText = 'We are currently loading your work order data, this can take up to 30 seconds or more so please be patient!',
  errorText = 'There was a problem loading your work order(s)',
  emptyText = 'No work orders found.',
  successText = `Success! ${numWorkOrders} work orders have been loaded as of ${moment().format(
    'MMMM D YYYY, h:mm a',
  )}`,
}) => {
  const classes = useStyles()
  return (
    <>
      {
        // we are loading
        loading && (
          <Alert variant="filled" severity="info" className={classes.alert}>
            {loadingText}
          </Alert>
        )
      }
      {
        // there was an error
        error && (
          <Alert variant="filled" severity="error" className={classes.alert}>
            <Typography component="span">{errorText}</Typography>
            <RefreshButton />
          </Alert>
        )
      }
      {
        // we have finished loading, there was no error, but also no work orders
        !loading && !numWorkOrders && !error && (
          <Alert variant="filled" severity="warning" className={classes.alert}>
            <Typography component="span">{emptyText}</Typography>
            <RefreshButton />
          </Alert>
        )
      }
      {
        // if at any point we have work orders, loading was a success
        numWorkOrders ? (
          <Alert variant="filled" severity="success" className={classes.alert}>
            <Typography component="span" data-cy="success-alert-span">
              {successText}
            </Typography>
            <RefreshButton buttonLabel="Refresh" />
          </Alert>
        ) : null
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    companyName: state.authCredentialsReducer.companyName,
  }
}

export default connect(mapStateToProps, null)(AlertGroup)
