import React from 'react'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined'
import { setMessage } from '../../UserFeedback/actionCreator'
import { SERVICE_TECH } from '../../../globalConstants'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    assignmentOutlined: {
      color: theme.palette.primary.action,
    },
  }),
)

const Clipboard = ({ wonum, companyId, setMessage }) => {
  const classes = useStyles()

  const handleClick = () => {
    navigator.clipboard.writeText(
      `https://servicetech.target.com/search#?company_id=${companyId}&wonum=${wonum}`,
    )
    setMessage(
      `${SERVICE_TECH} link copied to clipboard for work order #${wonum}`,
    )
  }

  return (
    <IconButton
      onClick={() => {
        handleClick()
      }}
    >
      <AssignmentOutlined className={classes.assignmentOutlined} />
    </IconButton>
  )
}

const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

const mapDispatchToProps = {
  setMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Clipboard)
