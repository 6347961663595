import React from 'react'
import { Container, Divider } from '@material-ui/core'
import MaterialLineItems from './MaterialLineItems/MaterialLineItems'
import NonCatalogLineItems from './NonCatalogLineItems/NonCatalogLineItems'
import TotalCheckInCheckOutHours from './TotalCheckInCheckOutHours/TotalCheckInCheckOutHours'
import CatalogLaborLineItems from './CatalogLaborLineItems/CatalogLaborLineItems'
import CatalogLaborLineSelection from './CatalogLaborLineSelection/CatalogLaborLineSelection'
import SavedNonCatalogItems from './NonCatalogLineItems/SavedNonCatalogItems'
import GrandTotalLineItems from './GrandTotalLineItems/GrandTotalLineItems'
import ProposalNotes from './ProposalNotes/ProposalNotes'
import HideInputToggleButton from './HideInputToggleButton/HideInputToggleButton'

export const LineItems = () => {
  return (
    <Container maxWidth="xl" disableGutters id={'wo-line-items'}>
      <ProposalNotes />
      <TotalCheckInCheckOutHours />
      <CatalogLaborLineItems />
      <CatalogLaborLineSelection />
      <SavedNonCatalogItems />
      <NonCatalogLineItems />
      <MaterialLineItems />
      <GrandTotalLineItems />
      <HideInputToggleButton />
      <Divider variant="middle" />
    </Container>
  )
}
export default LineItems
