/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useEffect, useRef } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  getURLSearchParams,
  setURLSearchParams,
  deleteURLSearchParams,
} from '../../../../windowManager'
import { connect } from 'react-redux'
import { setPreview } from '../../WorkOrdersStore/actionCreator'
import ProposalButton from '../ProposalButton/ProposalButton'
import PdfButton from '../PdfButton/PdfButton'
import { Stack, Switch } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const useStyles = makeStyles((theme) =>
  createStyles({
    outerContainer: {
      padding: theme.spacing(0, 2, 2, 0),
    },
  }),
)

export const HideInputToggleButton = ({ setPreview }) => {
  const ref = useRef(null)
  const HIDE_INPUTS_TEXT = 'Hide Inputs'
  const classes = useStyles()

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    setPreview(getHideShowInputParam())
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [])

  const getHideShowInputParam = () => {
    const searchParams = getURLSearchParams()
    const hideInputsParam = Boolean(searchParams.get(HIDE_INPUTS_TEXT))
    return hideInputsParam
  }

  const onHashChange = (event) => {
    const hideShowInputParam = getHideShowInputParam()
    if (event) {
      const ADD_NOTE = 'add_note=true'
      const CAROUSEL = 'image_dialog=true'
      const OPEN = 'open=true'
      if (
        event?.oldURL?.indexOf(ADD_NOTE) !== -1 ||
        event?.newURL?.indexOf(ADD_NOTE) !== -1 ||
        event?.oldURL?.indexOf(CAROUSEL) !== -1 ||
        event?.newURL?.indexOf(CAROUSEL) !== -1 ||
        event?.oldURL?.indexOf(OPEN) !== -1 ||
        event?.newURL?.indexOf(OPEN) !== -1
      ) {
        return false
      }
    }
    setPreview(hideShowInputParam)
  }

  const handleChange = (event) => {
    event.target.checked
      ? setURLSearchParams([{ name: HIDE_INPUTS_TEXT, value: true }])
      : deleteURLSearchParams([{ name: HIDE_INPUTS_TEXT }])
  }

  return (
    <Stack
      justifyContent="flex-end"
      direction="row"
      ref={ref}
      spacing={2}
      className={classes.outerContainer}
      data-html2canvas-ignore="true"
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={getHideShowInputParam()} onChange={handleChange} />
          }
          label={HIDE_INPUTS_TEXT}
        />
      </FormGroup>
      <PdfButton />
      <ProposalButton />
    </Stack>
  )
}

const mapStateToProps = (state) => {
  return {
    preview: state.workOrdersReducer.preview,
  }
}

const mapDispatchToProps = {
  setPreview,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HideInputToggleButton)
