import { CURRENT_PAGE, SEARCH_QUERY } from './actionType'

export const initialState = {
  searchState: '',
  currentPage: 0,
}

export default function materialLineItemsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SEARCH_QUERY: {
      const newState = {
        ...state,
        searchState: action.searchState,
      }
      return newState
    }
    case CURRENT_PAGE: {
      const newState = {
        ...state,
        currentPage: action.currentPage,
      }
      return newState
    }
    default:
      return state
  }
}
