import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'

const MatLinearProgress = ({ shouldRender }) => {
  return shouldRender && <LinearProgress color={'secondary'} />
}

const mapStateToProps = (state) => {
  return {
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

export default connect(mapStateToProps, null)(MatLinearProgress)
