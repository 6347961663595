import Cookies from 'js-cookie'
import decode from 'jwt-decode'
import { DOMAIN } from '../globalConstants'

export const getDomainName = () => {
  const hostName = window.location.hostname
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1,
  )
}

export const checkSessionToken = (cookie) => {
  let activeSession = true
  const userToken = localStorage.getItem('access_token')
  if (userToken) {
    const tokenExpUtc = decode(userToken).exp

    let tokenExpDateTime = new Date(0)
    const currentTime = new Date()

    tokenExpDateTime = tokenExpDateTime.setUTCSeconds(tokenExpUtc)
    tokenExpDateTime < currentTime && authUtilFunctions.forceSignOut(cookie)
  } else {
    activeSession = false
  }
  return activeSession
}

export async function authUtil(cookie, session) {
  let validCookie = false
  const isActiveSession = authUtilFunctions.checkSessionToken(cookie)
  if (session.validated) {
    const lanId = session.userInfo.lanId
    const domain = authUtilFunctions.getDomainName()
    if (!isActiveSession) {
      validCookie = isActiveSession
    } else if (domain === DOMAIN) {
      //  // We're on a POL site of some kind
      const SSOSESSIONINFOCookie = Cookies.get(cookie)
      if (SSOSESSIONINFOCookie) {
        const SSOSESSIONINFOcoded = window.atob(SSOSESSIONINFOCookie)
        const SSOSESSIONINFOJson = JSON.parse(SSOSESSIONINFOcoded)

        if (!SSOSESSIONINFOJson.user || !SSOSESSIONINFOJson.login_status) {
          // Its a POL site and the user has a cookie that is logged out
          authUtilFunctions.forceSignOut(cookie)
        } else {
          // Its a POL site, but the user has a cookie that is logged in
          if (String(lanId) !== String(SSOSESSIONINFOJson.user)) {
            /*
                // This else is if lanId != SSOSESSIONINFOJson.user
                // This bit of code gets run every route change
                // If a user logs into a different account via POL
                // we want to switch users, this code should handle that
            */
            authUtilFunctions.forceSignOut(cookie)
          } else {
            validCookie = true
          }
        }
      } else {
        const cookieVal = JSON.parse(window.atob(cookieGet(cookie)))
        validCookie =
          cookieVal.login_status && cookieVal.user === session.userInfo.lanId
      }
    } else {
      validCookie = true
    }
  }
  return validCookie
}

export const cookieGet = (key) => {
  let cookieValue = null
  if (key) {
    const cookies = document.cookie
      .replace(/\s/g, '')
      .split(';')
      .map((c) => (c.includes('=') ? c.split('=') : []))
    if (cookies.length) {
      const map = new Map(cookies)
      cookieValue = map.get(key)
    }
  }
  return cookieValue
}

export const forceSignOut = (cookie) => {
  // Todo move to Auth controller
  localStorage.removeItem('id_token')
  localStorage.removeItem('access_token')
  localStorage.removeItem('X-USER-TOKEN')

  const domain = authUtilFunctions.getDomainName()

  domain === DOMAIN && Cookies.remove(cookie)
  window.location.reload()
}

const authUtilFunctions = {
  getDomainName,
  checkSessionToken,
  authUtil,
  cookieGet,
  forceSignOut,
}
export default authUtilFunctions
