import React, { Suspense } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Header from '../../Header/Header'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { getGreenfieldTimePeriod } from '../../../service/common/GreenfieldService'
import 'greenfield-visualizations/greenfieldvisualizations.css'
import { useEnv } from '@praxis/component-runtime-env'
const GreenfieldVisualizations = React.lazy(
  () => import('greenfield-visualizations'),
)

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      margin: theme.spacing(3),
    },
    gridParent: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.secondary.action,
    },
  }),
)

const ScoreCardReport = ({
  companyName,
  title,
  cardNumber,
  filters,
  business,
  year,
  periodLabel,
}) => {
  const classes = useStyles()
  const canRender = cardNumber && filters
  const env = useEnv()
  const envGreenfield = env.greenfield
  const timePeriod = getGreenfieldTimePeriod()

  return (
    <>
      <Header
        arrowBack
        download
        reportParams={{
          cardNumber: cardNumber,
          reportTitle: title,
          body: { filters: filters, time_period: timePeriod },
        }}
      />
      {canRender ? (
        <main className={classes.content}>
          <Typography variant="h5" align={'left'}>
            {`${companyName} ${title}`}
          </Typography>
          <Typography align={'left'}>
            {`Business: ${business}, Fiscal Calendar: ${year} ${periodLabel} `}
          </Typography>
          <Grid container className={classes.gridParent}>
            <Grid item xs={12}>
              <Suspense fallback={null}>
                <GreenfieldVisualizations
                  fixedCard={{
                    id: cardNumber,
                    filters: filters,
                    time_period: timePeriod,
                  }}
                  apiKey={envGreenfield.apikey}
                  environment={envGreenfield.environment}
                />
              </Suspense>
            </Grid>
          </Grid>
        </main>
      ) : (
        <Typography variant="h5" align={'center'} className={classes.content}>
          Unable to get the report
        </Typography>
      )}
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    companyName: state.authCredentialsReducer.companyName,
  }
}

export default connect(mapStateToProps, null)(ScoreCardReport)
