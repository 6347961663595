import { useMemo } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Typography,
  Alert,
  createStyles,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import ExpandableDiv from '../../../common/ExpandableDiv'
import { Container, ListSubheader } from '@material-ui/core'
import WorkLogSelect from './WorkLogSelect'
import AddWorkLogButton from './AddWorkLogButton'
import { ADD_WORK_LOG } from '../../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    headerBackground: {
      background: theme.palette.primary.alternate,
      color: theme.palette.secondary.action,
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    listSubheader: {
      margin: theme.spacing(0, 1),
      fontWeight: 'bold',
    },
    outerContainer: {
      padding: theme.spacing(0, 1),
    },
    alert: {
      margin: theme.spacing(1),
    },
    alertInfo: {
      margin: theme.spacing(0, 1, 1, 1),
    },
  }),
)

export const WorkLogNotes = ({ workOrder, shouldRender, selectedWorkLog }) => {
  const classes = useStyles()
  const workLogsFromStore = workOrder?.work_order_logs

  function renderCard(data) {
    let createdBy
    const firstName = data?.user_first_name
    const lastName = data?.user_last_name
    if (firstName && lastName) {
      createdBy = firstName + ' ' + lastName
    } else {
      createdBy = data?.create_by + ' (Name unavailable)'
    }

    const commonGridItemNameProps = {
      item: true,
      xs: 4,
      fontWeight: 'bold',
    }
    const commonGridItemValueProps = {
      item: true,
      xs: 8,
    }
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        className={classes.cardContent}
        key={data?.work_log_id}
      >
        <Card data-cy="logCard">
          <CardHeader
            title={
              <Typography fontWeight="bold">
                {data?.formatted_date} (Create Date)
              </Typography>
            }
            className={classes.headerBackground}
          />
          <CardContent>
            <Grid container>
              <Grid {...commonGridItemNameProps}>Type:</Grid>
              <Grid {...commonGridItemValueProps}>{data?.log_type}</Grid>
              <Grid {...commonGridItemNameProps}>Summary:</Grid>
              <Grid {...commonGridItemValueProps}>{data?.description}</Grid>
              <Grid {...commonGridItemNameProps}>Created By:</Grid>
              <Grid {...commonGridItemValueProps}>{createdBy}</Grid>
              <Grid {...commonGridItemNameProps}>Details:</Grid>
              <Grid {...commonGridItemValueProps} xs={12}>
                <ExpandableDiv text={data?.description_long_description} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const setLvm = () => {
    let lvm = 'Not Available'
    if (workOrder.lvm_l3_esc === '1') {
      lvm = 'Vendor Action Plan'
    } else if (workOrder.lvm_l5_esc === '1') {
      lvm = 'HQ Acknowledgement'
    } else if (workOrder.lvm_l4_esc === '1') {
      lvm = 'FMOC to HQ'
    } else if (workOrder.lvm_l2_esc === '1') {
      lvm = 'PMBP to HQ'
    } else if (workOrder.lvm_l1_esc === '1') {
      lvm = 'PML to PMBP'
    }
    return lvm
  }

  const getFilteredLogs = useMemo(() => {
    if (selectedWorkLog?.length) {
      return (workLogsFromStore || []).filter((wo) =>
        selectedWorkLog.includes(wo.log_type),
      )
    } else {
      return workLogsFromStore
    }
  }, [workLogsFromStore, selectedWorkLog])
  return (
    <Container disableGutters className={classes.outerContainer} maxWidth="xl">
      <Alert severity="info" className={classes.alertInfo}>
        <strong>Local Vendor Management (LVM):&nbsp;</strong>
        {setLvm()}
      </Alert>
      <Grid container className={classes.outerContainer}>
        <Grid item xs={6}>
          <ListSubheader
            disableSticky
            color="primary"
            className={classes.listSubheader}
            data-cy="workLogsSubheader"
          >
            Work Logs
          </ListSubheader>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={6}
        >
          <AddWorkLogButton
            text={ADD_WORK_LOG}
            urlParam={'add_work_log'}
            logType={'VENDOR_UPDATE'}
          />
        </Grid>
      </Grid>

      <WorkLogSelect />

      {!shouldRender ? (
        !getFilteredLogs?.length ? (
          <Alert
            severity="warning"
            className={classes.alert}
            data-testid="alert"
          >
            No Work Logs found.
          </Alert>
        ) : (
          <Grid container data-testid="cardContainer">
            {getFilteredLogs?.map((val) => renderCard(val))}
          </Grid>
        )
      ) : (
        <Alert severity="info" className={classes.alert}>
          Loading Work Logs...
        </Alert>
      )}
    </Container>
  )
}

export const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
    selectedWorkLog: state.workOrdersReducer.selectedWorkLog,
  }
}

export default connect(mapStateToProps, null)(WorkLogNotes)
