import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Container, ListSubheader } from '@material-ui/core'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { setMessage } from '../../../UserFeedback/actionCreator'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import { FORMATTER, QUANTITY_ERROR } from '../../../../globalConstants'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      backgroundColor: theme.palette.secondary.action,
      borderRadius: 4,
    },
    listSubheader: {
      marginLeft: theme.spacing(1, 0, 0, 0),
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    typography: {
      padding: theme.spacing(2, 2),
    },
    error: {
      color: `${theme.palette.secondary.main} !important`,
    },
  }),
)

const formatCurrency = (value) => FORMATTER.format(value)

export const GrandTotalLineItems = ({ workOrder, shouldRender }) => {
  const lineItemTotals = workOrder.line_item_totals || {}
  const catalogPartsTotal = lineItemTotals?.catalog_parts_total || 0
  const catalogLaborTotal = lineItemTotals?.catalog_labor_total || 0
  const nonCatalogTotal = lineItemTotals?.non_catalog_total || 0
  const incurredTotal = lineItemTotals?.incurred_total || 0
  const lineItemGrandTotal = lineItemTotals?.grand_total || 0
  const overNTE =
    Number(lineItemGrandTotal) > Number(workOrder.nte_amt?.replace('$', ''))
  const isExceedsHours =
    workOrder?.total_catalog_hours > workOrder?.visit_hours?.total_hours_onsite
  const notes = workOrder?.proposal_notes
  const notesCount = notes?.length
  const classes = useStyles()

  const commonTableCellProps = {
    align: 'center',
  }

  return (
    <Container maxWidth="xl" disableGutters className={classes.outerContainer}>
      <ListSubheader
        disableSticky
        color="primary"
        className={classes.listSubheader}
        data-cy="totalsSubheader"
      >
        <strong>Totals</strong>
      </ListSubheader>
      <TableContainer className={classes.tableContainer}>
        {shouldRender && (
          <Typography className={classes.typography}>
            Loading Work Order Totals . . .
          </Typography>
        )}
        {!shouldRender ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell {...commonTableCellProps}>
                  Catalog Parts Total:
                </TableCell>
                <TableCell {...commonTableCellProps}>
                  Catalog Labor Total:
                </TableCell>
                <TableCell {...commonTableCellProps}>
                  Non-Catalog Total:
                </TableCell>
                <TableCell {...commonTableCellProps}>Incurred Total:</TableCell>
                <TableCell {...commonTableCellProps}>Grand Total:</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow align="center">
                <TableCell {...commonTableCellProps}>
                  {formatCurrency(catalogPartsTotal)}
                </TableCell>
                <TableCell {...commonTableCellProps}>
                  {formatCurrency(catalogLaborTotal)}
                </TableCell>
                <TableCell {...commonTableCellProps}>
                  {formatCurrency(nonCatalogTotal)}
                </TableCell>
                <TableCell {...commonTableCellProps}>
                  {formatCurrency(incurredTotal)}
                </TableCell>
                <TableCell
                  {...commonTableCellProps}
                  className={overNTE ? classes.error : null}
                >
                  {formatCurrency(lineItemGrandTotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{ verticalAlign: 'top' }}>
                  {isExceedsHours && (
                    <Alert severity="info" data-test={'exceed-hrs'}>
                      {QUANTITY_ERROR}
                    </Alert>
                  )}
                </TableCell>
                <TableCell colSpan={2}>
                  {!shouldRender && notes && notesCount < 1 && (
                    <Alert severity="error">
                      Billing Note needed before submitting line items.
                    </Alert>
                  )}
                  {overNTE && (
                    <Alert severity="warning">
                      <strong>Proposal Review Required:</strong> Grand Total
                      beyond NTE limit.
                    </Alert>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
      </TableContainer>
    </Container>
  )
}

export const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

const mapDispatchToProps = {
  setMessage,
  setWorkOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(GrandTotalLineItems)
