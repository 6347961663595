export const VENDOR_ADMIN = 'Property Management Work Order Administration'
export const NUMBER_OF_YEARS_OF_INFORMATION = 7
export const FIRST_YEAR_OF_INFORMATION = 2019
export const FISCAL_CAL_WEEK = 'acct_wk_i'
export const FISCAL_CAL_MONTH = 'acct_mo_i'
export const LOADING = 'Loading...'
export const FAILED_TO_LOAD = 'Failed to load'
export const NO_FILTER_OPTIONS = 'No filter options were found'
export const CANCEL = 'cancel'
export const LOG_OUT = 'Log Out'
export const OK = 'OK'
export const HELP = 'Help'
export const FILTER_FETCH_ERROR = 'We were unable to get your scorecard data'
export const LOGIN_COOKIE = 'SSOSESSIONINFO'
export const DOMAIN = 'partnersonline.com'
export const UTC = 'UTC'
export const OPEN_WORK_ORDERS = 'Open Work Orders'
export const BACK = 'back'
export const CHECK_IN = 'Check-In'
export const CHECK_OUT = 'Check-Out'
export const VISIT_ID = 'Visit ID'
export const WORK_ORDER_NUMBER = 'Work Order Number'
export const SERVICE_TECH = 'ServiceTech'
export const FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
export const DATEFORMATTER = new Intl.DateTimeFormat('en-US').format()
export const WORK_ORDER = 'Work Order'
export const SAVING = 'Please wait while changes are saved.'
export const ITEM_SUCCESS = 'Successfully updated line item.'
export const QUANTITY_SUCCESS = 'Successfully updated line item quantity.'
export const INCURRED_SUCCESS = 'Successfully updated incurred item.'
export const SAVING_ERROR = 'There was an error updating line item.'
export const DELETING = 'Deleting item...'
export const DELETE_SUCCESS = 'Successfully deleted line item'
export const DELETE_ERROR = 'There was an error deleting line item'
export const DEFAULT_ERROR_MESSAGE = 'Error while performing action'
export const ADD_BILLING_NOTE = 'Add Billing Note'
export const SUMMARY_TEXT = 'Summary'
export const NA = 'N/A'
export const DATE_TIME_FORMAT_STRING = 'MMMM DD, YYYY HH:mm A'
export const NOTE_TYPE = 'Note Type'
export const CLEAR_FILTERS = 'Clear filters'
export const SELECT_ALL = 'Select All'
export const FILTER_WORK_LOGS = 'Filter Work Logs'
export const QUANTITY_ERROR =
  'Labor hours billed exceeds total hours recorded on-site'
export const VENDOR_PROPOSAL_SUBMITTING = 'Submitting line items...'
export const VENDOR_PROPOSAL_UPDATED = 'Line items submitted successfully'
export const VENDOR_PROPOSAL_ERROR =
  'There was an error while submitting line items.'
export const MAX_PRECISION = 2
export const VENDOR_PROPOSAL_DOWNLOAD = 'Downloaded successfully'
export const VENDOR_PROPOSAL_DOWNLOAD_ERROR =
  'Error while downloading line items'
export const SET_MESSAGE_DELAY_FIX = 100
export const GOLDEN_RATIO_AS_PERCENT = 0.62
export const GOLDEN_RATIO_AS_PERCENT_REVERSED = 0.38
export const ADD_WORK_LOG = 'Add Work Log'
export const WORK_ORDER_FILTER_FIELDS = [
  {
    field: 'work_order_number',
    name: 'Work Order',
  },
  {
    field: 'work_order_description',
    name: 'Description',
  },
  { field: 'work_type', name: 'Work Type' },
  { field: 'region', name: 'Region' },
  { field: 'group', name: 'Group' },
  { field: 'district', name: 'District' },
  { field: 'site', name: 'Site ID' },
  { field: 'city', name: 'City' },
  { field: 'state', name: 'State' },
  {
    field: 'not_to_exceed',
    name: 'NTE',
  },
  { field: 'work_order_status', name: 'Status' },
  { field: 'status_date', name: 'Status Date' },
  { field: 'vendor_wo_val', name: 'Work Validation' },
  {
    field: 'lvm_status',
    name: 'LVM Status',
  },
  { field: 'last_vendor_work_log', name: 'Last Vendor Work log' },
  {
    field: 'earliest_start',
    name: `Earliest Start`,
  },
  {
    field: 'converted_repair',
    name: 'Repair Expectation',
  },
  {
    field: 'converted_response',
    name: 'Response Expectation',
  },
  {
    field: 'arrive_by',
    name: `Arrive By`,
  },
  {
    field: 'first_check_in',
    name: `First Check-In`,
  },
  {
    field: 'last_check_in',
    name: `Last Check-In`,
  },
  {
    field: 'last_check_out',
    name: `Last Check-Out`,
  },
  {
    field: 'repair_by',
    name: `Repair By`,
  },
  { field: 'asset_name', name: 'Asset' },
  { field: 'area_of_store', name: 'Area of Store' },
  { field: 'problem', name: 'Problem' },
]
