/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { ListSubheader, Container } from '@material-ui/core'
import { fetchData, putData } from '../../../service/common/HttpService'
import { connect } from 'react-redux'
import { getURLSearchParams } from '../../../windowManager'
import { setMessage } from '../../UserFeedback/actionCreator'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import { toggleRender } from '../../MatLinearProgress/actionCreator'
import { setToggle } from '../WorkOrdersStore/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    outerContainer: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    innerContainer: {
      backgroundColor: theme.palette.secondary.action,
      padding: theme.spacing(2),
      borderRadius: 4,
    },
  }),
)

export const WorkOrderRemedies = ({
  workOrder,
  setMessage,
  toggleRender,
  shouldRender,
  toggle,
  setToggle,
}) => {
  const classes = useStyles()
  const workOrderNumber = getURLSearchParams().get('work_order_number')
  const [remedyList, setRemedyList] = useState([])
  const [error, setError] = useState(false)
  const [remedyLoading, setRemedyLoading] = useState(false)

  const errorMessageHandler = (error, message) => {
    const errorResponseDataMessage = error.response?.data.message
    const processedMessage =
      errorResponseDataMessage !== 'null' ? errorResponseDataMessage : message
    setMessage(processedMessage)
    setError(true)
  }

  const onChange = async (event) => {
    try {
      const eventRemedy = event.target.value
      toggleRender(true)
      setError(false)
      setMessage(`Updating remedy to ${eventRemedy}...`)
      await putData(`work_order/${workOrderNumber}`, {
        remedy: eventRemedy,
      })
      setMessage(`Remedy has been updated to ${eventRemedy}.`)
    } catch (error) {
      errorMessageHandler(
        error,
        'There was an error updating the work order remedy.',
      )
    } finally {
      toggleRender(false)
      setToggle(!toggle)
    }
  }

  useEffect(async () => {
    if (workOrder.cause !== undefined && workOrder.cause !== '') {
      setRemedyLoading(true)
      toggleRender(true)
      setError(false)
      try {
        const response = await fetchData(
          `work_order/${workOrderNumber}/problem/${workOrder.problem_code}/cause/${workOrder.cause}/remedies`,
        )
        setRemedyList(response || [])
      } catch (error) {
        errorMessageHandler(
          error,
          'There was an error getting the work order remedies.',
        )
      } finally {
        toggleRender(false)
        setRemedyLoading(false)
      }
    }
  }, [workOrder.cause])
  return workOrder.cause ? (
    <Container maxWidth="xl" disableGutters className={classes.outerContainer}>
      <ListSubheader disableSticky color="primary">
        <strong>Remedies</strong>
      </ListSubheader>
      <FormControl fullWidth className={classes.innerContainer}>
        <>
          {
            // shouldRender is a global state toggle to determine rendering or not.
            // remedyLoading was added as a local state value to further protect this componenet from rendering when it shouldn't
            shouldRender ||
              (remedyLoading && !error && (
                <Typography>Remedies Are Loading . . .</Typography>
              ))
          }
        </>
        {!shouldRender && !remedyLoading && !error ? (
          <>
            <Select
              data-cy="remedySelector"
              value={
                workOrder.remedy && remedyList.length > 0
                  ? workOrder.remedy
                  : ''
              }
              onChange={(event) => onChange(event)}
            >
              {remedyList.map((remedy, index) => {
                return (
                  <MenuItem value={remedy.failure_code} key={index}>
                    {remedy.failure_code}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText>
              Selected remedy. If blank, no remedy is selected.
            </FormHelperText>
          </>
        ) : null}
        {
          // there was an error
          error && (
            <Typography>
              There was a problem loading remedy / no remedy found.
            </Typography>
          )
        }
      </FormControl>
    </Container>
  ) : null
}

const mapStateToProps = (state) => {
  return {
    toggle: state.workOrdersReducer.toggle,
    workOrder: state.workOrdersReducer.workOrder,
    companyId: state.authCredentialsReducer.companyId,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
  setToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderRemedies)
