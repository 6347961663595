import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { PieChart } from '@mui/x-charts/PieChart'
import { connect } from 'react-redux'
import { Paper, Typography, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    alert: {
      margin: theme.spacing(1, 0),
    },
  }),
)

const completedStatuses = ['VCOMP', 'COMP', 'CLOSE']

// data array should have objects with these keys { value, label, status }
const StatusPieChart = ({ data = [], title = '', companyId }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const noData = data?.length === 0
  const handleClick = (_event, _itemIdentifier, item) => {
    const status = item?.status
    const filterIndex = completedStatuses.includes(status) ? 'all' : 'current'
    // set local storage data before navigating to prevent conflict with saved filters on page load
    window.localStorage.setItem(
      'savedFilters',
      JSON.stringify({
        work_order_status: {
          filterType: 'text',
          type: 'equals',
          filter: status,
        },
      }),
    )
    navigate(
      `/work-orders#?company_id=${companyId}&primary_tab_index=2&wo_filter_index=${filterIndex}`,
    )
  }
  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h6">{title}</Typography>
      {noData ? (
        <Alert severity="info" className={classes.alert}>
          <Typography>{`No work orders in statuses required for ${title} report.`}</Typography>
        </Alert>
      ) : (
        <PieChart
          height={220}
          series={[
            {
              data: data,
            },
          ]}
          onClick={handleClick}
        />
      )}
    </Paper>
  )
}
export const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}
export default connect(mapStateToProps, null)(StatusPieChart)
