import { SET_STATUS_COUNTS, SET_AGING_WORK_ORDERS } from './actionType'

export const initialState = {
  statusCounts: {
    vendorAction: [],
    targetAction: [],
  },
  agingWorkOrders: [],
}
const vendorActionStatuses = [
  'APPR',
  'VACCEPT',
  'VINPRG',
  'VREPAIR',
  'PAPPR',
  'PDEC',
  'WVFAIL',
]

const targetActionStatuses = ['PREVIEW', 'VCOMP', 'VDECWC', 'VDECWO']

export default function dashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STATUS_COUNTS: {
      const allStatusCounts = action.statusCounts
      const vendorAction = []
      const targetAction = []
      for (const status of allStatusCounts) {
        const key = status?.key
        const count = status?.doc_count
        const data = { value: count, label: `${key} (${count})`, status: key }
        vendorActionStatuses.includes(key) && vendorAction.push(data)
        targetActionStatuses.includes(key) && targetAction.push(data)
      }
      const newState = {
        ...state,
        statusCounts: {
          vendorAction: vendorAction,
          targetAction: targetAction,
        },
      }
      return newState
    }
    case SET_AGING_WORK_ORDERS: {
      const workOrders = action.workOrders
      // datagrid requires unique id key, need to set one for each work order
      const updatedWorkOrders = workOrders.map((wo) => ({
        ...wo,
        id: wo.work_order_number,
      }))
      const newState = {
        ...state,
        agingWorkOrders: updatedWorkOrders,
      }
      return newState
    }
    default:
      return state
  }
}
