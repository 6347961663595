/* eslint-disable no-undef */
import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import ImageCarousel from './ImageCarousel'
import { getURLSearchParams } from '../../windowManager'

export default function ImageDialog() {
  const [open, setOpen] = useState(false)

  const onHashChange = () => {
    const imageDialog = Boolean(getURLSearchParams().get('image_dialog'))
    setOpen(imageDialog)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const handleClose = () => {
    window.history.back()
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xl"
      fullWidth
      fullScreen
      transitionDuration={0}
    >
      <ImageCarousel />
    </Dialog>
  )
}
