import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Container,
} from '@mui/material'
import ExpandableDiv from '../../../../common/ExpandableDiv'

const ProposalNoteDetails = ({ note }) => {
  let createdBy
  const firstName = note?.user_first_name
  const lastName = note?.user_last_name
  if (firstName && lastName) {
    createdBy = firstName + ' ' + lastName
  } else {
    createdBy = note?.create_by + ' (Name unavailable)'
  }

  return (
    <Container maxWidth="auto" key={note.work_log_id} sx={{ paddingBottom: 3 }}>
      <Typography sx={{ paddingBottom: 1 }}>
        <strong>{`${createdBy}`}</strong> <i>{`${note.formatted_date}`}</i>
      </Typography>
      <Card elevation={3}>
        <CardHeader
          title={
            <Typography data-cy={`description${note.work_log_id}`}>
              <strong>{note.description}</strong>
            </Typography>
          }
        />
        <CardContent>
          <ExpandableDiv text={note.description_long_description} />
        </CardContent>
      </Card>
    </Container>
  )
}

export default ProposalNoteDetails
