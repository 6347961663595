/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { fetchData } from '../../service/common/HttpService'
import {
  getURLSearchParams,
  setURLSearchParams,
  deleteURLSearchParams,
} from '../../windowManager'
import { connect } from 'react-redux'
import { setAgingWorkOrders } from './actionCreator'
import WorkOrderLink from '../WorkOrders/WorkOrderLink/WorkOrderLink'
import { Typography, Alert, Stack } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    dataGrid: {
      backgroundColor: theme.palette.secondary.action,
      minHeight: 418,
    },
    dataGridTitle: {
      padding: theme.spacing(1, 0, 1, 2),
    },
  }),
)

export const AgingWorkOrders = ({
  setAgingWorkOrders,
  agingWorkOrders,
  companyId,
}) => {
  const DEFAULT_PAGE_SIZE = 5
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE)
  const classes = useStyles()
  const DEFAULT_PAGE = 0
  const DEFAULT_SORT_ORDER = 'asc'
  const DEFAULT_SORT_BY = 'reported_date'
  const REPLACE_HISTORY_ENTRY = true
  const SORT_ORDER = 'sort_order'
  const SORT_BY = 'sort_by'
  const PAGE_SIZE = 'page_size'
  const CURRENT_PAGE = 'current_page'

  const columns = [
    {
      field: 'work_order_number',
      headerName: 'Work Order',
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return <WorkOrderLink wonum={params.value} companyId={companyId} />
      },
      sortable: false,
    },
    { field: 'site', headerName: 'Site ID', width: 100 },
    {
      field: 'work_order_description',
      headerName: 'Description',
      width: 300,
      sortable: false,
      filterable: false,
    },
    { field: 'work_order_status', headerName: 'Status', width: 100 },
    { field: 'reported_date', headerName: 'Reported Date', width: 210 },
    { field: 'status_date', headerName: 'Status Date', width: 210 },
  ]

  const setCurrentWorkOrderArray = (workOrders) => {
    const newWoNumsArray = []
    workOrders.forEach(function (workOrder) {
      newWoNumsArray.push(workOrder.work_order_number)
    })
    localStorage.setItem('workOrderArray', JSON.stringify(newWoNumsArray))
  }

  const onPageSizeChange = (event) => {
    setURLSearchParams(
      [{ name: PAGE_SIZE, value: event }],
      REPLACE_HISTORY_ENTRY,
    )
  }

  const onSortModelChange = (event) => {
    if (event.length) {
      const sortFieldName = event[0].field
      setURLSearchParams(
        [
          { name: SORT_BY, value: sortFieldName },
          { name: SORT_ORDER, value: event[0].sort },
        ],
        REPLACE_HISTORY_ENTRY,
      )
    } else {
      deleteURLSearchParams(
        [{ name: SORT_ORDER }, { name: SORT_BY }],
        REPLACE_HISTORY_ENTRY,
      )
    }
  }

  const onHashChange = async (event) => {
    if (event) {
      if (
        event?.oldURL?.indexOf('open') !== -1 ||
        event?.newURL?.indexOf('open') !== -1
      ) {
        return false
      }
    }
    try {
      setLoading(true)
      setError(false)
      const urlSearchParams = getURLSearchParams()
      const currentPage =
        parseInt(urlSearchParams.get(CURRENT_PAGE) || 0) + DEFAULT_PAGE
      const pageSize =
        parseInt(urlSearchParams.get(PAGE_SIZE)) || DEFAULT_PAGE_SIZE
      const sortType = urlSearchParams.get(SORT_ORDER) || DEFAULT_SORT_ORDER
      const sortBy = urlSearchParams.get(SORT_BY) || DEFAULT_SORT_BY
      // calculate 'from' parameter for elasticsearch - should be the index of the record to start from. Records start at index 0
      const from = currentPage * pageSize
      const url = `company/${companyId}/work_orders/aging?page_size=${10000}&sort_by=${sortBy}&sort_order=${sortType}&page_number=${from}`
      setAgingWorkOrders([])
      setRowsPerPage(pageSize)
      const response = await fetchData(url)
      const workOrders = response.work_orders
      if (workOrders.length) {
        setTotalCount(response.total_count)
        setAgingWorkOrders(workOrders)
        setCurrentWorkOrderArray(workOrders)
      }
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  const RenderDataGridTitle = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography noWrap variant="h6" className={classes.dataGridTitle}>
          Aging Work Orders (60+ Days)
        </Typography>
        {error && (
          <Alert severity="error">
            <Typography component="span">
              There was a problem loading aging work orders.
            </Typography>
          </Alert>
        )}
      </Stack>
    )
  }

  return (
    <DataGrid
      rows={agingWorkOrders}
      columns={columns}
      className={classes.dataGrid}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[5, 10, 25]}
      onPageSizeChange={onPageSizeChange}
      paginationMode={'client'}
      onSortModelChange={onSortModelChange}
      sortingMode={'client'}
      autoHeight
      rowCount={totalCount}
      loading={loading}
      hideFooterSelectedRowCount
      disableColumnFilter
      components={{
        Toolbar: RenderDataGridTitle,
      }}
    />
  )
}
const mapStateToProps = (state) => {
  return {
    agingWorkOrders: state.dashboardReducer.agingWorkOrders,
    companyId: state.authCredentialsReducer.companyId,
  }
}
const mapDispatchToProps = {
  setAgingWorkOrders,
}
export default connect(mapStateToProps, mapDispatchToProps)(AgingWorkOrders)
