import React from 'react'
import { connect } from 'react-redux'
import ScoreCardReport from '../Common/ScoreCardReport'
import { getReportParams } from '../../../windowManager'
import { getResponseFilters } from '../../../service/common/GreenfieldService'
import { useEnv } from '@praxis/component-runtime-env'

const RESPONSE_FAILS_TITLE = 'Response Time Failures'

const ResponseFailsReport = ({ companyId }) => {
  const { year, business, period, periodLabel, calendar } = getReportParams()
  const filters = getResponseFilters(
    year,
    period,
    business,
    companyId,
    calendar,
  )
  const env = useEnv()

  return (
    <ScoreCardReport
      title={RESPONSE_FAILS_TITLE}
      cardNumber={env.greenfield.cards.responseFails}
      filters={filters}
      business={business}
      year={year}
      periodLabel={periodLabel}
    />
  )
}

export const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
  }
}

export default connect(mapStateToProps, null)(ResponseFailsReport)
