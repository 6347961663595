/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Header from '../Header/Header'
import StatusPieChart from './StatusPieChart'
import { fetchData } from '../../service/common/HttpService'
import { connect } from 'react-redux'
import { setStatusCounts } from './actionCreator'
import { Alert, Grid, Typography } from '@mui/material'
import { toggleRender } from '../MatLinearProgress/actionCreator'
import { Container } from '@material-ui/core'
import AgingWorkOrders from './AgingWorkOrders'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(3, 0),
    },
    offset: theme.mixins.toolbar,
  }),
)

const Dashboard = ({
  statusCounts,
  setStatusCounts,
  companyId,
  shouldRender,
  toggleRender,
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)

  useEffect(async () => {
    try {
      toggleRender(true)
      setError(false)
      const response = await fetchData(
        `company/${companyId}/work_orders/status_count`,
      )
      const statusCounts = response?.work_order_status?.buckets
      setStatusCounts(statusCounts)
    } catch (error) {
      setError(true)
    } finally {
      toggleRender(false)
    }
  }, [])

  const commonAlertProps = {
    variant: 'filled',
  }

  return (
    <>
      <Header renderTabs />
      <div className={classes.offset} />
      <Container className={classes.container} maxWidth={false}>
        {shouldRender && (
          <Alert severity="info" {...commonAlertProps}>
            <Typography>Loading user dashboard...</Typography>
          </Alert>
        )}
        {!shouldRender && error && (
          <Alert severity="error" {...commonAlertProps}>
            <Typography>
              There was an error while fetching data for user dashboard. Please
              try again later.
            </Typography>
          </Alert>
        )}
        <Grid container spacing={3}>
          {!shouldRender && !error && (
            <>
              <Grid item xs={12} lg={6}>
                <StatusPieChart
                  data={statusCounts?.vendorAction}
                  title={'Vendor Action'}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <StatusPieChart
                  data={statusCounts?.targetAction}
                  title={'Target Action'}
                />
              </Grid>
            </>
          )}
          {!error && (
            <Grid item xs={12} xl={6}>
              <AgingWorkOrders />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  )
}
export const mapStateToProps = (state) => {
  return {
    companyId: state.authCredentialsReducer.companyId,
    statusCounts: state.dashboardReducer.statusCounts,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}
const mapDispatchToProps = {
  setStatusCounts,
  toggleRender,
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
