import React from 'react'
import WorkLogNotes from './WorkLogNotes'
import VisitDetails from '../VisitDetails/VisitDetails'

export const WorkLogs = () => {
  return (
    <>
      <WorkLogNotes />
      <VisitDetails />
    </>
  )
}

export default WorkLogs
