/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  FormControl,
  Select,
  MenuItem,
  createStyles,
  InputLabel,
  Checkbox,
  Chip,
  ListItemText,
  Divider,
  IconButton,
} from '@material-ui/core'
import {
  CLEAR_FILTERS,
  SELECT_ALL,
  FILTER_WORK_LOGS,
} from '../../../globalConstants'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { setSelectedWorkLog } from '../WorkOrdersStore/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(3, 1, 0, 0),
    },
    root: {
      margin: theme.spacing(1),
      background: theme.palette.secondary.action,
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        border: '1px solid',
      },
      '& label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: `translate(16px, 16px) scale(0.75)`,
      },
    },
  }),
)

export const WorkLogSelect = ({
  selectedWorkLog,
  setSelectedWorkLog,
  workOrder,
}) => {
  const classes = useStyles()
  const [selectOpen, setSelectOpen] = useState(false)
  const selectedWorkLogLength = selectedWorkLog.length

  const handleChange = (e) => {
    const eventTargetValue = e?.target?.value
    const workOrderLogsLength = getWorkOrderLogs.length

    const isSelectAll =
      eventTargetValue?.find?.((val) => val === SELECT_ALL) &&
      selectedWorkLogLength !== workOrderLogsLength
    const isUnSelectAll =
      eventTargetValue?.find?.((val) => val === SELECT_ALL) &&
      selectedWorkLogLength === workOrderLogsLength

    let workLogValue = eventTargetValue?.filter?.((val) => val !== SELECT_ALL)
    if (isSelectAll) {
      workLogValue = getWorkOrderLogs.map((wo) => wo.log_type)
    } else if (isUnSelectAll) {
      workLogValue = []
    }
    setSelectedWorkLog(workLogValue)
  }

  const getWorkOrderLogs = useMemo(() => {
    return (workOrder?.work_order_logs || []).reduce((result, curr) => {
      if (!result.find((res) => res.log_type === curr.log_type)) {
        result.push(curr)
      }
      return result
    }, [])
  }, [workOrder])

  const commonChipProps = {
    clickable: true,
    className: classes.chip,
    onDelete: () => this.onMouseDown(),
  }

  const getMenuItem = (logType, index) => {
    return [
      index === 0 && <Divider />,
      <MenuItem value={logType}>
        <Checkbox checked={selectedWorkLog.includes(logType)} />
        <ListItemText primary={logType} />
      </MenuItem>,
    ]
  }
  const handleMouseDown = (e, workLog) => {
    e.stopPropagation()
    setSelectedWorkLog(workLog)
  }

  const options = () => {
    const workOrderLogsLength = getWorkOrderLogs?.length
    return (
      workOrderLogsLength > 0 &&
      getWorkOrderLogs.map?.((worklogNoteType, index) => {
        return [
          index === 0 && (
            <MenuItem value={SELECT_ALL}>
              <Checkbox
                checked={selectedWorkLogLength === workOrderLogsLength}
              />
              <ListItemText primary={SELECT_ALL} />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectOpen(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </MenuItem>
          ),
          getMenuItem(worklogNoteType.log_type, index),
        ]
      })
    )
  }

  useEffect(() => {
    setSelectedWorkLog([])
  }, [])

  return (
    <div className={classes.root}>
      <FormControl fullWidth variant="outlined">
        <InputLabel shrink={Boolean(selectedWorkLogLength)}>
          {FILTER_WORK_LOGS}
        </InputLabel>
        <Select
          multiple
          value={selectedWorkLog}
          onChange={handleChange}
          onClose={() => setSelectOpen(false)}
          onOpen={() => setSelectOpen(true)}
          open={selectOpen}
          disabled={getWorkOrderLogs.length === 0}
          renderValue={(selected) => (
            <>
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={value}
                  {...commonChipProps}
                  onMouseDown={(e) =>
                    handleMouseDown(
                      e,
                      selectedWorkLog.filter((val) => val !== value),
                    )
                  }
                />
              ))}
              {selected.length > 0 && (
                <Chip
                  label={CLEAR_FILTERS}
                  {...commonChipProps}
                  onMouseDown={(e) => handleMouseDown(e, [])}
                />
              )}
            </>
          )}
        >
          {options()}
        </Select>
      </FormControl>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    selectedWorkLog: state.workOrdersReducer.selectedWorkLog,
  }
}

const mapDispatchToProps = {
  setSelectedWorkLog,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkLogSelect)
