import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Grid, ListSubheader, Paper } from '@material-ui/core'
import { Alert } from '@mui/material'
import { connect } from 'react-redux'
import ProposalNoteDetails from './ProposalNoteDetails'
import AddWorkLogButton from '../../WorkLogs/AddWorkLogButton'
import { ADD_BILLING_NOTE } from '../../../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    listSubheader: {
      fontWeight: 'bold',
    },
    innerContainer: {
      backgroundColor: theme.palette.secondary.action,
      padding: theme.spacing(1, 1, 1, 1),
    },
    outerContainer: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(3, 0, 2, 0),
    },
  }),
)

export const ProposalNotes = ({ workOrder, shouldRender }) => {
  const classes = useStyles()
  const notes = workOrder?.proposal_notes
  const notesCount = notes?.length

  return (
    <Grid container className={classes.outerContainer}>
      <Grid item xs={6}>
        <ListSubheader
          disableSticky
          color="primary"
          className={classes.listSubheader}
          data-cy="proposalNotesSubheader"
        >
          Billing Notes
        </ListSubheader>
      </Grid>
      <Grid item container justifyContent="flex-end" alignItems="center" xs={6}>
        <AddWorkLogButton
          text={ADD_BILLING_NOTE}
          urlParam={'add_note'}
          logType={'VENDOR_PROPOSAL'}
        />
      </Grid>
      <Grid item xs={12}>
        {shouldRender && (
          <Alert severity="info" data-testid="loadingMessage">
            Loading Billing Notes...
          </Alert>
        )}
        {!shouldRender && notes && notesCount < 1 && (
          <Alert severity="error">No Billing Notes found.</Alert>
        )}
        {!shouldRender && notesCount >= 1 && (
          <Paper className={classes.innerContainer}>
            {notes.map((note) => (
              <ProposalNoteDetails note={note} key={note.work_log_id} />
            ))}
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}

export const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    shouldRender: state.linearProgressReducer.shouldRender,
  }
}

export default connect(mapStateToProps, null)(ProposalNotes)
