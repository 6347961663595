import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
} from '@mui/material'
import { useEnv } from '@praxis/component-runtime-env'
import { useState } from 'react'

const AppRestrictedDialog = () => {
  const env = useEnv()
  const inAppMessage = env?.inAppMessage
  const appMessage = env?.appMessage
  const restrictedMessage = env?.restrictedMessage
  const allowRestrictedAccessBypass = env?.allowRestrictedAccessBypass
  const [accessRestricted, setAccessRestricted] = useState(
    env?.accessRestricted,
  )
  const [messageOpen, setMessageOpen] = useState(inAppMessage)

  return (
    <>
      {accessRestricted && (
        <Dialog
          BackdropProps={{ style: { backgroundColor: '#d6d6d6' } }}
          severity="error"
          open={accessRestricted}
        >
          <DialogTitle>{restrictedMessage}</DialogTitle>
          {allowRestrictedAccessBypass && (
            <DialogActions>
              <Button onClick={() => setAccessRestricted(false)}>
                Continue
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
      {inAppMessage && (
        <Snackbar
          open={messageOpen}
          severity="info"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setMessageOpen(false)} severity="warning">
            {appMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default AppRestrictedDialog
