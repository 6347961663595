import React, { Suspense } from 'react'
import 'greenfield-visualizations/greenfieldvisualizations.css'
import { useEnv } from '@praxis/component-runtime-env'
const GreenfieldVisualizations = React.lazy(
  () => import('greenfield-visualizations'),
)

const ScoreCardHelp = () => {
  const env = useEnv()
  const envGreenfield = env.greenfield
  return (
    <Suspense fallback={null}>
      <GreenfieldVisualizations
        fixedCard={{
          id: envGreenfield.cards.scorecardHelp,
        }}
        apiKey={envGreenfield.apikey}
        environment={envGreenfield.environment}
      />
    </Suspense>
  )
}

export default ScoreCardHelp
