export const SET_WORK_ORDERS = 'SET_WORK_ORDERS'
export const SET_TOGGLE = 'SET_TOGGLE'
export const SET_VISITS = 'SET_VISITS'
export const SET_WORK_ORDER = 'SET_WORK_ORDER'
export const SET_PREVIEW = 'SET_PREVIEW'
export const SET_SELECTED_WORK_LOG = 'SET_SELECTED_WORK_LOG'
export const SET_WORK_ORDER_ATTACHMENTS = 'SET_WORK_ORDER_ATTACHMENTS'
export const SET_NON_CATALOG_LINE_ITEM_TYPE = 'SET_NON_CATALOG_LINE_ITEM_TYPE'
export const SET_WORK_ORDER_ATTACHMENTS_INDEX =
  'SET_WORK_ORDER_ATTACHMENTS_INDEX'
export const SET_TOTAL_COUNT_EXPORT = 'SET_TOTAL_COUNT_EXPORT'
