import {
  SET_WORK_ORDERS,
  SET_TOGGLE,
  SET_VISITS,
  SET_WORK_ORDER,
  SET_PREVIEW,
  SET_TOTAL_COUNT_EXPORT,
  SET_SELECTED_WORK_LOG,
  SET_WORK_ORDER_ATTACHMENTS,
  SET_NON_CATALOG_LINE_ITEM_TYPE,
  SET_WORK_ORDER_ATTACHMENTS_INDEX,
} from './actionType'

export function setNonCatalogLineItemType(itemType) {
  return {
    type: SET_NON_CATALOG_LINE_ITEM_TYPE,
    itemType: itemType,
  }
}

export function setWorkOrderAttachmentsIndex(attachmentsIndex) {
  return {
    type: SET_WORK_ORDER_ATTACHMENTS_INDEX,
    attachmentsIndex: attachmentsIndex,
  }
}

export function setWorkOrderAttachments(attachments) {
  return {
    type: SET_WORK_ORDER_ATTACHMENTS,
    attachments: attachments,
  }
}

export function setWorkOrders(workOrders) {
  return {
    type: SET_WORK_ORDERS,
    workOrders: workOrders,
  }
}

export function setToggle(toggle) {
  return {
    type: SET_TOGGLE,
    toggle: toggle,
  }
}

export function setPreview(preview) {
  return {
    type: SET_PREVIEW,
    preview: preview,
  }
}
export function setVisits(visits) {
  return {
    type: SET_VISITS,
    visits: visits,
  }
}

export function setWorkOrder(workOrder) {
  return {
    type: SET_WORK_ORDER,
    workOrder: workOrder,
  }
}

export function setSelectedWorkLog(selectedWorkLog) {
  return {
    type: SET_SELECTED_WORK_LOG,
    selectedWorkLog: selectedWorkLog,
  }
}

export function setTotalCountExport(totalCountExport) {
  return {
    type: SET_TOTAL_COUNT_EXPORT,
    totalCountExport: totalCountExport,
  }
}
