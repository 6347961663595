import React from 'react'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { toggleRender } from '../../../MatLinearProgress/actionCreator'
import { putData } from '../../../../service/common/HttpService'
import { setMessage } from '../../../UserFeedback/actionCreator'
import { setWorkOrder } from '../../WorkOrdersStore/actionCreator'
import {
  getURLSearchParams,
  setURLSearchParams,
} from '../../../../windowManager'
import {
  WORK_ORDER,
  VENDOR_PROPOSAL_SUBMITTING,
  VENDOR_PROPOSAL_UPDATED,
  VENDOR_PROPOSAL_ERROR,
  SET_MESSAGE_DELAY_FIX,
} from '../../../../globalConstants'
import { setToggle } from '../../WorkOrdersStore/actionCreator'
import { useAnalytics } from '@praxis/component-analytics'
import ConfirmBillingModal from './ConfirmBillingModal'

export const ProposalButton = ({
  workOrder,
  toggleRender,
  setMessage,
  isSubmitting,
  toggle,
  setToggle,
  userInfo,
}) => {
  const { trackCustomEvent } = useAnalytics()
  const workOrderNumber = getURLSearchParams().get('work_order_number')
  const notes = workOrder?.proposal_notes
  const workOrderPlanItem = workOrder?.work_plan_item
  const catalogLaborLineItems = (workOrderPlanItem || []).filter(
    (x) =>
      x.is_non_catalog === false &&
      x.line_item_type !== null &&
      x.line_item_type?.toLowerCase() === 'labor',
  )
  const nonCatalogLineItems = (workOrderPlanItem || []).filter(
    (x) => x.is_non_catalog,
  )
  const partsLineItems = (workOrderPlanItem || []).filter(
    (x) =>
      x.is_non_catalog === false &&
      x.line_item_type !== null &&
      x.line_item_type?.toLowerCase() === 'parts',
  )
  const disabled =
    !notes?.length ||
    (!catalogLaborLineItems?.length &&
      !nonCatalogLineItems?.length &&
      !partsLineItems?.length)

  const updateStatus = async () => {
    let message = `${VENDOR_PROPOSAL_UPDATED}, refreshing ${WORK_ORDER}...`
    try {
      const body = { status: 'VPROP' }
      setTimeout(() => {
        setMessage(VENDOR_PROPOSAL_SUBMITTING)
      }, SET_MESSAGE_DELAY_FIX)
      toggleRender(true)
      await putData(`work_order/${workOrderNumber}`, body)
      setToggle(!toggle)
      setURLSearchParams([{ name: 'confirm_billing', value: true }])
    } catch (error) {
      const errorResponeData = error?.response?.data
      message =
        errorResponeData?.message || errorResponeData || VENDOR_PROPOSAL_ERROR
      toggleRender(false)
    } finally {
      setMessage(message)
      trackCustomEvent(
        'Submit Line Items',
        workOrderNumber,
        userInfo.firstName + userInfo.lastName,
        userInfo.technicianId,
      )
    }
  }

  return (
    <>
      <Button
        onClick={updateStatus}
        variant="contained"
        color="primary"
        size="large"
        disabled={disabled || isSubmitting}
      >
        Submit Line Items
      </Button>
      <ConfirmBillingModal />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    workOrder: state.workOrdersReducer.workOrder,
    isSubmitting: state.linearProgressReducer.shouldRender,
    toggle: state.workOrdersReducer.toggle,
    userInfo: state.authCredentialsReducer.auth.session.userInfo,
  }
}

const mapDispatchToProps = {
  toggleRender,
  setMessage,
  setWorkOrder,
  setToggle,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalButton)
